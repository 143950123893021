import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {


  key = "MilesLMS";

  constructor() { }

  //To encrypt input data
  public encrypt(data: any): string {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
  }

  //To decrypt input data
  public decrypt(data: string) {
    const bytes = CryptoJS.AES.decrypt(data, this.key);
    if (bytes.toString()) {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return data;
  }

  /* Decrypt the data based on the fields*/
  public dataEncryption(data: any[], decryptField: any[]) {
    let encryptedData = data.map((element) => {
      for (let item of decryptField) {
        if (element[item]) {
          element[item] = this.decrypt(element[item]);
        }
      }
      return element;
    })
    return encryptedData;
  }

  /* Decrypt the response */
  public responseDecrypt(response) {
    if (response.status) {
      if (response.data && response.data.length > 0) {
        response.data = this.decrypt(response.data);
      }

      if (response.metadata && response.metadata.length > 0) {
        response.metadata = this.decrypt(response.metadata);
      }
    }
    return response;
  }
}
