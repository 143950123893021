import { Component, OnInit, ViewChild } from '@angular/core';
// import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { ChartConfiguration, Chart } from 'chart.js';
import {
  IMyOptions,
  MDBModalRef,
  MDBModalService,
  ModalDirective,
  TabsetComponent,
} from 'ng-uikit-pro-standard';
import { ActivatedRoute, Router } from '@angular/router';
// import player from 'lottie-web';
import { LaunchpadService } from '../../clusters/services/launchpad.service';
import { Store } from '@ngrx/store';
import { MonthService } from 'src/app/_clusters/documents/month.service';
import { StudyplannerService } from './common/studyplanner.service';
import Swal from 'sweetalert2';
import { object } from 'underscore';
import { BaseChartDirective } from 'ng2-charts';
import moment from 'moment';
import { ImaPopupComponent } from '../launchpad/ima-popup/ima-popup.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PopupService } from 'projects/cma/src/app/clusters/services/popup.service';
import * as _ from 'underscore';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-studyplanner',
  templateUrl: './studyplanner.component.html',
  styleUrls: ['./studyplanner.component.scss'],
})
export class StudyplannerComponent implements OnInit {
  @ViewChild('infoModal') infoModal: ModalDirective;
  @ViewChild('tabs') tabs: TabsetComponent;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  gameplanExamId: any;
  plannedYearValidation: any;
  staticAuthToken: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdHVkZW50X2lkIjoxMTQwNTk3ODQsImludF9zdGF0dXMiOjEsIm1hY19pZCI6bnVsbCwiaW50X2NvdXJzZV9pZCI6MiwicGFydDFfbW9kZSI6MCwicGFydDJfbW9kZSI6MCwiaXNfZm9ydW1fYWRtaW4iOm51bGwsImlhdCI6MTcyODI4NDIxOH0.i2nJ19R-9t3ap-96xtoJFdpEPL85sM4ge6FDOCTVepM';
  userToken: string = ''
  oldGamePlanId: number
  newGamePlanId: number

  // @ViewChild('chart1') chart1: BaseChartDirective;
  ngAfterViewInit(): void {
    this.tabs.setActiveTab(1);
  }

  smallHoverImg = `Note: From 2022 edition, Miles CMA LMS has been expanded (i.e, Normal mode) so that candidates have a single online access leading to an improved study experience.

  Choose to use the cram mode only if you are strong with the concepts and fast track your learning!`;

  dashboardData: any = [];
  observer: any;

  //Ima popup
  showimaInput: boolean = false;
  imaDataPopup: any;
  imaMetaDataPopup: any;
  showImaSteps: boolean = false;
  submitted: boolean = false;
  imaCode: any;
  showExamStatus: boolean = true;
  isShown: boolean = false;
  modalRef: MDBModalRef;
  imaModalRef: MDBModalRef;
  imaverify = false;
  // dateVariables
  canid: string;
  srid: string;

  currentMonth1 = new Date().getMonth() + 1;
  currentMonthDate: any;
  validMonthLabels = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  //Date Picker Model Variables
  scheduleDate: any;
  examDate: any;
  year = new Date().getFullYear();
  switchId = null;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  currentDate = new Date().getDate();
  DateValidation = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  public myDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    monthLabels: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
    },
    dateFormat: 'dd-mmm-yyyy',
    minYear: new Date().getFullYear(),
    maxYear: 2025,
    disableUntil: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
  };
  public passedDateOption: IMyOptions = {
    closeAfterSelect: true,
    monthLabels: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
    },
    dateFormat: 'dd-mmm-yyyy',
    minYear: 1970,
    maxYear: new Date().getFullYear(),
    disableSince: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate() + 1,
    },
  };

  part1Data: any = { id: 0, subject_id: 105 };
  part2Data: any = { id: 0, subject_id: 106 };
  //study Planer Variables
  validScheduleMonths1: any;
  validScheduleMonths2: any;
  userData: any = [];
  showStatus: any;
  showEditOptions: any = 0;
  gameplanList: any = [];
  examStatusList: any = [];
  studyPlannerDetails: any = [];
  subjectId: any;
  myChart: any;
  currentTab: any;
  examStatusTabCss: any;
  studyTrackTabCss: any;
  preparationTabCss: any;
  studentGpStatus: any = [];
  studentUpdateExamStatusList: any = [];
  part2ScheduleDate: any;
  part1ScheduleDate: any;
  part1ExamstatusThree: any; // Checking the Examstatus  based on the gp status(4)
  part2ExamstatusThree: any;
  studentUpdateExamStatus: any;
  gpStatus: number;
  studentCurrentGp = '';
  studentCurrentExamStatus = '';
  studentCurrentExamPart1 = '';
  studentCurrentExamPart: any = [];
  studentCurrentExamPart2 = '';
  studentCurrentExamDatePart1 = '';
  studentCurrentExamDatePart2 = '';
  part1Score: any;
  part2Score: any;
  plannerFormData: any = {
    exam_date: '',
    studyMode: false,
    studyType: false,
    preparation_pace: '',
  };
  plannedMonthValidation: any;

  plannerDetails: any = {
    paceMode: '',
    studyMode: '',
    studyType: '',
    time: '',
    dateOfExam: '',
    percent_complition: '',
    score: '',
  };
  checkImaSteps: any;
  paceChecked1: any;
  paceChecked2: any;
  paceChecked3: any;
  paceChecked4: any;
  diableGpStatus: boolean = false;
  //Planner CSS Variables
  subjectDetails: any = {};
  prepcard: any = {
    prepcard1: '',
    prepcard2: '',
    prepcard3: '',
    prepcard4: '',
  };
  examDateLabel: any;
  examDateLabel1: any;
  examDateLabel2: any;
  metaPrepCard: any;
  plannerClass: any = {};
  showhat: boolean = false;
  part1SubjectId = '41d4d05f967c1b913cabe95876b8a2c7';
  part2SubjectId = '6c7ccca37633e15249a4ba916d82317e';
  gameplanPreviousStatus: any;
  modeEvent: any;
  typeEvent: any;
  // animation
  leftMCQ: any = [];
  leftVideo: any = [];
  leftEssay: any = [];
  chartList: any = [];
  remainingHrs: any;
  progressBar: any;
  option: AnimationOptions = {
    path: '/assets/book-popup.json',
  };

  option2: AnimationOptions = {
    path: '/assets/hat-animation.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    // maxWidth: '550px',
    margin: '0 auto',
    display: 'block',
  };

  // chart
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [
      'Section A',
      'Section B',
      'Section C',
      'Section D',
      'Section E',
      'Section F',
    ],
    datasets: [
      {
        data: this.leftVideo,
        label: 'Remaining Video Hrs',
        backgroundColor: ['rgb(185 204 130)'],
        borderColor: ['rgb(185 204 130)'],
        borderWidth: 1,
        maxBarThickness: 5,
        borderRadius: 10,
        barPercentage: 0.6,
        categoryPercentage: 0.5,
      },
      {
        data: this.leftMCQ,
        label: 'Remaining MCQ Hrs',
        backgroundColor: ['rgb(34 84 136)'],
        borderColor: ['rgb(34 84 136)'],
        borderWidth: 1,
        maxBarThickness: 5,
        borderRadius: 10,
        barPercentage: 0.6,
        categoryPercentage: 0.5,
      },
      {
        data: this.leftEssay,
        label: 'Remaining Essay Hrs',
        backgroundColor: ['rgb(145 177 219)'],
        borderColor: ['rgb(145 177 219)'],
        borderWidth: 1,
        maxBarThickness: 5,
        borderRadius: 10,
        barPercentage: 0.6,
        categoryPercentage: 0.5,
      },
    ],
  };
  options: {
    layout: {
      scales: {
        y: {
          beingAtZero: true;
          ticks: {
            padding: 5;
          };
        };
      };
    };
  };

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    maintainAspectRatio: true,
  };
  constructor(
    private launchpadService: LaunchpadService,
    private store: Store<any>,
    public monthService: MonthService,
    private modalService: MDBModalService,
    private studyPlannerService: StudyplannerService,
    private firestore: AngularFirestore,
    private popupService: PopupService,
    private route: ActivatedRoute
  ) { }

  controllerForApi: {} = {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.canid = params['canid'];
      this.srid = params['srid'];
    });
    this.showhat = false;

    let month = (this.plannedMonthValidation = new Date().getMonth());
    this.plannedYearValidation = new Date().getFullYear();
    // console.log(this.plannedMonthValidation, 'month');
    const getDashboardData_mf = this.launchpadService.getDashboardData_mf({ canid: this.canid, is_mf: 1 });
    this.observer = this.launchpadService.dashboardData.subscribe(
      (res: any) => {
        if (!_.isEmpty(res)) {
          this.dashboardData = res;
          this.userToken = res.metadata[0].token
          this.getStudentGpStatus();
          this.candidateDetails();
          this.getGameplanList();
          this.showStatusView(1);
          this.getSubjectExamStatus(this.part1SubjectId, this.userToken);
          this.getSubjectExamStatus(this.part2SubjectId, this.userToken);
          this.launchpadService.getImaData2(this.userToken).subscribe((res: any) => {
            this.imaDataPopup = res.data[0].ima_code;
            // console.log(typeofthis.imaDataPopup);
            this.imaMetaDataPopup = res.metadata[0] || [];
            if (this.imaDataPopup == '' || this.imaDataPopup == null) {
              this.showImaSteps = false;
              this.showimaInput = true;
            } else if (this.imaDataPopup.length == 12) {
              this.showImaSteps = true;
              this.showimaInput = true;
            }
            //   // if (this.imaMetaDataPopup.pop_up == 1) {
            //   //   this.imaSteps = 0;
            //   // }
          });
        }
      }
    );

    // this.getDashboardData(this.canid)
  }

  setBedCrem(subjectName) {
    this.launchpadService.beadCreamSubject.next(subjectName);
  }

  updateChart() {
    if (this.chart) {
      this.chart.update();
    }
  }

  beforePrintHandler() {
    for (let id in Chart.instances) {
      Chart.instances[id].resize(200, 200);
    }
  }

  getStudentGpStatus() {
    // console.log(this.userToken)
    this.studyPlannerService.getGpStatus_mf(this.userToken).subscribe((res: any) => {
      if (res && res.status) {
        if (res.data.length > 0) {
          this.studentGpStatus = res.metadata[1].status;
          this.oldGamePlanId = res.metadata[0].int_gameplan_id
          // this.verifyImaCode();
          if (this.studentGpStatus < 2) {
            this.gpStatus = res.data[0].int_gameplan_id;
            this.studentCurrentGp = res.metadata[0].option;
            this.gameplanPreviousStatus =
              res.metadata[3].gameplanPreviousStatus;

            // if (this.userData.ima_code.length == 12) {
            //   // if (this.gameplanPreviousStatus == 1) {
            //   //   let payload = {
            //   //     int_gameplan_id: 2,
            //   //   };
            //   //   this.studyPlannerService.saveGpStatus(payload).subscribe();
            //   //   window.location.reload();
            //   // }
            // } else {
            //   // this.verifyImaCode();
            // }
          }
        } else {
          this.studentGpStatus = res.metadata[0].status;
          this.gameplanPreviousStatus = 0;
          this.studentGpStatus == 0
            ? (this.diableGpStatus = true)
            : (this.diableGpStatus = false);
          // if (this.studentGpStatus == 0) {
          //   console.log(this.studentGpStatus);
          //   this.diableGpStatus = true;
          // }
        }
      }
    });
  }

  // getting userDetails
  candidateDetails() {
    console.log("userToken", this.userToken)
    this.launchpadService.getProfile_mf(this.userToken);
    this.launchpadService.profileData.subscribe((res: any) => {
      this.userData = res[0];
      // if(this.userData.ima_code == null ||this.userData.ima_code == '' ){
      //   this.userData.ima_code =''
      // }
      // console.log(this.userData.ima_code.length);
      if (this.userData?.ima_code !== null && this.userData?.ima_code.length == 12) {
        if (this.gameplanPreviousStatus == 1) {
          // console.log(this.gameplanPreviousStatus);
          let payload = {
            int_gameplan_id: 2,
          };
          this.studyPlannerService.saveGpStatus(payload).subscribe();
        }
      }
      // else {
      //   this.verifyImaCode();
      // }
    });
  }

  getSubjectCLass(subjectId) {
    switch (subjectId) {
      case '41d4d05f967c1b913cabe95876b8a2c7':
        this.plannerClass.subinput = 'form-control input-aud';
        this.plannerClass.substatus = 'head_title head_title_part1';
        this.plannerClass.btnnext = 'btn btn-aud';
        this.plannerClass.substatusbox = 'subject-block-aud';
        this.plannerClass.studytrack =
          'study-track-aud d-flex align-items-center justify-content-around';
        this.plannerClass.studyswitch = 'custom-control-label switch-aud';
        this.plannerClass.prepcard = 'pace-card-aud';
        this.plannerClass.scorecard = 'card-head card-aud';
        this.plannerClass.scoremode = 'text-mode-aud text-center p-3';
        this.plannerClass.studybox = 'aud-study-box';
        this.plannerClass.subjectName = 'Part1';
        this.plannerClass.toggle = 'toggle_part1';
        this.plannerClass.paceRadio = 'custom-control-label switch-aud my-2';
        this.plannerClass.progressBar = 'rgba(185, 204, 130, 1)';
        this.plannerClass.sideLine =
          'list-group border border-0 cma_track track-aud';
        this.getExamStatus(this.part1SubjectId, this.userToken);

        this.plannerDetails.score = this.part1Score;
        break;
      case '6c7ccca37633e15249a4ba916d82317e':
        this.studentCurrentExamPart = [];
        this.plannerClass.subinput = 'form-control input-bec';
        this.plannerClass.substatus = 'head_title head_title_part2';
        this.plannerClass.btnnext = 'btn btn-bec';
        this.plannerClass.substatusbox = 'subject-block-bec';
        this.plannerClass.studytrack =
          'study-track-bec d-flex align-items-center justify-content-around';
        this.plannerClass.studyswitch = 'custom-control-label switch-bec';
        this.plannerClass.prepcard = 'pace-card-bec';
        this.plannerClass.scorecard = 'card-head card-bec';
        this.plannerClass.scoremode = 'text-mode-bec text-center p-3';
        this.plannerClass.studybox = 'bec-study-box';
        this.plannerClass.paceRadio = 'custom-control-label switch-bec my-2';
        this.plannerClass.toggle = 'toggle_part2';
        this.plannerClass.progressBar = 'rgba(232, 203, 124, 0.35)';
        this.plannerClass.subjectName = 'Part2';
        this.plannerClass.sideLine =
          'list-group border border-0 cma_track track-bec';
        // console.log(this.part2Data.date_of_exam);
        this.getExamStatus(this.part2SubjectId, this.userToken);

        this.studentCurrentExamDatePart1 = this.studentCurrentExamDatePart2;
        this.studentCurrentExamPart1 = this.studentCurrentExamPart2;
        this.studentUpdateExamStatus = this.studentCurrentExamPart2;
        this.plannerDetails.score = this.part2Score;
        // this.part1Score = this.part2Score;

        break;
      default:
        break;
    }
  }

  showHatCss(id) {
    if (id != 0) {
      this.showhat = true;
    } else {
      this.showhat = false;
      this.getSubjectExamStatus(this.part1SubjectId, this.userToken);
      this.getSubjectExamStatus(this.part2SubjectId, this.userToken);
    }
  }
  getStudyplanner(subjectId, userToken) {
    this.leftMCQ = [];
    this.leftEssay = [];
    this.leftVideo = [];
    this.subjectId = subjectId;
    this.subjectDetails = this.studyPlannerService.getSubjectDetails(subjectId);
    this.getSubjectCLass(subjectId);
    this.getSubjectExamStatus(subjectId, this.userToken);
    this.studyPlannerService
      .getStudyplanner(this.subjectId, userToken)
      .subscribe((res: any) => {
        if (res) {
          this.chartList = res.data;
          this.plannerDetails.time = res.metadata.no_of_hours_left;
          this.plannerDetails.percent_complition =
            res.metadata.percent_complition;
          this.plannerFormData.studyMode =
            res.metadata.cram_mode === '0' ? false : true;
          this.plannerFormData.studyType =
            res.metadata.classroom_type === '0' ? false : true;
          this.metaPrepCard = res.metadata.preparation_pace;
          let id = 0;
          this.paceCardCSS(id);
          this.graphLoop();
          this.plannerFormData.preparation_pace =
            res.metadata.preparation_pace + '';
        }
      });
  }

  graphLoop() {
    for (const element in this.chartList) {
      let x = this.chartList[element].mcq_time_remain;
      this.transform(x);
      x = this.remainingHrs;
      this.leftMCQ.push(x);
      let y = this.chartList[element].essay_time_remain;
      this.transform(y);
      y = this.remainingHrs;
      this.leftEssay.push(y);
      let z = this.chartList[element].unwatched_video;
      this.transform(z);
      z = this.remainingHrs;

      this.leftVideo.push(z);
    }
    this.barChartData.datasets[0].data = this.leftVideo;
    this.barChartData.datasets[1].data = this.leftMCQ;
    this.barChartData.datasets[2].data = this.leftEssay;
    this.updateChart();
  }

  //show PrepModal
  openPrepModal(PrepModal) {
    PrepModal.show();
  }

  transform(input: string) {
    const time = input.split(':');
    const minutes = parseInt(time[1]) + parseInt(time[0]) * 60;
    if (minutes > 59) {
      this.remainingHrs = Math.round(minutes / 60);
    } else {
      this.remainingHrs = minutes / 60;
    }
  }

  //subject status tabs

  showStatusView(id) {
    this.showStatus = id;
    if (this.showStatus == 1) {
      this.examStatusTabCss = 'd-flex align-items-center active';
      this.studyTrackTabCss = this.preparationTabCss =
        'd-flex align-items-center';
    } else if (this.showStatus == 2) {
      this.studyTrackTabCss = 'd-flex align-items-center active';
      this.examStatusTabCss = this.preparationTabCss =
        'd-flex align-items-center';
    } else {
      this.examStatusTabCss = this.studyTrackTabCss =
        'd-flex align-items-center';
      this.preparationTabCss = 'd-flex align-items-center active';
    }
  }

  getPlannerGraphdata() {
    this.leftMCQ = [];
    this.leftEssay = [];
    this.leftVideo = [];

    // if (this.plannerFormData.studyType == false) {
    //   this.plannerFormData.studyType = '0';
    // } else {
    //   this.plannerFormData.studyType = '1';
    // }
    // if (this.plannerFormData.studyMode == false) {
    //   this.plannerFormData.studyMode = '0';
    // } else {
    //   this.plannerFormData.studyMode = '1';
    // }
    this.studyPlannerService
      .getStudyplanner(this.subjectId, this.userToken)
      .subscribe((res: any) => {
        if (res) {
          this.chartList = res.data;
          this.plannerDetails.time = res.metadata.no_of_hours_left;
          this.plannerDetails.percent_complition =
            res.metadata.percent_complition;
          this.plannerFormData.studyMode =
            res.metadata.cram_mode === '1' ? true : false;
          this.plannerFormData.studyType =
            res.metadata.classroom_type === '1' ? true : false;
          this.progressBar = res.metadata.percent_complition;
          // this.progressBar = '59';
          this.graphLoop();
        }
      });
  }
  // checkboxCss(id) {
  //   if (id == 1) {
  //     if (this.plannerFormData.studyType == false) {
  //       this.plannerFormData.studyType = '0';
  //     } else {
  //       this.plannerFormData.studyType = '1';
  //     }
  //   } else {
  //     if (this.plannerFormData.studyMode == false) {
  //       this.plannerFormData.studyMode = '0';
  //     } else {
  //       this.plannerFormData.studyMode = '1';
  //     }
  //   }
  // }

  changeClassroom(event1) {
    // console.log(event, 'type');
    if (event1 == true) {
      this.plannerFormData.studyType = true;
    } else {
      this.plannerFormData.studyType = false;
    }

    this.getPlannerGraphdata();
  }

  changeMode(event2) {
    if (event2 == true) {
      this.plannerFormData.studyMode = true;
    } else {
      this.plannerFormData.studyMode = false;
    }
    this.getPlannerGraphdata();
  }

  changeModeLaunchpad(event, val) {
    // console.log('event', event.target.checked);
    const param = {
      subject_id: val,
      questions_mode: event.target.checked ? 1 : 0,
    };
    this.launchpadService.changeQuestionsMode(param).subscribe((res: any) => {
      if (res.status) {
        console.log("res.data", res.data)
        // Swal.fire({
        //   title: 'Study Mode Changed',
        //   // text: "Study Mode Changed",
        //   icon: 'success',
        //   confirmButtonColor: '#3085d6',
        //   confirmButtonText: 'Proceed',
        //   allowEscapeKey : false,
        //   allowOutsideClick: false
        // }).then((result) => {
        //   if (result.isConfirmed) {
        var temp = res.data[0];
        const token = temp.token;
        localStorage.setItem('secrettoken', temp.token);

        this.firestore
          .collection('isLoggedIn_CMA')
          .doc(temp.sid + '')
          .set({ token });

        this.getDashboardData(this.canid);
        //   }
        // })
      } else {
        this.popupService.error('An error occured while changing the mode');
      }
    });
  }

  getDashboardData(canid: any) {
    const getDashboardData_mf = this.launchpadService.getDashboardData_mf({ canid, is_mf: 1 });
    this.observer = this.launchpadService.dashboardData.subscribe(
      (res: any) => {
        if (!_.isEmpty(res)) {
          this.dashboardData = res;
          this.userToken = res.metadata[0].token
        }
      }
    );
  }

  changePace(event) {
    this.plannerFormData.preparation_pace = event.target.value;

    let id = 1;

    this.paceCardCSS(id);
    this.getPlannerGraphdata();
  }
  paceCardCSS(id) {
    let paceCard: any;
    if (id == 1) {
      paceCard = this.plannerFormData.preparation_pace;
    } else {
      paceCard = this.metaPrepCard;
    }
    if (paceCard == '1') {
      this.paceChecked1 = 'custom-control-input';
      this.paceChecked2 =
        this.paceChecked3 =
        this.paceChecked4 =
        this.plannerClass.paceRadio;

      this.plannerDetails.paceMode = 'Exam Pace';
      this.prepcard.prepcard1 = this.plannerClass.prepcard;
      this.prepcard.prepcard2 =
        this.prepcard.prepcard3 =
        this.prepcard.prepcard4 =
        'pace-card-gray';
    } else if (paceCard == '2') {
      this.paceChecked2 = 'custom-control-input';
      this.paceChecked1 =
        this.paceChecked3 =
        this.paceChecked4 =
        this.plannerClass.paceRadio;

      this.plannerDetails.paceMode = 'Fast';
      this.prepcard.prepcard2 = this.plannerClass.prepcard;
      this.prepcard.prepcard1 =
        this.prepcard.prepcard3 =
        this.prepcard.prepcard4 =
        'pace-card-gray';
    } else if (paceCard == '3') {
      this.paceChecked3 = 'custom-control-input';
      this.paceChecked2 =
        this.paceChecked1 =
        this.paceChecked4 =
        this.plannerClass.paceRadio;

      this.plannerDetails.paceMode = 'Normal';
      this.prepcard.prepcard3 = this.plannerClass.prepcard;
      this.prepcard.prepcard1 =
        this.prepcard.prepcard2 =
        this.prepcard.prepcard4 =
        'pace-card-gray';
    } else if (paceCard == '4') {
      this.paceChecked4 = 'custom-control-input';
      this.paceChecked2 =
        this.paceChecked3 =
        this.paceChecked1 =
        this.plannerClass.paceRadio;

      this.plannerDetails.paceMode = 'Slow';
      this.prepcard.prepcard4 = this.plannerClass.prepcard;
      this.prepcard.prepcard1 =
        this.prepcard.prepcard2 =
        this.prepcard.prepcard3 =
        'pace-card-gray';
    }
  }
  //DatePicket
  preYear() {
    this.year = this.year - 1;
  }
  nextYear() {
    this.year = this.year + 1;
  }

  setDate(row) {
    const months = row.value > 9 ? row.value : '0' + row.value;
    switch (this.switchId) {
      case 1:
        this.part1Data.date_of_exam = row.month + '-' + this.year;
        break;
      case 2:
        this.part2Data.date_of_exam = row.month + '-' + this.year;
        break;
      default:
        break;
    }
  }

  //
  showEditDropdown() {
    if (this.showEditOptions == 0) {
      this.showEditOptions = 1;
    } else {
      this.showEditOptions = 0;
    }
  }

  getGameplanList() {
    this.studyPlannerService.getGameStatusList_mf(this.userToken).subscribe((res: any) => {
      if (res) {
        this.gameplanList = res.data;
      }
    });
  }

  getExamStatus(subjectId, userToken) {
    this.studyPlannerService
      .getExamStatusList({ subjectId: subjectId, userToken })
      .subscribe((res: any) => {
        if (res && res.status) {
          this.studentUpdateExamStatusList = res.data;
          this.plannerDetails.time = res.metadata.no_of_hours_left;
          this.plannerDetails.percent_complition =
            res.metadata.percent_complition;
          let id = 0;
          this.paceCardCSS(id);
          if (this.studentUpdateExamStatusList.length == 0) {
            this.studentCurrentExamDatePart1 = '';
            this.studentUpdateExamStatus = '';
            this.part1Data.date_of_exam = '';
          }
        }
      });
  }

  getSubjectExamStatus(subjectId, userToken) {
    if (this.controllerForApi.hasOwnProperty(subjectId) && this.controllerForApi[subjectId] > 4) {
      this.studyPlannerService
        .getSubjectEs({ subjectId: subjectId, userToken })
        .subscribe((res: any) => {
          if (res && res.status) {
            this.studentCurrentExamPart = res.data;
            for (const element of this.studentCurrentExamPart) {
              if (subjectId == this.part1SubjectId) {
                element.subject_id = 105
              }
              else if (subjectId == this.part2SubjectId) {
                element.subject_id = 106
              }
              element.score = res.metadata[0].score + '';
              element.date_of_exam = res.metadata[0].date_of_exam
            }
            if (this.studentCurrentExamPart.length != 0) {
              if (subjectId == this.part1SubjectId) {
                this.studentUpdateExamStatus = res.data[0].int_examstatus_id;
                this.part1Data.date_of_exam = res.metadata[0].date_of_exam;
                this.studentCurrentExamPart1 = res.data[0].option;
                this.studentCurrentExamDatePart1 = res.metadata[0].date_of_exam;
                this.gameplanExamId = res.metadata[0].gameplan_exam_id;
                this.plannerDetails.dateOfExam = this.studentCurrentExamDatePart1;
                this.examDateLabel = this.studentUpdateExamStatus;
                this.examDateLabel1 = this.studentUpdateExamStatus;
                this.part1Score = res.metadata[0].score + '';
                if (this.part1Score == null) {
                  this.part1Score = '';
                }
                if (
                  this.gameplanPreviousStatus >= 6 &&
                  this.studentUpdateExamStatus != 3
                ) {
                  this.studentUpdateExamStatus = '';
                }
                if (this.part1Score == 0) {
                  this.part1Score = '';
                } else {
                  this.part1Score;
                }

                this.part1ScheduleDate = res.metadata[0].date_of_exam;
                this.part1ExamstatusThree = res.data[0].int_examstatus_id;
              }
              if (subjectId == this.part2SubjectId) {
                this.studentCurrentExamPart2 = res.data[0].option;
                this.studentCurrentExamDatePart2 = res.metadata[0].date_of_exam;
                this.part2Score = res.metadata[0].score + '';

                if (this.part2Score == 0) {
                  this.part2Score = '';
                } else {
                  this.part2Score;
                }
                this.plannerDetails.dateOfExam = this.studentCurrentExamDatePart2;
                this.studentUpdateExamStatus = res.data[0].int_examstatus_id;
                this.gameplanExamId = res.metadata[0].gameplan_exam_id;
                this.examDateLabel = this.studentUpdateExamStatus;
                this.examDateLabel2 = this.studentUpdateExamStatus;
                this.part2Data.date_of_exam = res.metadata[0].date_of_exam;
                this.part2ScheduleDate = res.metadata[0].date_of_exam;
                this.part2ExamstatusThree = res.data[0].int_examstatus_id;
                if (this.part2Score == null) {
                  this.part2Score = '';
                }
                if (
                  this.gameplanPreviousStatus >= 6 &&
                  this.studentUpdateExamStatus != 3
                ) {
                  this.studentUpdateExamStatus = '';
                }
              }
            }
          }
        });
    } else if (!this.controllerForApi.hasOwnProperty(subjectId)) {
      this.controllerForApi[subjectId] = 0;
    }

    this.controllerForApi[subjectId] = this.controllerForApi[subjectId] + 1;
  }

  swalPopup(icon, title, message?) {
    Swal.fire({
      icon: icon,
      title: title,
      text: message,
      allowOutsideClick: false,
    }).then(() => {
      window.location.reload();
    });
  }
  swalPopup1(icon, title, message?) {
    Swal.fire({
      icon: icon,
      title: title,
      text: message,
      allowOutsideClick: false,
    });
  }

  saveGpStatus() {
    // if (
    //   this.gpStatus > 4 &&
    //   this.part1ExamstatusThree != 3 &&
    //   this.part2ExamstatusThree != 3
    // ) {
    //   this.swalPopup1(
    //     'info',
    //     'Attention',
    //     'You are not allowed to go Further untill you pass both the subjects'
    //   );
    //   this.gpStatus = this.gameplanPreviousStatus;
    // }
    // // else if (this.gpStatus == this.gameplanPreviousStatus) {
    // //   this.swalPopup1(
    // //     'info',
    // //     'Attention',
    // //     'You are not allowed to submit the same Gameplan status'
    // //   );
    // //   this.gpStatus = this.gameplanPreviousStatus;
    // // }
    // else
    if (this.userData.ima_code.length == 12 || this.gpStatus == 1) {
      this.insertGpStatus();
    } else if (this.imaMetaDataPopup.pop_up == 1) {
      this.imaSwalpopup();
    } else {
      this.insertGpStatus();
      // this.imaSwalpopup();
      // Swal.fire({
      //   text: 'Check IMA registration steps',
      //   icon: 'info',
      //   showCloseButton: true,
      //   confirmButtonColor: '#3085d6',
      //   confirmButtonText: 'Click here',
      //   title: 'Attention',
      //   allowOutsideClick: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.checkImaSteps = 1;
      //     this.gpStatus;
      //   } else if (result.isDismissed) {
      //     this.gpStatus;
      //     this.checkImaSteps = 0;
      //   }
      // });
    }
  }

  imaSwalpopup() {
    Swal.fire({
      title: '',
      text: 'Our records indicate that you have not registered with IMA yet! To enter Customer ID click on "Click here"',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Enter IMA Customer ID/Membership Number',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          this.imaDataPopup == '' &&
          this.imaMetaDataPopup.pop_up == 0 &&
          this.imaMetaDataPopup.can_skip == 0
        ) {
          this.imaMetaDataPopup.pop_up = 0;
          this.imaMetaDataPopup.can_skip == 0;
          this.openImaPopup(this.imaDataPopup, this.imaMetaDataPopup);
        } else if (
          this.imaDataPopup == '' &&
          this.imaMetaDataPopup.pop_up == 1 &&
          this.imaMetaDataPopup.can_skip == 1
        ) {
          this.openImaPopup(this.imaDataPopup, this.imaMetaDataPopup);
        } else {
          this.verifyImaCode();
        }
      }
    });
  }
  insertGpStatus() {
    if (this.gameplanPreviousStatus <= this.gpStatus) {
      // let payload = {
      //   int_gameplan_id: this.gpStatus,
      // };
      // this.studyPlannerService.saveGpStatus(payload).subscribe((res: any) => {
      //   if (res && res.status) {
      //     this.swalPopup(
      //       'success',
      //       'Your status has been updated successfully!'
      //     );
      //   } else {
      //     this.swalPopup('info', '', res.message);
      //   }
      // });
      let payload = {
        email: this.userData.email,
        sr_spoc_id: this.srid,
        old_gp_status: this.gameplanPreviousStatus,
        updated_gp_status: this.gpStatus,
        subject_info: this.studentCurrentExamPart,
        can_id: this.canid,
      }
      this.studyPlannerService.update_sr_log_and_gp_details(payload, this.userToken).subscribe((res: any) => {
        if (res && res.status) {
          this.swalPopup(
            'success',
            'Your status has been updated successfully!'
          );
        } else {
          this.swalPopup('info', '', res.message);
        }
      })
    } else {
      this.swalPopup(
        'info',
        'Attention',
        'You are not allowed to go back to previous status'
      );
      this.gpStatus = this.gameplanPreviousStatus;
    }
  }

  saveExamScheduleStatus() {
    // console.log(
    //   this.studentCurrentExamPart2,
    //   'studentCurrentExamPart2',
    //   this.part2Data.date_of_exam,
    //   'part2Data.date_of_exam',
    //   this.part1Data.date_of_exam,
    //   'part1Data.date_of_exam',
    //   this.studentCurrentExamPart1,
    //   'studentCurrentExamPart1'
    // );
    let year1, plannedDate1, year2, plannedDate2;
    if (
      this.subjectId == this.part1SubjectId &&
      (this.studentUpdateExamStatus == 1 || this.studentUpdateExamStatus == 4)
    ) {
      plannedDate1 = this.part1Data.date_of_exam.split('-');
      year1 = JSON.parse(plannedDate1[1]);
      // yearData1= this.plannedYearValidation
      // monthData1 = monthData1 + '-' + year1;
    } else if (
      this.subjectId == this.part2SubjectId &&
      (this.studentUpdateExamStatus == 1 || this.studentUpdateExamStatus == 4)
    ) {
      plannedDate2 = this.part2Data.date_of_exam.split('-');
      year2 = plannedDate2[1];
    }

    let validDateList = ['Jan', 'Feb', 'May', 'Jun', 'Sep', 'Oct'];

    let validDate1 = this.part1Data.date_of_exam;
    let validDate2 = this.part2Data.date_of_exam;

    if (
      this.part1Data.date_of_exam == this.part2ScheduleDate &&
      this.studentUpdateExamStatus == 2
    ) {
      this.swalPopup1(
        'info',
        '',
        'Note that "Scheduled Date" cannot be the same for both parts'
      );
    } else if (
      this.part2Data.date_of_exam == this.part1ScheduleDate &&
      this.studentUpdateExamStatus == 2
    ) {
      this.swalPopup1(
        'info',
        '',
        'Note that "Scheduled Date" cannot be the same for both parts'
      );
    } else if (
      this.subjectId == this.part1SubjectId &&
      year1 < this.plannedYearValidation &&
      (this.studentUpdateExamStatus == 1 || this.studentUpdateExamStatus == 4)
    ) {
      this.swalPopup1(
        'info',
        '',
        'Please update your date before you continue'
      );
    } else if (
      this.subjectId == this.part2SubjectId &&
      year2 < this.plannedYearValidation &&
      (this.studentUpdateExamStatus == 1 || this.studentUpdateExamStatus == 4)
    ) {
      this.swalPopup1(
        'info',
        '',
        'Please update your date before you continue'
      );
    }
    // else if (this.studentCurrentGp == '5' && this.studentUpdateExamStatus > 2) {
    //   this.swalPopup1(
    //     'info',
    //     '',
    //     'Kindly update the exam status for the subject/subjects that you have appeared.'
    //   );
    // }
    else if (
      this.part1Data.date_of_exam == this.part2ScheduleDate &&
      this.studentUpdateExamStatus == 5
    ) {
      this.swalPopup1('info', '', 'Can not Reschedule on the Same day');
    } else if (
      this.part2Data.date_of_exam == this.part1ScheduleDate &&
      this.studentUpdateExamStatus == 5
    ) {
      this.swalPopup1('info', '', 'Can not Reschedule on the Same day');
    }
    // else if (
    //   validDateList.includes(validDate1[1]) == false &&
    //   this.studentUpdateExamStatus == 2
    // ) {
    //   this.swalPopup1(
    //     'info',
    //     'The scheduled date mentioned is not valid.',
    //     'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
    //   );
    // } else if (
    //   validDateList.includes(validDate2[1]) == false &&
    //   this.studentUpdateExamStatus == 5
    // ) {
    //   console.log(validDate2[1], this.studentUpdateExamStatus);
    //   this.swalPopup1(
    //     'info',
    //     'The scheduled date mentioned is not valid.',
    //     'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
    //   );
    // }
    else if (
      this.subjectId == this.part2SubjectId &&
      this.studentUpdateExamStatus == 3 &&
      (this.part2Score < 360 || this.part2Score > 500)
    ) {
      this.swalPopup1('info', '', 'Kindly Update Valid Score');
    } else if (
      this.subjectId == this.part1SubjectId &&
      this.studentUpdateExamStatus == 3 &&
      (this.part1Score < 360 || this.part1Score > 500)
    ) {
      this.swalPopup1('info', '', 'Kindly Update Valid Score');
    } else {
      if (
        this.part2Data.date_of_exam == null ||
        this.part1Data.date_of_exam == null
      ) {
        this.swalPopup1('info', '', 'Kindly Update Exam Status');
      }

      let popup1: any;
      let popup2: any;
      let dateOfExam: any;
      let score: any;
      validDate1 = validDate1.split('-');
      validDate2 = validDate2.split('-');
      if (
        this.subjectId == this.part1SubjectId &&
        validDateList.includes(validDate1[1]) == false &&
        (this.studentUpdateExamStatus == 2 || this.studentUpdateExamStatus == 5)
      ) {
        this.swalPopup1(
          'info',
          'The scheduled date mentioned is not valid.',
          'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
        );
        popup1 = 1;
      } else if (
        this.subjectId == this.part2SubjectId &&
        validDateList.includes(validDate2[1]) == false &&
        (this.studentUpdateExamStatus == 2 || this.studentUpdateExamStatus == 5)
      ) {
        this.swalPopup1(
          'info',
          'The scheduled date mentioned is not valid.',
          'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
        );
        popup2 = 1;
      } else if (
        this.subjectId == this.part1SubjectId &&
        validDateList.includes(validDate1[0]) == false &&
        (this.studentUpdateExamStatus == 1 || this.studentUpdateExamStatus == 4)
      ) {
        this.swalPopup1(
          'info',
          'The Planned Month mentioned is not valid.',
          'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
        );
        popup1 = 1;
      } else if (
        this.subjectId == this.part2SubjectId &&
        validDateList.includes(validDate2[0]) == false &&
        (this.studentUpdateExamStatus == 1 || this.studentUpdateExamStatus == 4)
      ) {
        this.swalPopup1(
          'info',
          'The Planned Month mentioned is not valid.',
          'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
        );
        popup2 = 1;
      } else if (
        this.subjectId == this.part1SubjectId &&
        validDateList.includes(validDate1[1]) == false &&
        (this.studentUpdateExamStatus == 6)
      ) {
        this.swalPopup1(
          'info',
          'The Appeared date mentioned is not valid.',
          'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
        );
        popup1 = 1;
      } else if (
        this.subjectId == this.part2SubjectId &&
        validDateList.includes(validDate2[1]) == false &&
        (this.studentUpdateExamStatus == 6)
      ) {
        this.swalPopup1(
          'info',
          'The Appeared date mentioned is not valid.',
          'Note that CMA exams can be scheduled only during Jan- Feb, May-June, Sept-Oct windows in a year.'
        );
        popup2 = 1;
      } else if (
        (this.part1Score == null || this.part1Score == '') &&
        this.studentUpdateExamStatus == 3
      ) {
        this.swalPopup1('info', '', 'Kindly Update Score');
        popup1 = 1;
      } else if (
        (this.part2Score == null || this.part2Score == '') &&
        this.studentUpdateExamStatus == 3
      ) {
        this.swalPopup1('info', '', 'Kindly Update Score');
        popup2 = 1;
      }

      if (popup1 != 1 && this.subjectId == this.part1SubjectId) {
        dateOfExam = this.part1Data.date_of_exam;
        score = this.part1Score;

        let payload = {
          gameplanExamId: this.gameplanExamId,
          subjectId: this.subjectId,
          examStatusId: this.studentUpdateExamStatus,
          dateOfExam: dateOfExam,
          score: score,
        };
        this.studyPlannerService
          .saveExamStatus2(this.userToken, payload)
          .subscribe((res: any) => {
            if (res && res.status) {
              this.swalPopup(
                'success',
                'Your status has been updated successfully!'
              );
              // this.getStudentGpStatus();
              // this.getSubjectExamStatus(this.subjectId);
            } else {
              this.swalPopup('info', '', res.message);
            }
          });
      } else if (popup2 != 1 && this.subjectId == this.part2SubjectId) {
        dateOfExam = this.part2Data.date_of_exam;
        score = this.part2Score;
        let payload = {
          gameplanExamId: this.gameplanExamId,
          subjectId: this.subjectId,
          examStatusId: this.studentUpdateExamStatus,
          dateOfExam: dateOfExam,
          score: score,
        };
        this.studyPlannerService
          .saveExamStatus2(this.userToken, payload)
          .subscribe((res: any) => {
            if (res && res.status) {
              this.swalPopup(
                'success',
                'Your status has been updated successfully!'
              );
              // this.getStudentGpStatus();
              // this.getSubjectExamStatus(this.subjectId);
            } else {
              this.swalPopup('info', '', res.message);
            }
          });
      }
    }
  }

  showHome() {
    this.tabs.setActiveTab(1);
  }
  showPart1() {
    this.tabs.setActiveTab(2);
  }
  showPart2() {
    this.tabs.setActiveTab(3);
  }

  editGPStatus() {
    if (this.diableGpStatus == false) {
      this.diableGpStatus = true;
      this.studentGpStatus = 0;
    } else {
      this.diableGpStatus = false;
      this.studentGpStatus = 1;
    }
  }
  time() {
    this.part1Data.date_of_exam = '';
    this.part2Data.date_of_exam = '';
    this.scheduleDate = '';
    this.examDate = '';
  }

  dateLabel(row) {
    if (row === 2 || row === 5) {
      return 'Scheduled Date';
    } else if (row === 3) {
      return 'Result Date';
    } else {
      return 'Planned Month';
    }
  }

  verifyImaCode() {
    if (!this.imaverify) {
      this.imaverify = true;
      this.launchpadService.getImaData2(this.userToken).subscribe((res: any) => {
        let imaData, imaMetaData;
        imaData = res.data;
        imaMetaData = res.metadata[0] || [];
        if (imaMetaData.pop_up) {
          this.openImaPopup(imaData, imaMetaData);
        } else {
          this.launchpadService.setImaModalData('ima updated');
        }
      });
    }
  }
  scoreValidation(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  toggleShow() {
    this.isShown = !this.isShown;
    // if (this.showExamStatus == true) {
    //   this.showExamStatus = false;
    // } else {
    //   this.showExamStatus = true;
    // }
  }

  openImaPopup(imaData, imaMetaData) {
    this.imaModalRef = this.modalService.show(ImaPopupComponent, {
      data: {
        imaData: imaData,
        imaMetaData: imaMetaData,
      },
      backdrop: true,
      keyboard: false,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-centered modal-lg',
      containerClass: 'modal fade overflow-y',
      animated: true,
    });
  }

  submitIma() {
    this.submitted = true;
    let payload = {
      ima_code: this.imaCode.toString(),
      skip_code: '1',
    };
    console.log(this.imaCode);
    if (this.imaCode.toString().length == 12) {
      this.launchpadService.updateImaData(payload).subscribe((res: any) => {
        // this.launchpadService.setImaModalData('ima updated');
        Swal.fire({
          icon: 'success',
          text: 'Your IMA Customer ID/Membership Number is successfully updated. Note that we will verify the same with IMA.',
        }).then(async () => {
          if (this.gameplanPreviousStatus < 3) {
            let payload = { int_gameplan_id: 3 };
            this.studyPlannerService.saveGpStatus(payload).subscribe();
          }

          window.location.reload();

          this.showImaSteps = false;
        });
      });
    }
  }
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  showInput() {
    if (this.showimaInput == false) {
      console.log('true');
      // showimaInput
      this.showimaInput = true;
      this.showExamStatus = true;
    } else {
      console.log('false');
      this.showExamStatus = false;

      this.showimaInput = false;
    }
  }
}
