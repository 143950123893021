import { isLoggedIn } from 'src/app/_store/selectors/auth.select';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AppState } from '../reducers';
import { Store, select } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';



@Injectable()
export class AuthGuard  {
  constructor(
    private route: Router,
    private store: Store<AppState>
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(isLoggedIn),
      tap(loggedIn => {
        if (!loggedIn)  {
          this.route.navigateByUrl('/');
        }
      })
    )
  }
}
