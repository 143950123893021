import { ContactModalComponent } from 'projects/cpa/src/app/pages/modal-pages/contact-modal/contact-modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { SubjectService } from 'src/app/_clusters/services/subject.service';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
// import { FeedbackComponent } from 'projects/cpa/src/app/pages/modal-pages/feedback/feedback.component';
import { FeedbackComponent } from 'projects/jobs/src/app/pages/feedback/feedback.component';
import { LogsModalComponent } from 'projects/cpa/src/app/pages/modal-pages/logs-modal/logs-modal.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GameplanModalComponent } from 'projects/cpa/src/app/pages/modal-pages/gameplan-modal/gameplan-modal.component';
import * as _ from 'underscore';
import { LoginService } from 'src/app/_clusters/services/login.service';
import { ForumServiceService } from 'projects/cpa/src/app/pages/forum/forum-service.service';
import { FacsPaymentModalComponent } from 'projects/cpa/src/app/pages/modal-pages/facs-payment-modal/facs-payment-modal.component';
import { GamePlanService } from 'src/app/_clusters/services/game-plan.service';
import { AdditionalContentUpdateComponent } from 'projects/cpa/src/app/pages/modal-pages/additional-content-update/additional-content-update.component';
import { ContentUpdateComponent } from 'projects/cpa/src/app/pages/modal-pages/content-update/content-update.component';
import { PopupService } from 'src/app/_clusters/services/popup.service';
import { MacService } from 'src/app/_clusters/services/mac.service';
import { Logout, UserRequested } from 'src/app/_store/actions/auth.actions';
import { LoadSearchQuestionAction } from 'src/app/_store/actions/question.action';
import { LoadStudentAction } from 'src/app/_store/actions/student.action';
import { JobsService } from 'projects/jobs/src/app/common/jobs.service';
import { JobsPopupComponent } from '../../../../projects/jobs/src/app/common/jobs-popup/jobs-popup.component';
import { CpaEvolutionComponent } from 'projects/cpa/src/app/pages/modal-pages/cpa-evolution/cpa-evolution.component';

@Component({
  selector: 'app-miles-header',
  templateUrl: './miles-header.component.html',
  styleUrls: ['./miles-header.component.scss'],
})
export class MilesHeaderComponent implements OnInit {
  @ViewChild('aud') public aud: any;
  @ViewChild('bec') public bec: any;
  @ViewChild('reg') public reg: any;
  @ViewChild('far') public far: any;
  // For Mobile Menu Begin
  @ViewChild('aud_m') public aud_m: any;
  @ViewChild('bec_m') public bec_m: any;
  @ViewChild('reg_m') public reg_m: any;
  @ViewChild('far_m') public far_m: any;
  @ViewChild('launch_m') public launch_m: any;

  // @ViewChild('facsPaymentStartupModal', { static: true }) facsPaymentStartupModal: ModalDirective;
  // @ViewChild('stateboardId') ngSelectComponent: NgSelectComponent;
  // For Mobile Menu Ends
  sidebarclick = 'sidebar-expand';
  bodyblock = 'body-block';
  menuhide = 'menu-show';
  logohide = true;
  falarge = 'fa-normal';
  fnav = 'fnav-normal';
  textAlign = 'text-left';
  // search = '';
  launch = 'Launch AUD';
  report = 'View Report';
  isMobile: boolean = false;
  questionText = '';
  studentDetails!: Observable<Array<any>>;
  userData: any = {};
  subjectDetails: any = {};
  modalRef!: MDBModalRef;
  Breadcrumbs: any;
  defaultImage =
    'https://storage.googleapis.com/miles-next_website-lms-gptool/MAC/user.jpg';
  modalClass = '';
  forumLaunchpadData: any = [];
  notificationData: any = [];
  notificationObserver =
    this.forumServiceService.notificationObserver.asObservable();
  forumLaunchpadObserver =
    this.forumServiceService.forumLaunchpadObserver.asObservable();
  // stateboardArray: any = [];
  // stateBoardId: any;
  // planDetails: any = {};
  // transcript_email: string;
  // evaluation_email: string;
  // agency: any;
  // stateBoardValue: any;
  // stateboardValuelist = {13:'Guam (GU)',37:'North Dakota (ND)',41:'Pennsylvania (PA)',22:'Maine'};
  // evaluationAgency = {13:15, 37:17, 41:16, 22:28}
  // stateEvalStatus: any;
  facs_code: any;
  showFacs: boolean = false;
  // changeNgSelectStatus: any = true;
  // isCopied: boolean = false;
  is_only_bridgecourse: any;
  is_cpa101_access: any;
  is_only_integrity: any;
  is_only_aicpa: any;
  dashboardMeta = this.loginService.dashboardMeta.asObservable();
  unsubscribemeta: any;
  unsubscribeMeta: any;
  is_alumni: any = 0;
  isFof: any = localStorage.getItem('islogged_fof')

  is_only_aicpa_inter = 0;
  constructor(
    private store: Store<any>,
    private formBuilder: UntypedFormBuilder,
    private subjectService: SubjectService,
    private popupService: PopupService,
    private firestore: AngularFirestore,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private modalService: MDBModalService,
    private rout: ActivatedRoute,
    private forumServiceService: ForumServiceService,
    private loginService: LoginService,
    private gamePlanService: GamePlanService,
    private macservice: MacService,
    private jobsService: JobsService
  ) { }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.is_alumni = localStorage.getItem('enlni');
    this.rout.params.subscribe((param) => {
      this.subjectService.Breadcrumbs.subscribe((data) => {
        if (data) {
          this.Breadcrumbs = data.subject;
        }
      });
    });
    // this.store.dispatch(new UserRequested());
    this.store
      .select((store: any) => store.auth.user)
      .subscribe((data: any) => {
        //  // console.log('error2',data);
        if (data) {
          this.subjectDetails = data;
        }
      });

    this.unsubscribemeta = this.dashboardMeta.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.is_only_bridgecourse = res[0].is_only_bridgecourse;
        this.is_cpa101_access = res[0].is_cpa101_access;
      }
    });

    this.loginService.getUserByToken().subscribe((data) => {
      // this.openGameplanModal();
      if (!_.isEmpty(data)) {
        if (data.gameplan_details[0].gameplan_filled === 0) {
          this.openGameplanModal();
        }
      }
    });

    this.studentDetails = this.store.select(
      (store: any) => store.student?.student_details
    );
    this.studentDetails.subscribe((data: any) => {
      if (data) {
        this.userData = data;
        if (this.userData?.is_only_iimi === 1) {
          if (this.router.url == "/lms/launchpad") {
            this.router.navigate(['/lms/university-programme']);
          }
        }
        this.is_only_aicpa = data.is_only_aicpa;
        this.is_only_integrity = data.is_only_integrity;
        this.is_only_aicpa_inter = data.is_only_aicpa_inter;
        this.firestore
          .collection('isLoggedIn')
          .doc(data.otb_id + '')
          .valueChanges()
          .subscribe((res: any) => {
            if (res) {
              if (
                res.token !== localStorage.getItem(environment.authTokenKey) &&
                localStorage.getItem(environment.authTokenKey)
              ) {
                Swal.fire({
                  html: `<p>Your session was terminated due to login from another browser/device
                . If it wasn't you,​ ​please change your password on next login or contact <a href="mailto:support@mileseducation.com">
                Miles Support Team</a>.</p>`,
                  icon: 'error',
                  backdrop: true,
                  allowOutsideClick: false,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Ok',
                  allowEscapeKey: false,
                }).then((result) => {
                  if (result.value) {
                    // this.modalRef.hide();
                    document.location.href = '/';
                    // this.router.navigate(['/']);
                  }
                });
              }
            }
          });
      } else {
        this.store.dispatch(new LoadStudentAction());
      }
    });
    this.forumServiceService.forumLaunchpadForum();
    this.forumLaunchpadObserver.subscribe((data) => {
      if (data) {
        this.forumLaunchpadData = data;
      }
    });
    this.getForumNotifications();
    this.notificationObserver.subscribe((data) => {
      if (data) {
        this.notificationData = data;
      }
    });
    this.getstudent_facsdetails();
    // this.getProfileDetailsCpa101();
  }

  // getProfileDetailsCpa101(){
  //   this.jobsService.getjobprofiledetails().subscribe((res: any) => {
  //     if (!(res && res.data.length > 0)) {
  //     //  console.log('in if')
  //      this.modalRef = this.modalService.show(JobsPopupComponent, {
  //       backdrop: true,
  //       keyboard: false,
  //       focus: true,
  //       show: false,
  //       ignoreBackdropClick: true,
  //       class: 'modal-dialog modal-dialog-centered modal-lg',
  //       containerClass: 'modal fade overflow-y',
  //       animated: true
  //     });
  //     }
  //   });
  // }

  menuclick() {
    this.bodyblock === 'body-block'
      ? (this.bodyblock = 'body-block-lg')
      : (this.bodyblock = 'body-block');
    this.sidebarclick === 'sidebar-expand'
      ? (this.sidebarclick = 'sidebar-normal')
      : (this.sidebarclick = 'sidebar-expand');
    this.menuhide === 'menu-show'
      ? (this.menuhide = 'menu-hide')
      : (this.menuhide = 'menu-show');
    this.falarge === 'fa-normal'
      ? (this.falarge = 'fa-large')
      : (this.falarge = 'fa-normal');
    this.fnav === 'fnav-normal'
      ? (this.fnav = 'fnav-collapse')
      : (this.fnav = 'fnav-normal');
    this.textAlign === 'text-left'
      ? (this.textAlign = 'text-center')
      : (this.textAlign = 'text-left');
    this.logohide === true ? (this.logohide = false) : (this.logohide = true);
  }
  searchQuestion() {
    //// console.log(this.questionText);
    this.store.dispatch(
      new LoadSearchQuestionAction({ question_id: this.questionText })
    );
  }

  logout() {
    this.firestore
      .collection('isLoggedIn')
      .doc(this.userData.otb_id + '')
      .delete();
    this.store.dispatch(new Logout());
  }

  closePopover(index: any) { }
  showFeedbackModal() {
    this.modalRef = this.modalService.show(FeedbackComponent, {
      backdrop: true,
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-scrollable feedback_modal_fix_width',
      containerClass: 'right',
      animated: true,
    });
  }
  openLogModal() {
    this.modalRef = this.modalService.show(LogsModalComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-scrollable',
      containerClass: 'right',
      animated: true,
    });
  }
  openGameplanModal() {
    this.modalRef = this.modalService.show(GameplanModalComponent, {
      backdrop: true,
      keyboard: false,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-dialog-centered modal-lg',
      containerClass: 'modal fade overflow-y',
      animated: true,
    });
  }

  openContactModal() {
    if (
      this.is_only_bridgecourse == 1 ||
      this.is_only_aicpa == 1 ||
      this.is_only_integrity == 1
    ) {
      this.showNAPopup();
      this.showNAPOPUp();
      this.router.navigateByUrl('/lms/launchpad');
    } else {
      if (this.isMobile) {
        this.modalClass = 'modal-dialog modal-fluid pr-3 w-100 mt-5 pt-4';
      } else {
        this.modalClass = 'modal-dialog modal-fluid w-75 mt-5 pt-4';
      }
      this.modalRef = this.modalService.show(ContactModalComponent, {
        backdrop: true,
        keyboard: false,
        focus: true,
        show: false,
        ignoreBackdropClick: true,
        class: this.modalClass,
        containerClass: 'modal fade overflow-y',
        animated: true,
      });
    }
  }

  goToSubjectForum(id: number) {
    const audId = 'c53f9b7df8fbb22dc12d0e859a5c9734';
    const becId = '53625d62402e139ccc1e441dea96b90c';
    const farId = 'a1a8cddccb57f50303507da5087ed062';
    const regId = '5fdf99246bcbd70b7fdb6b6b600e6d35';
    if (id == 101) {
      // this.router.navigate(['/lms/forum/subject',audId]);
      window.open('/lms/forum/subject/' + audId, '_blank');
    } else if (id == 102) {
      window.open('/lms/forum/subject/' + becId, '_blank');
      // this.router.navigate(['/lms/forum/subject',becId]);
    } else if (id == 103) {
      window.open('/lms/forum/subject/' + farId, '_blank');
      // this.router.navigate(['/lms/forum/subject',farId]);
    } else if (id == 104) {
      window.open('/lms/forum/subject/' + regId, '_blank');
      // this.router.navigate(['/lms/forum/subject',regId]);
    } else {
      window.open('/lms/forum', '_blank');
      // this.router.navigate(['/lms/forum','']);
    }
  }

  getForumNotifications() {
    this.forumServiceService.getForumNotifications();
  }
  goToForum(
    type: number,
    subjectId: any,
    questionId: any,
    mainTopicId: any,
    subTopicId: any,
    videoId: any
  ) {
    if (type === 1) {
      this.router.navigate(['/lms/forum/mcq', subjectId, questionId]);
    } else if (type === 2) {
      this.router.navigate(['/lms/forum/sims', subjectId, questionId]);
    } else if (type === 3) {
      this.router.navigate([
        '/lms/forum/video',
        subjectId,
        mainTopicId,
        subTopicId,
        videoId,
      ]);
    } else if (type === 4) {
      this.router.navigate([
        '/lms/forum/ebook',
        subjectId,
        mainTopicId,
        subTopicId,
        0,
      ]);
    }
  }
  marksNotificaionsread(int_post_id?: any) {
    this.forumServiceService
      .marksNotificaionsread({ int_status: 1, int_post_id })
      .subscribe((res: any) => {
        if (res.status) {
          this.getForumNotifications();
        }
      });
  }

  // ************** Evaluation Modal Query Begins *****************//
  evaluationPaymentModal() {
    if (
      this.is_only_bridgecourse == 1 ||
      this.is_only_aicpa == 1 ||
      this.is_only_integrity == 1
    ) {
      this.router.navigateByUrl('/lms/launchpad');
    } else {
      this.modalRef = this.modalService.show(FacsPaymentModalComponent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: 'modal-lg modal-dialog-scrollable',
        containerClass: 'right',
        animated: true,
      });
    }
  }
  getstudent_facsdetails() {
    this.gamePlanService.student_facsdetails().subscribe(
      (res: any) => {
        if (res.data.length && res.status) {
          this.facs_code = res.data[0]?.facs_code;
          this.showFacs = true;
        } else {
          this.showFacs = false;
        }
      },
      (err) => {
        this.showFacs = false;
      }
    );
  }
  // *************** Evaluation Modal Query Ends ******************//
  // ################# Content Update Modal Begins ################# //

  additionContentUpdate() {
    this.modalRef = this.modalService.show(AdditionalContentUpdateComponent, {
      backdrop: true,
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-scrollable',
      containerClass: 'right',
      animated: true,
    });
  }

  cpaEvolution() {
    this.modalRef = this.modalService.show(CpaEvolutionComponent, {
      backdrop: true,
      keyboard: false,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-scrollable',
      containerClass: 'right',
      animated: true,
    });
  }

  contentUpdate() {
    if (
      this.is_only_bridgecourse == 1 ||
      this.is_only_aicpa == 1 ||
      this.is_only_integrity == 1
    ) {
      this.showNAPopup();
      this.showNAPOPUp();
      this.router.navigateByUrl('/lms/launchpad');
    } else {
      this.modalRef = this.modalService.show(ContentUpdateComponent, {
        backdrop: true,
        keyboard: false,
        focus: true,
        show: true,
        ignoreBackdropClick: true,
        class: 'modal-lg modal-dialog-scrollable',
        containerClass: 'right',
        animated: true,
      });
    }
  }

  checkurl() {
    if (
      this.is_only_bridgecourse == 1 ||
      this.is_only_aicpa == 1 ||
      this.is_only_integrity == 1
    ) {
      this.popupService.naInfo('Attention', 'This feature is not applicable.');
      this.router.navigateByUrl('lms/launchpad');
    } else {
      this.macservice.checkuserexists().subscribe((resp: any) => {
        var cond;
        if (document.URL.includes('cma') == true) {
          cond = resp.data[0]?.cma_info.firstname;
        } else {
          cond = resp.data[0]?.cpa_info?.firstname;
        }
        if (resp.data.length == 0) {
          this.router.navigate(['/lms/mac']);
        } else if (
          resp.data.length > 0 &&
          resp.data[0]?.token_expired == true
        ) {
          this.router.navigate(['/lms/mac']);
        } else if (resp.data.length > 0 && cond == undefined) {
          this.router.navigate(['/lms/mac/registration']);
        } else {
          localStorage.setItem('is_mac_loggedin', '1');
          this.router.navigate(['/lms/mac/dashboard/my-profile']);
        }
      });
    }
  }

  undermaintenance() {
    this.router.navigateByUrl('lms/launchpad');
    Swal.fire({
      icon: "info",
      title: "The M.A.C portal is currently under maintenance. We apologize for the inconvenience."
    })
  }

  showAttentionPopup() {
    if (
      this.is_only_bridgecourse == 1 ||
      this.is_only_aicpa == 1 ||
      this.is_only_integrity == 1
    ) {
      this.popupService.naInfo('Attention', 'This feature is not applicable.');
      this.router.navigateByUrl('lms/launchpad');
    }
  }

  check1o1url() {
    if (this.is_only_bridgecourse == 1) {
      this.router.navigateByUrl('/lms/launchpad');
    } else {
      this.router.navigateByUrl('/lms/certificate-program');

      // this.macservice.getjobprofiledetails().subscribe((res: any) => {
      //   if (res && res.data.length > 0) {
      //     if (res.data[0].step == 1) {
      //       this.router.navigate(['/lms/jobs/onboard/step1']);
      //     } else if (res.data[0].step == 2) {
      //       this.router.navigate(['/lms/jobs/onboard/step2']);
      //     } else if (res.data[0].step == 3) {
      //       this.router.navigate(['/lms/jobs/onboard/step3']);
      //     } else if (res.data[0].step == 4) {
      //       this.router.navigate(['/lms/jobs/onboard/step4']);
      //     } else if (res.data[0].step == 5) {
      //       this.router.navigate(['/lms/jobs/onboard/step5']);
      //     } else if (res.data[0].step == 6) {
      //       this.router.navigate(['/lms/jobs/onboard/step6']);
      //     } else if (res.data[0].step == 7) {
      //       this.router.navigate(['/lms/jobs/onboard/step7']);
      //     } else if (res.data[0].step == 8) {
      //       this.router.navigate(['/lms/jobs/onboard/step8']);
      //     } else if (res.data[0].step == 9) {
      //       this.router.navigate(['/lms/jobs/onboard/step9']);
      //     } else if (res.data[0].step == 10) {
      //       this.router.navigate(['/lms/jobs/onboard/step10']);
      //     } else if (res.data[0].step == 11) {
      //       this.router.navigate(['/lms/certificate-program']);
      //     } else {
      //       this.router.navigate(['/lms/jobs']);
      //     }
      //   } else {
      //     this.router.navigate(['/lms/jobs']);
      //   }
      // });
    }
  }

  // ################## Content Update Modal Ends ################## //
  navigateTo(url) {
    if (!this.is_only_aicpa_inter) {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl('lms/cpa-inter');
    }
  }
  showNAPopup(fromInter?) {
    if (this.is_only_bridgecourse == 1) {
      this.popupService.naInfo('Attention', 'This feature is not applicable.');
      this.router.navigateByUrl('lms/launchpad');
    } else if (this.is_only_aicpa_inter && !fromInter) {
      this.popupService.naInfo('Attention', 'This feature is not available for you.');
      this.router.navigateByUrl('lms/cpa-inter');
    }
  }
  showNAPopUp() {
    if (this.is_only_aicpa == 1 || this.is_only_aicpa_inter) {
      this.popupService.naInfo('Attention', 'This feature is not applicable.');
    }
  }
  showNAPOPUp() {
    if (
      this.is_only_aicpa != 1 ||
      this.is_only_bridgecourse != 1 ||
      this.is_only_integrity == 1
    ) {
      this.router.navigateByUrl('lms/mileage/attendance');
    } else {
      this.popupService.naInfo('Attention', 'This feature is not applicable.');
      this.router.navigateByUrl('lms/launchpad');
    }
  }

  showNAPOPUpCpaInter() {
    if (
      this.is_only_aicpa != 1 ||
      this.is_only_bridgecourse != 1 ||
      this.is_only_integrity == 1
    ) {
      this.router.navigateByUrl('lms/cpa-inter');
    } else {
      this.popupService.naInfo('Attention', 'This feature is not applicable.');
      this.router.navigateByUrl('lms/launchpad');
    }
    if (this.is_only_aicpa_inter) {
      this.router.navigateByUrl('lms/cpa-inter');
    }
  }

  newMenu() {
    if (this.is_alumni == 1) {
      this.popupService.newMenuPopup(
        'Info',
        'This feature will be coming soon.'
      );
    }
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    try {
      this.unsubscribeMeta.unsubscribe();
    } catch (error) { }
  }
}
