import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  Url = `${environment.cmaapiUrl}`;
  part1 = '41d4d05f967c1b913cabe95876b8a2c7';
  part2 = '6c7ccca37633e15249a4ba916d82317e';
  // farSubjectId = 'a1a8cddccb57f50303507da5087ed062';
  // regSubjectId = '5fdf99246bcbd70b7fdb6b6b600e6d35';
  ebook: BehaviorSubject<any> = new BehaviorSubject([]);
  webinarVideoList: Subject<any> = new Subject();
  ebookSubtopicName: BehaviorSubject<any> = new BehaviorSubject([]);
  ebookMetadata: BehaviorSubject<any> = new BehaviorSubject([]);
  Breadcrumbs: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(private http: HttpClient) { }
  getMainTopic(id) {
    return this.http.post(this.Url + 'getSubjectsDetails', { subject_id: id });
  }
  getSubTopic(subject_id, maintopic_id) {
    return this.http.post(this.Url + 'subject/getSubTopic', {
      maintopic_id,
      subject_id,
    });
  }
  gettopicwisereport(subject_id, maintopic_id) {
    return this.http.post(this.Url + 'gettopicwisereport', {
      maintopic_id,
      subject_id,
    });
  }
  gettopicreport(subject_id) {
    return this.http.post(this.Url + 'dashboard', {
      request_from: 'web',
      subject_id,
    });
  }
  changeQuestionsMode(subject_id, questions_mode) {
    return this.http.post(this.Url + 'changeQuestionsMode', {
      subject_id,
      questions_mode,
    });
  }
  getQuestionIds(question_id) {
    return this.http.post(this.Url + 'getQuestionIds', { question_id });
  }
  getQuestionDeatilsIds(question_id, is_attempt, from?) {
    return this.http.post(this.Url + 'getQuestionDetails', {
      question_id,
      is_attempt,
      created_from: '1',
      from,
    });
  }
  simQuestionView(subject_id, int_maintopic_id, question_id, attempt_r_view) {
    return this.http.post(this.Url + 'simQuestionView', {
      subject_id,
      int_maintopic_id,
      question_id,
      attempt_r_view,
    });
  }
  simQuestionView1(
    subject_id,
    question_id,
    created_from,
    exam_pattern,
    number_of_questions,
    question_type,
    section_id,
    selected_topics
  ) {
    console.log(question_id);
    return this.http.post(this.Url + 'mcqSelectedQuestions', {
      subject_id: subject_id,
      question_id: question_id,
      created_from: created_from,
      exam_pattern: exam_pattern,
      number_of_questions: number_of_questions,
      selected_questionstype: question_type,
      section_id: section_id,
      selected_topics: [selected_topics],
    });
  }
  essayquestioncall(
    subject_id,
    question_id,
    created_from,
    exam_pattern,
    number_of_questions,
    question_type,
    section_id,
    selected_topics
  ) {
    // 'essay/createSession'
    return this.http.post(this.Url + 'essay/createSession', {
      subject_id: subject_id,
      question_id: question_id,
      created_from: created_from,
      exam_pattern: exam_pattern,
      number_of_questions: number_of_questions,
      selected_questionstype: question_type,
      section_id: section_id,
      selected_topics: [selected_topics],
    });
  }
  getSubjectList(subject_id, section_id) {
    return this.http.post(this.Url + 'gettopicDetails', {
      subject_id,
      section_id,
    });
  }
  private handleError(error: Response) {
    return throwError(error.statusText);
  }
  activateVideos(subject_id, activation_code) {
    return this.http.post(this.Url + 'ActivateVideos', {
      subject_id,
      activation_code,
    });
  }
  activateMcq(subject_id, activation_code) {
    return this.http.post(this.Url + 'ActivateMcq', {
      subject_id,
      activation_code,
    });
  }
  ebooks(subject_id, subtopic_id, section_id, old_year) {
    // console.log('year', { subject_id, subtopic_id, old_year })
    const params =
      old_year == 1
        ? { subject_id, subtopic_id, section_id, old_year }
        : { subject_id, subtopic_id, section_id };
    this.http.post(this.Url + 'ebooks', params).subscribe((res: any) => {
      // console.log('res', res)
      if (res && res.data.length) {
        this.ebook.next(res.data);
        this.ebookSubtopicName.next(res.data[0].subtopic_name);
        this.ebookMetadata.next(res.metadata[0]);
      } else {
        Swal.fire({
          icon: 'error',
          text: ' Coming Soon!',
          allowOutsideClick: false,
        }).then((res) => {
          window.close();
        });
      }
    });
  }
  getWebinarVideos(subject_id, maintopic_id?) {
    return this.http.post(this.Url + 'getWebinarVideos', {
      subject_id,
      maintopic_id,
    });
  }
  getSubjectDetails(subjectId) {
    switch (subjectId) {
      case '41d4d05f967c1b913cabe95876b8a2c7':
        return {
          subjectName: 'Financial Planning, Performance, And Analytics',
          logo: 'fa-search-dollar',
          shortName: 'PART 1',
        };
        break;
      case '6c7ccca37633e15249a4ba916d82317e':
        return {
          subjectName: 'Strategic Financial Management',
          logo: 'fa-chart-line',
          shortName: 'PART 2',
        };
        break;
      // case 'a1a8cddccb57f50303507da5087ed062':
      //   return {
      //     subjectName: 'Financial Accounting & Reporting',
      //     logo: 'fa-university',
      //     shortName: 'FAR'
      //   }
      //   break;
      // case '5fdf99246bcbd70b7fdb6b6b600e6d35':
      //   return {
      //     subjectName: 'Regulation',
      //     logo: 'fa-gavel',
      //     shortName: 'REG'
      //   }
      //   break;
      default:
        break;
    }
  }
  practiceMocktest(subject_id?) {
    return this.http.post(this.Url + 'create/PracticeMocktest', { subject_id });
  }
  getPreviousScore(subject_id, exam_code) {
    return this.http.post(this.Url + 'getPreviousPracticeTestResults', {
      subject_id,
      exam_code,
    });
  }
  getMiniMocktestScore(payload) {
    return this.http.post(this.Url + 'getMiniMocktestScoreList', payload);
  }

  saveEbookNotes(params) {
    return this.http.post(this.Url + 'saveEbookNotes', { ...params });
  }
  update_aud_status(params) {
    return this.http.post(this.Url + 'update_aud_status', { ...params });
  }
}
