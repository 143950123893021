export const environment = {
  production: true,
  cmaapiUrl: 'https://cma-api.mileseducation.com/', // CMA
  apiUrl: 'https://cpa-api.mileseducation.com/', // CPA
  cdnUrl: 'https://storage.googleapis.com/miles-next_website-lms-gptool/educational_documents',
  mentoringurl: 'https://miles-lms-mentoring-live-dot-me-server-1.el.r.appspot.com/',
  authTokenKey: 'auth8983a0c22a0529c1c646dd0dc2aaaf68',
  fofAuthTokenKey: 'foftoken',
  // firebase: {
  //   apiKey: 'AIzaSyAnnPD8iCOPy0i19ksNx_Bf_cGIicUsFkA',
  //   authDomain: 'mileseducation-angular-project.firebaseapp.com',
  //   databaseURL: 'https://mileseducation-angular-project.firebaseio.com',
  //   projectId: 'mileseducation-angular-project',
  //   storageBucket: 'mileseducation-angular-project.appspot.com',
  //   messagingSenderId: '970786198643',
  //   appId: '1:970786198643:web:d0ae3df34782ea8973c606',
  //   measurementId: 'G-718X5ERNVW',
  // },
  firebase: {
    apiKey: "AIzaSyArkO_mN7VjYrtmtUsp6eMqga-aahNftCo",
    authDomain: "miles-firebase-projects.firebaseapp.com",
    projectId: "miles-firebase-projects",
    storageBucket: "miles-firebase-projects.appspot.com",
    messagingSenderId: "756926493455",
    appId: "1:756926493455:web:cd7ccbd72a3cb1dbe0047a",
    measurementId: "G-0Y63HJBX5T"
  },
  // PUSHER_API_KEY: "2c9afef3ab4bd62fd1cb",
  // PUSHER_API_CLUSTER: "ap2",
  PUSHER_API_KEY: '36b2aaddc3c40ad00f15',
  PUSHER_API_CLUSTER: 'ap2',
  superBaseUrl: 'https://blcwdqfhupddorxpdcjl.supabase.co/functions/v1/get-upcoming-webinar',
};
