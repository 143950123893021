<!-- <main class="grid">
  <div class="container px-0 position-relative">
    <div class="w-100"> -->
<!-- <div *ngIf="showhat">
        <ng-lottie width="100%" height="100%" class="cma_back_animation" containerClass="moving-box" [styles]="styles"
                   [options]="option2">
        </ng-lottie>
      </div> -->

<!-- Nav tabs -->


<mdb-tabset #tabs [buttonClass]="'new-study-tab'" [contentClass]="'card'" class="new-study-tab">
  <mdb-tab heading="<i class='fas fa-home-lg-alt mr-2'></i>Home" (select)="showHatCss(0)">
    <div class="card-body p-0 m-0 home-tab">
      <div class="row">
        <div class="col-xl-6 col-12">
          <div class="position-relative">
            <p><strong>Hi</strong>,</p>
            <h4 class="student_title">{{userData?.txt_name}}</h4>
          </div>
          <p class="py-4">Miles is pleased to present to you this proprietory Study Planner tool which is going to
            be your one-stop space to plan your CMA! You can update the status related to your CMA formalities as
            well as plan your preparation here!
          </p>
          <p class="mb-4">Cheers,<br>
            <strong>Miles Education</strong>
          </p>
          <p><strong>Gameplan Status:</strong></p>
          <form>
            <div class="input-group mb-1 Home-tab">
              <ng-select *ngIf="diableGpStatus" class="mb-3 w-100 mx-auto" name="audstatusList"
                         placeholder="Choose option" [items]="gameplanList" [multiple]="false"
                         bindValue="int_gameplan_id"
                         dropdownPosition='auto' [(ngModel)]="gpStatus" bindLabel="option"
                         placeholder="Select Exam Pattern" [clearable]="false" required>
              </ng-select>
              <input *ngIf="!diableGpStatus" class="mb-3 w-100 mx-auto disabled form-control" name="audstatusList"
                     placeholder="Choose option" [value]="studentCurrentGp">

              <div class="input-group-append Home-tab">
                <button *ngIf="studentGpStatus == 1 && diableGpStatus == false" mdbBtn size="md"
                        class="m-0 px-3 py-2 edit_btn" type="button" id="button-addon2" mdbWavesEffect
                        (click)="editGPStatus()"><i class="far fa-pen-to-square mr-2"></i>Edit</button>
                <button *ngIf="studentGpStatus == 0 && diableGpStatus == true" type="btn"
                        class="btn btn-blue text-white m-0 btn-md" (click)="saveGpStatus()">Submit</button>
              </div>
            </div>
            <p *ngIf="showImaSteps == true" class="cust_id">IMA Customer ID- {{userData?.ima_code}}</p>
            <a *ngIf="showImaSteps == false" class="cust_id" (click)="showInput()"
               style="color: #0044BA; text-decoration: underline;">Enter
              IMA
              Customer ID-
            </a>
            <ng-container *ngIf="showimaInput== false">
              <div class="row position-relative">
                <img
                     src="https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/feedback/profile/close_icon_new.png"
                     class="close_btn" (click)="showInput()">
                <div class="col-md-9 col-12">
                  <input type="text" id="form6" (keypress)="keyPress($event)" maxlength="12" class="form-control"
                         [ngModelOptions]="{standalone: true}" [(ngModel)]="imaCode"
                         [ngClass]="{'border-danger': submitted && (!imaCode ||imaCode.toString().length!=12)}"
                         placeholder="IMA Customer ID/Membership Number">
                  <p class="text-danger" *ngIf="submitted && (!imaCode ||imaCode.toString().length!=12)">
                    IMA code
                    should
                    have 12 digits</p>
                </div>
                <div class="col-md-3 col-12 px-md-0 py-2">
                  <button mdbBtn type="button" class="download_btn btn" style="padding: .6rem 2rem!important"
                          rounded="true" (click)="submitIma()" mdbWavesEffect>Submit</button>
                </div>
                <p class="text-danger px-3 mb-2"><small>Haven’t registered with IMA
                    yet? <a> <strong (click)="toggleShow()">Click here</strong></a></small>
                </p>
                <div class="content_list px-3" *ngIf="isShown" id="divshow">
                  <h3
                      style="font-size: 16px; font-weight: 600; color: #000; border-bottom: 1px solid #ccc; padding-bottom: 10px;">
                    Steps to create Customer ID on IMA Website</h3>
                  <ul class="modl-text">
                    <li class="mb-1">Click on the following link <br><a href="https://bit.ly/3Q1o42X"
                         target="_blank">(https://bit.ly/3Q1o42X)</a>
                    </li>
                    <li class="mb-1">Click on Register Now, fill in the necessary details and
                      register.
                    </li>
                    <li class="mb-1">On the top LHC click on myIMA > myProfile >My Account ; you'll
                      find
                      the IMA Customer ID under My Account.
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>

          </form>
          <div *ngIf="showExamStatus" class="row mt-4">
            <div class="col-12 col-md-12">
              <div class="sub_title">CMA Part-1</div>
              <div class="d-flex flex-md-row flex-column justify-content-start">
                <div class="md-form md-outline pl-0 planner-input mb-0 mt-3 w-100 col">
                  <input type="text" id="subid1" class="form-control input-aud disabled" disabled="true"
                         [value]="studentCurrentExamPart1" name="subname" mdbInput mdbValidate>
                  <label class="cursor-disable text-dark" for="subid1">Exam
                    Status</label>
                  <div class="input-group-append exam-status py-4">
                    <a (click)="getSubjectExamStatus(this.part1SubjectId, userToken);showPart1()"><i
                         class="far fa-pen-to-square"></i></a>
                  </div>
                </div>
                <div class="md-form md-outline pl-0 planner-input mb-0 mt-3 col-md-3">
                  <input type="text" disabled="true" id="date1" class="form-control input-aud disabled"
                         name="date1" mdbInput [value]="studentCurrentExamDatePart1" mdbValidate>
                  <label *ngIf="examDateLabel1== null || examDateLabel1== 1 || examDateLabel1== 4"
                         class="cursor-disable text-dark" for="date1">Planned Month</label>
                  <label *ngIf="examDateLabel1== 2 || examDateLabel1== 5" class="cursor-disable text-dark"
                         for="date1">Scheduled Date</label>
                  <label *ngIf="examDateLabel1== 3 " class="cursor-disable text-dark" for="date1">Result
                    Date</label>
                  <label *ngIf="examDateLabel1== 6 " class="cursor-disable text-dark" for="date1">Appeared
                    Date</label>
                </div>
                <div *ngIf="examDateLabel1== 3" class="md-form md-outline pl-0 planner-input mb-0 mt-3 col-md-2">
                  <input type="text" disabled="true" id="score1" class="form-control input-aud disabled"
                         name="score1" mdbInput [value]="part1Score" mdbValidate>
                  <label class="cursor-disable text-dark" for="score1">Score</label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 pt-3">
              <div class="sub_title">CMA Part-2</div>
              <div class="d-flex flex-md-row flex-column justify-content-start">
                <div class="md-form md-outline pl-0 planner-input mb-0 mt-3 w-100 col">
                  <input type="text" disabled="true" id="subid2" class="form-control input-bec disabled"
                         name="subname" mdbInput [value]="studentCurrentExamPart2" mdbValidate>
                  <label class="cursor-disable text-dark" for="subid2">Exam
                    Status</label>
                  <div class="input-group-append exam-status py-4">
                    <a (click)="getSubjectExamStatus(this.part2SubjectId, userToken);showPart2()"><i
                         class="far fa-pen-to-square"></i></a>
                  </div>
                </div>
                <div class="md-form md-outline pl-0 planner-input mb-0 mt-3 col-md-3">
                  <input type="text" disabled="true" id="date2" class="form-control input-bec disabled"
                         name="date2" mdbInput [value]="studentCurrentExamDatePart2" mdbValidate>
                  <label *ngIf="examDateLabel2== null || examDateLabel2== 1 || examDateLabel2== 4"
                         class="cursor-disable text-dark" for="date2">Planned Month</label>
                  <label *ngIf="examDateLabel2== 2 || examDateLabel2== 5" class="cursor-disable text-dark"
                         for="date2">Scheduled Date</label>
                  <label *ngIf="examDateLabel2== 3" class="cursor-disable text-dark" for="date2">Result
                    Date</label>
                  <label *ngIf="examDateLabel2== 6" class="cursor-disable text-dark" for="date2">Appeared
                    Date</label>
                </div>
                <div *ngIf="examDateLabel2== 3" class="md-form md-outline pl-0 planner-input mb-0 mt-3 col-md-2">
                  <input type="text" disabled="true" id="score2" class="form-control input-bec disabled"
                         name="score2" mdbInput [value]="part2Score" mdbValidate>
                  <label class="cursor-disable text-dark" for="score2">Score</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 position-relative px-0" *ngIf="!showhat">
          <ng-lottie width="100%" height="100%" class="cma_img" containerClass="moving-box" [styles]="styles"
                     [options]="option">
          </ng-lottie>
        </div>
      </div>
    </div>
  </mdb-tab>
  <mdb-tab heading="<i class='fas fa-search-dollar mr-2'></i>Part - 1"
           (select)="getStudyplanner(part1SubjectId, userToken);showHatCss(1);">
    <div class="col-12" *ngIf="studentUpdateExamStatusList.length;then TabHtmlPart else  NoTabHtmlPart"></div>
  </mdb-tab>
  <mdb-tab heading="<i class='fa fa-chart-line-up mr-2'></i>Part - 2"
           (select)="getStudyplanner(part2SubjectId, userToken);showHatCss(1);">
    <div class="col-12" *ngIf="studentUpdateExamStatusList.length;then TabHtmlPart  else  NoTabHtmlPart"></div>
  </mdb-tab>
</mdb-tabset>
<!-- </div>
  </div>
</main> -->

<ng-template #TabHtmlPart>
  <div class="row">
    <div class="col-xl-9 col-12">
      <div class="row">
        <div class="col-lg-3 col-12 px-2">
          <ul class={{plannerClass.sideLine}}>
            <li class="list-group-item border border-0 track-txt">
              <a class={{examStatusTabCss}} (click)="showStatusView(1) "><i class='far fa-calendar-day mr-2'></i>
                <p class="d-lg-block d-none">Exam Schedule</p>
              </a>
            </li>
            <li class="list-group-item border border-0 track-txt">
              <a class={{studyTrackTabCss}} (click)="showStatusView(2)"><i class='far fa-book-reader mr-2'></i>
                <p class="d-lg-block d-none">Study track</p>
              </a>
            </li>
            <li class="list-group-item border border-0 track-txt position-relative">
              <a class={{preparationTabCss}} (click)="showStatusView(3)"><i class='far fa-location mr-2'></i>
                <p class="d-lg-block d-none">Preparation Pace</p><br />
                <span class="d-lg-block d-none">(Determines the time spend per question)</span>
              </a>
            </li>
            <li class="list-group-item border border-0 track-txt d-none">
              <a class={{examStatusTabCss}} (click)="showStatusView(4)"><i class='far fa-road mr-2'></i>
                <p class="d-lg-block d-none">Tracking</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-9 col-12">
          <div class="card card_track">
            <div class="card-body">

              <!-- Exam Schedule -->
              <div *ngIf="showStatus==1">
                <h5 class="d-lg-none d-block mb-4"><i class='far fa-calendar-day mr-2'></i>Exam Schedule</h5>
                <form>
                  <div class="form-row">
                    <div class="form-group col-lg-8 col-12">
                      <label for="inputEmail4">Exam Status</label>
                      <div class="input-group">
                        <input type="text" [disabled]="true" class={{plannerClass.subinput}}
                               [placeholder]="studentCurrentExamPart1" aria-label="IMA Status"
                               aria-describedby="button-addon2">
                        <!-- <input type="text" [disabled]="true" class="form-control input-aud" placeholder="Choose" [(ngModel)]="studentCurrentExamPart2"
                          aria-label="IMA Status" aria-describedby="button-addon2"> -->
                        <div class="input-group-append py-4">
                          <a (click)="showEditDropdown()"><i class="far fa-pen-to-square"></i></a>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-4 col-12">
                      <label for="inputEmail4">Date</label>
                      <div class="input-group">
                        <input type="text" [disabled]="true" class={{plannerClass.subinput}}
                               [placeholder]='studentCurrentExamDatePart1' aria-label="Status"
                               aria-describedby="button-addon2">
                        <!-- <input type="text" [disabled]="true" class="form-control input-aud"
                          [placeholder]='studentCurrentExamDatePart1' aria-label="Status" aria-describedby="button-addon2"> -->
                        <div class="input-group-append py-4">
                          <a (click)="showEditDropdown()"><i class="fal fa-calendar-alt"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <a (click)="showHome()">To change Game plan Status <strong class="cust_id">click here</strong>
                </a>
                <div *ngIf="showEditOptions == 1">
                  <div class="sub_title mt-3"><strong>{{plannerClass.subjectName}}</strong></div>
                  <div class={{plannerClass.studybox}}>
                    <mdb-accordion [multiple]="false">
                      <mdb-accordion-item [collapsed]="false" class="cma_acc">
                        <mdb-accordion-item-head class={{plannerClass.substatus}}>
                          {{studentCurrentGp}}
                        </mdb-accordion-item-head>
                        <mdb-accordion-item-body class="p-0">
                          <div class="d-flex align-content-around align-items-center">
                            <div class="d-flex flex-column mx-auto game-plan-block-size subject-block-aud">
                              <!-- <form name="audForm" #audForm="ngForm" class="mx-auto w-100"> -->
                              <div style="margin: 5px;" class="md-form">
                                <ng-select class="mb-3" [items]="studentUpdateExamStatusList" name="part1Status"
                                           bindLabel="option" [(ngModel)]="studentUpdateExamStatus" appendTo="body"
                                           dropdownPosition="auto" [searchable]="false" bindValue="int_examstatus_id"
                                           [virtualScroll]="true" placeholder="Select Category" [clearable]="false"
                                           (change)="time()" required>
                                </ng-select>
                              </div>
                              <div class="d-flex flex-fill">
                                <div *ngIf="subjectId == part1SubjectId" class="col mx-1 px-0">
                                  <div *ngIf="studentUpdateExamStatus== 1 || studentUpdateExamStatus== 4"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <input type="text" id="plannercalender1" class="bg-white form-control" name="date"
                                           [(ngModel)]="part1Data.date_of_exam" placeholder="Planned Month"
                                           (focus)="monthModal.show();switchId=1" required mdbInput mdbValidate>
                                    <!-- <label for="plannercalender1">{{dateLabel(examDateLabel)}}</label> -->
                                  </div>

                                  <div *ngIf="studentUpdateExamStatus== 2 || studentUpdateExamStatus== 5"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <mdb-date-picker name="mydate" [options]="myDatePickerOptions"
                                                     [(ngModel)]="part1Data.date_of_exam"
                                                     [placeholder]="'Scheduled Date'" required>
                                    </mdb-date-picker>

                                  </div>
                                  <div *ngIf="studentUpdateExamStatus== 6"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <mdb-date-picker name="mydate" [options]="passedDateOption"
                                                     [(ngModel)]="part1Data.date_of_exam"
                                                     [placeholder]="'Appeared Date'" required>
                                    </mdb-date-picker>

                                  </div>
                                  <div *ngIf="studentUpdateExamStatus== 3"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <mdb-date-picker name="mydate" [options]="passedDateOption"
                                                     [(ngModel)]="part1Data.date_of_exam" [placeholder]="'Result Date'"
                                                     required>
                                    </mdb-date-picker>

                                  </div>
                                  <div *ngIf="studentUpdateExamStatus== 3"
                                       class="md-form md-outline mb-2 gamePlan-Subject-input">
                                    <input type="text" class="form-control" placeholder="Score" aria-label="Score"
                                           [(ngModel)]="part1Score" aria-describedby="button-addon2"
                                           (keypress)="scoreValidation($event)" maxlength="3">
                                  </div>
                                </div>
                                <div *ngIf="subjectId == part2SubjectId" class="col mx-1 px-0">
                                  <div *ngIf="studentUpdateExamStatus== 1 || studentUpdateExamStatus== 4"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <input type="text" id="plannercalender1" class="bg-white form-control" name="date"
                                           [(ngModel)]="part2Data.date_of_exam" placeholder="Planned Month"
                                           (focus)="monthModal.show();switchId=2" required mdbInput mdbValidate>
                                    <!-- <label for="plannercalender1">{{dateLabel(examDateLabel)}}</label> -->
                                  </div>

                                  <div *ngIf="studentUpdateExamStatus== 2 || studentUpdateExamStatus== 5"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <mdb-date-picker name="mydate" [options]="myDatePickerOptions"
                                                     [(ngModel)]="part2Data.date_of_exam"
                                                     [placeholder]="'Scheduled Date'" required>
                                    </mdb-date-picker>

                                  </div>
                                  <div *ngIf="studentUpdateExamStatus== 6"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <mdb-date-picker name="mydate" [options]="passedDateOption"
                                                     [(ngModel)]="part2Data.date_of_exam"
                                                     [placeholder]="'Appeared Date'" required>
                                    </mdb-date-picker>

                                  </div>
                                  <div *ngIf="studentUpdateExamStatus== 3"
                                       class="md-form md-outline my-2 gamePlan-Subject-input">
                                    <mdb-date-picker name="mydate" [options]="passedDateOption"
                                                     [(ngModel)]="part2Data.date_of_exam" [placeholder]="'Result Date'"
                                                     required>
                                    </mdb-date-picker>

                                  </div>
                                  <div *ngIf="studentUpdateExamStatus== 3"
                                       class="md-form md-outline mb-2 gamePlan-Subject-input">
                                    <input type="text" class="form-control" placeholder="Score" aria-label="Score"
                                           [(ngModel)]="part2Score" aria-describedby="button-addon2"
                                           (keypress)="scoreValidation($event)" maxlength="3">
                                  </div>
                                </div>
                              </div>
                              <!-- </form> -->
                            </div>
                          </div>
                        </mdb-accordion-item-body>
                      </mdb-accordion-item>
                    </mdb-accordion>
                  </div>
                </div>
                <div class="w-100 d-flex mt-3">
                  <div class="text-left w-50">
                    <button *ngIf="showEditOptions == 1" type="btn" class="btn btn-blue py-2"
                            (click)="saveExamScheduleStatus()">Submit</button>
                  </div>
                  <div class="text-right w-50">
                    <button type="btn" class={{plannerClass.btnnext}} (click)="showStatusView(2)">Next</button>
                  </div>
                </div>
              </div>

              <!-- Study track -->
              <div *ngIf="showStatus==2">
                <h5 class="d-lg-none d-block mb-4"><i class='far fa-book-reader mr-2'></i>Study track</h5>
                <!-- <form> -->
                <div class="form-row">
                  <div class="form-group col-lg-10 col-12">
                    <label for="inputEmail">Study Type</label>
                    <div class={{plannerClass.studytrack}}>
                      <p><i class="fal fa-tv-alt mr-2"></i>Online</p>
                      <!-- New Switch Button Starts -->
                      <label class="content">
                        <input type="checkbox" style="display:none" id="studyType1" name="studyType1"
                               [(ngModel)]="plannerFormData.studyType" (ngModelChange)="changeClassroom($event)" />
                        <div class={{plannerClass.toggle}}>
                          <div class="toggle_btn"></div>
                        </div>
                      </label>
                      <!-- New Switch Button Ends -->

                      <p><i class="fal fa-users-class mr-2"></i>Classroom</p>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="form-group col-lg-10 col-12">
                    <label for="inputEmail4">Study Mode</label>
                    <div class={{plannerClass.studytrack}}>
                      <p><i class="fas fa-walking mr-2"></i>Normal</p>
                      <!-- New Switch Button Starts -->
                      <label class="content">
                        <input type="checkbox" style="display:none" id="studyMode2" name="studyMode2"
                               [(ngModel)]="plannerFormData.studyMode" (ngModelChange)="changeMode($event)" />
                        <div class={{plannerClass.toggle}}>
                          <div class="toggle_btn"></div>
                        </div>
                      </label>
                      <!-- New Switch Button Ends -->
                      <p><i class="fas fa-running mr-2"></i>Cram
                        <a data-toggle="tooltip" data-placement="top" title="Tooltip on top" class="text-primary">
                          <i class="fas fa-info-circle ml-2"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- </form> -->
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitches">
                </div>
                <div class="btn-next mt-3">
                  <button type="btn" class="btn btn-gray mr-3" (click)="showStatusView(1)">Back</button>
                  <button type="btn" class={{plannerClass.btnnext}} (click)="showStatusView(3)">Next</button>
                </div>
              </div>

              <!-- Preparation Pace-->
              <div *ngIf="showStatus==3" class="prep-card">
                <h5 class="d-lg-none d-block mb-4"><i class='far fa-location mr-2'></i>Preparation Pace</h5>
                <div class="d-flex flex-wrap justify-content-center" style="gap: 18px">
                  <div class={{prepcard.prepcard4}}>
                    <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
                    <div class="custom-control custom-radio">
                      <input type="checkbox" class={{paceChecked4}} id="materialUnchecked4"
                             [(ngModel)]="plannerFormData.preparation_pace" (change)="changePace($event)" value="4"
                             name="defaultExampleRadios" mdbInput>
                      <label class={{plannerClass.paceRadio}} for="materialUnchecked4">Slow</label>
                    </div>
                    <div class="btm-txt">
                      <p>MCQ - 4 Min</p>
                      <p>Essay - 60 Min</p>
                    </div>
                  </div>
                  <div class={{prepcard.prepcard3}}>
                    <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
                    <div class="custom-control custom-radio">
                      <input type="checkbox" class={{paceChecked3}} id="materialUnchecked3"
                             [(ngModel)]="plannerFormData.preparation_pace" (change)="changePace($event)" value="3"
                             name="defaultExampleRadios" mdbInput>
                      <label class={{plannerClass.paceRadio}} for="materialUnchecked3">Normal</label>
                    </div>
                    <div class="btm-txt">
                      <p>MCQ - 3 Min
                      </p>
                      <p>Essay - 45 Min</p>
                    </div>
                  </div>
                  <div class={{prepcard.prepcard2}}>
                    <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
                    <div class="custom-control custom-radio">
                      <input type="checkbox" class={{paceChecked2}} id="materialUnchecked2"
                             [(ngModel)]="plannerFormData.preparation_pace" (change)="changePace($event)" value="2"
                             name="defaultExampleRadios" mdbInput>
                      <label class={{plannerClass.paceRadio}} for="materialUnchecked2">Fast</label>
                    </div>
                    <div class="btm-txt">
                      <p>MCQ - 2 min</p>
                      <p>Essay - 30 Min</p>
                    </div>
                  </div>
                  <div class={{prepcard.prepcard1}}>
                    <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
                    <div class="custom-control custom-radio">
                      <input type="checkbox" class={{paceChecked1}} id="materialUnchecked1"
                             [(ngModel)]="plannerFormData.preparation_pace" (change)="changePace($event)" value="1"
                             name="defaultExampleRadios" mdbInput>
                      <label class={{plannerClass.paceRadio}} for="materialUnchecked1">Exam
                        Pace</label>
                    </div>
                    <div class="btm-txt">
                      <p>MCQ - 1.5 Min</p>
                      <p>Essay - 17.5 Min</p>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <h6><strong>PART-1 - PENDING PREPARATION CHART</strong></h6>
                    <small class="track-txt">(Move the mouse pointer on each topic's bar to see the remaining
                      hours of study)</small>
                    <ng-container *ngIf="plannerFormData.preparation_pace == 1">
                      <div class="my-3">
                        <canvas baseChart #chart1 [data]="barChartData" [type]="'bar'" [options]="barChartOptions"
                                height="250">
                        </canvas>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="plannerFormData.preparation_pace == 2">
                      <div class="my-3">
                        <canvas baseChart #chart1 [data]="barChartData" [type]="'bar'" [options]="barChartOptions"
                                height="250">
                        </canvas>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="plannerFormData.preparation_pace == 3">
                      <div class="my-3">
                        <canvas baseChart #chart1 [data]="barChartData" [type]="'bar'" [options]="barChartOptions"
                                height="250">
                        </canvas>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="plannerFormData.preparation_pace == 4">
                      <div class="my-3">
                        <canvas baseChart #chart1 [data]="barChartData" [type]="'bar'" [options]="barChartOptions"
                                height="250">
                        </canvas>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="btn-next">
                  <button type="btn" class="btn btn-gray mr-3" (click)="showStatusView(2)">Back</button>
                  <!-- <button type="btn" class={{plannerClass.btnnext}} (click)="showStatusView(3)">Next</button> -->
                </div>
              </div>


              <!-- Tracking -->
              <!-- <div *ngIf="showStatus==4" class="d-none">
                <p class="d-lg-none d-block">Tracking</p>
                <div class="text-center">
                  <circle-progress [radius]="60" [outerStrokeWidth]="6" [innerStrokeWidth]="3"
                    innerStrokeColor="rgba(185, 204, 130, 1)" [animation]="true" subtitle="Completed"
                    subtitleColor="#000" [animationDuration]="300" titleColor="#000" unitsColor="#000">
                  </circle-progress><br />
                  <small>you need to complete atleast 75% to site for the examination</small>
                </div>
                <div class="row my-5">
                  <div class="col-lg-4 col-12 px-1">
                    <div class="d-flex align-items-center justify-content-center">
                      <circle-progress [radius]="35" [outerStrokeWidth]="6">
                      </circle-progress>
                      <div>
                        <h6><strong>85%</strong></h6>
                        <p class="track-txt"><small>Target<br />
                            355 Days</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12 px-1 border-box">
                    <div class="d-flex align-items-center justify-content-center">
                      <circle-progress [radius]="35" [outerStrokeWidth]="6">
                      </circle-progress>
                      <div>
                        <h6><strong>35%</strong></h6>
                        <p class="track-txt"><small>Actual<br />
                            158 days left</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-12 px-3">
                    <h3 class="mt-lg-3 ml-lg-4 text-lg-left text-center"><strong>321</strong></h3>
                    <div class="d-flex align-items-center justify-content-center">
                      <i class="far fa-clock mr-2" style="color: #bacc82;"></i>
                      <p class="track-txt"><small>hours* per day is the required run rate</small></p>
                    </div>
                  </div>
                </div>
                <div class="btm-note">
                  <p>(Move the mouse pointer on each topic's bar to see the remaining hours of study)</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-12 pt-xl-0 pt-5">
      <div class="text-center">
        <circle-progress [radius]="60" [outerStrokeWidth]="6" [innerStrokeWidth]="3" [percent]="progressBar"
                         innerStrokeColor={{plannerClass.progressBar}} [animation]="true" subtitle="Completed"
                         subtitleColor="#000"
                         [animationDuration]="300" titleColor="#000" unitsColor="#000">
        </circle-progress>
      </div>
      <div class="card-details">
        <div class={{plannerClass.scorecard}}>Your Selected Plan</div>
        <p class={{plannerClass.scoremode}}>Pace -{{plannerDetails.paceMode}}</p>
        <table class="mx-4 tble-details">
          <tr class="track-txt">
            <td>Study Type-</td>
            <td *ngIf="plannerFormData.studyType == false" class="pl-2">Online</td>
            <td *ngIf="plannerFormData.studyType == true" class="pl-2">Classroom</td>
          </tr>
          <tr class="track-txt">
            <td>Study Mode-</td>
            <td *ngIf="plannerFormData.studyMode == false" class="pl-2">Normal</td>
            <td *ngIf="plannerFormData.studyMode == true" class="pl-2">Cram</td>
          </tr>
          <tr *ngIf="examDateLabel!= 3" class="track-txt">
            <td>Pending Preparation- </td>
            <td class="pl-2">{{plannerDetails.time}} hours left</td>
          </tr>
          <tr class="track-txt">
            <td *ngIf="examDateLabel== 2 || examDateLabel== 5">Scheduled Date-</td>
            <td *ngIf="examDateLabel== null || examDateLabel== 1 || examDateLabel== 4">Planned Month-</td>
            <td *ngIf="examDateLabel== 3">Result Date-</td>

            <td class="pl-2">{{plannerDetails.dateOfExam}}</td>
          </tr>
          <tr *ngIf="examDateLabel== 3" class="track-txt">
            <td>Score-</td>
            <td class="pl-2">{{plannerDetails.score}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #NoTabHtmlPart>
  <div class="row mx-auto">
    <div class="col-12 text-center">
      <img src="./assets/img/no-webinar-found.png" class="img-fluid w-50" alt="No Webinar found">
      <p class="text-center">No study scheduler available</p>
    </div>
  </div>
</ng-template>


<!-- Preparation Pace modal -->
<!-- <div mdbModal #PrepModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body p-4">
        <div class="d-flex flex-wrap" style="gap: 19px">
          <div class={{plannerClass.prepcard}}>
            <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
            <p class="my-2">Slow</p>
            <div class="btm-txt">
              <p>MCQ - 2 min</p>
              <p>SIM - 50 min</p>
            </div>
          </div>
          <div class="pace-card-gray">
            <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
            <p class="my-2">Slow</p>
            <div class="btm-txt">
              <p>MCQ - 2 min</p>
              <p>SIM - 50 min</p>
            </div>
          </div>
          <div class="pace-card-gray">
            <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
            <p class="my-2">Slow</p>
            <div class="btm-txt">
              <p>MCQ - 2 min</p>
              <p>SIM - 50 min</p>
            </div>
          </div>
          <div class="pace-card-gray">
            <div class="icon-box mt-2"><i class="fal fa-tachometer-alt-slow"></i></div>
            <p class="my-2">Slow</p>
            <div class="btm-txt">
              <p>MCQ - 2 min</p>
              <p>SIM - 50 min</p>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-8 col-12">
            <h6><strong>PART-1 - PENDING PREPARATION CHART</strong></h6>
            <small class="track-txt">(Move the mouse pointer on each topic's bar to see the remaining
              hours of study)</small>


          </div>
          <div class="col-lg-4 col-12 my-auto text-center">
            <circle-progress [radius]="60" [outerStrokeWidth]="7" [innerStrokeWidth]="3"
              innerStrokeColor="rgba(185, 204, 130, 1)" [animation]="true" subtitle="Completed" subtitleColor="#000"
              [animationDuration]="300" titleColor="#000" unitsColor="#000">
            </circle-progress><br />
            <small class="track-txt">Preparation to go (Videos, MCQs & Simulations)</small>
          </div>
        </div>
        <div class="btn-next mt-3">
          <button type="btn" class="btn btn-gray mr-3" (click)="PrepModal.hide();showStatusView(2)">Back</button>
          <button type="btn" class={{plannerClass.btnnext}} (click)="PrepModal.hide();showStatusView(3)">Next</button>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div mdbModal #monthModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mymonthModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header p-2" style="background: #f7f7f7;">
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
          <span aria-hidden="true">×</span>
        </button> -->
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
                style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;"
                [ngClass]="year>currentYear?'':'disabled'" (click)="preYear()"><i
             class="fas fa-angle-left"></i></button>
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
                style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;">{{year}}</button>
        <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
                style="background: #efefef !important; box-shadow: none; padding: 10px 15px; border: 1px solid #f5f2f2;"
                (click)="nextYear()"><i class="fas fa-angle-right"></i></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-3" *ngFor="let row of monthService.month;let i=index" style="padding-left: 5px;">
            <button mdbBtn type="button" color="light" size="sm" mdbWavesEffect
                    [ngClass]="year===currentYear && currentMonth>i?'disabled':''"
                    (click)="year===currentYear && currentMonth>i?'':setDate(row);monthModal.hide();"
                    style="background: #efefef !important; box-shadow: none; padding: 10px 5px; border: 1px solid #f5f2f2; width: 50px;">{{row.month}}</button>
            <!-- <button *ngIf="studentUpdateExamStatus == 3" mdbBtn type="button" color="light" size="sm" mdbWavesEffect
              (click)="setDate(row);monthModal.hide();"
              style="background: #efefef !important; box-shadow: none; padding: 10px 5px; border: 1px solid #f5f2f2; width: 50px;">{{row.month}}</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>