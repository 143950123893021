import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'miles-lms';
  constructor(private mdbSpinningPreloader: MDBSpinningPreloader, private swUpdate: SwUpdate) {
    this.mdbSpinningPreloader.stop();
  }
  ngOnInit() {
    // console.log(this.swUpdate.isEnabled)
    if (this.swUpdate.isEnabled) {
      // this.swUpdate.available.subscribe(() => {
      //   if (confirm("New version is now available. Update to the Latest Version")) {
      //     window.location.reload();
      //   }
      // });
      this.swUpdate.checkForUpdate()
    }

  }


}
