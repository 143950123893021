<header>
  <div class="d-flex flex-row">
    <!-- ###### Menu for website begin ####### -->
    <div class="sidebar py-3 px-2 d-none d-sm-none d-md-block d-lg-block position-fixed" [ngClass]="sidebarclick">
      <div>
        <div style="text-align: center; margin: -15px -8px; background: #fff">
          <img
               src="https://storage.googleapis.com/miles-next_website-lms-gptool/educational_documents/Miles%20Website%20Images/home/MilesEducation_Main_Logo.png"
               style="animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards"
               alt="Miles CPA LMS"
               class="miles-brand-logo mt-0 mx-2"
               *ngIf="logohide" />
          <img
               src="https://storage.googleapis.com/miles-next_website-lms-gptool/educational_documents/Miles%20Website%20Images/home/Miles_Logo.png"
               alt="Miles CPA LMS"
               style="
							width: 70px;
							animation: fadeInAnimation ease 3s;
							animation-iteration-count: 1;
							animation-fill-mode: forwards;
						"
               class="miles_logo animated zoomIn img-fluid"
               *ngIf="!!!logohide" />
          <!-- <img src="../../../assets/img/Miles Logo.png" alt="Miles CPA LMS" class="miles-brand-logo mt-0 mx-2"> -->
          <!-- <img [src]="isRotate?'../../../assets/images/MilesEducation.jpg':'../../../assets/images/MilesEducation_Main_Logo.png'" [ngClass]="isRotate?'p-1':''"> -->
        </div>
        <div class="text-right d-block d-sm-none position-absolute" style="color: #000; top: 5px; right: 10px">
          <i class="fad fa-times" (click)="menuclick()"></i>
        </div>
        <div class="mt-2 overflow-auto" style="max-height: 88vh; overflow-x: hidden !important">
          <ul *ngIf="userData?.is_only_iimi != 1" class="p-0" style="list-style: none; margin-top: 0px">
            <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      (click)="closePopover(0); showNAPopup(); navigateTo('/lms/launchpad')"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-rocket" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Launchpad</span>
                <!-- <span class="d-none" routerLinkActive="d-inline-block">
  <span class="fad fa-arrow-right ml-2" [ngClass]="menuhide"></span>
  </span> -->
              </button>
            </li>

            <li class="my-3 mx-1 aud-li" *ngIf="is_alumni != 1 && subjectDetails[0]?.is_mcqs_purchased === 2">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white aud-grd"
                      [ngClass]="textAlign"
                      block="true"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/subject/subjectname/topic' + '/' + subjectDetails[0]?.subject_id_encrypt
								"
                      (click)="showNAPopUp(); showAttentionPopup()">
                <!-- [routerLink]="['/lms/subject/subjectname/topic',subjectDetails[0]?.subject_id_encrypt]" -->
                <span class="fad fa-search-dollar" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Auditing</span>
              </button>
            </li>
            <li class="my-3 mx-1 bec-li" *ngIf="is_alumni != 1 && subjectDetails[1]?.is_mcqs_purchased === 2">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white bec-grd"
                      [ngClass]="textAlign"
                      block="true"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/subject/subjectname/topic/' + subjectDetails[1]?.subject_id_encrypt
								"
                      (click)="showNAPopUp(); showAttentionPopup()">
                <span class="fad fa-chart-line" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Business</span>
              </button>
            </li>
            <li class="my-3 mx-1 far-li" *ngIf="is_alumni != 1 && subjectDetails[2]?.is_mcqs_purchased === 2">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white far-grd"
                      [ngClass]="textAlign"
                      block="true"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/subject/subjectname/topic/' + subjectDetails[2]?.subject_id_encrypt
								"
                      (click)="showNAPopUp(); showAttentionPopup()">
                <span class="fad fa-university" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Financial</span>
              </button>
            </li>
            <li class="my-3 mx-1 reg-li" *ngIf="is_alumni != 1 && subjectDetails[3]?.is_mcqs_purchased === 2">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white reg-grd"
                      [ngClass]="textAlign"
                      block="true"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/subject/subjectname/topic/' + subjectDetails[3]?.subject_id_encrypt
								"
                      (click)="showNAPopUp(); showAttentionPopup()">
                <span class="fad fa-gavel" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Regulation</span>
              </button>
            </li>

            <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== ''" class="my-3 mx-1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="['/lms/university-programme']"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-university" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">IIMI-PGCIAA</span>
                <sup style="position: relative; right: -40px"><i
                     style="position: absolute; top: -1px; right: 20px; font-size: 9px; animation: menuText 1s infinite">NEW</i></sup>
              </button>
            </li>

            <li class="my-3 mx-1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/mileage/appointments/new'
								"
                      (click)="showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-users" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Mentoring</span>
              </button>
            </li>

            <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/forum'
								"
                      style="background-color: darkcyan"
                      (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-receipt" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Forum</span>
              </button>
            </li>
            <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/mileage/webinar/subject'
								"
                      (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-laptop" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Webinar</span>
              </button>
            </li>
            <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_aicpa_inter
										? 'lms/cpa-inter'
										: is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
										? '/lms/launchpad'
										: '/lms/classroom'
								"
                      (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-users-class" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Face to Face</span>
              </button>
            </li>
            <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      routerLink="/study-lounge"
                      style="background-color: darkcyan"
                      (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fa-solid fa-school" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Study Lounge</span>
                <!-- <sup style="position: relative; right: -40px"
                  ><i
                    style="position: absolute; top: -1px; right: 20px; font-size: 9px; animation: menuText 1s infinite"
                    >NEW</i
                  ></sup
                > -->
              </button>
            </li>
            <li class="my-3 mx-1" style="position: relative" *ngIf="is_alumni != 1">
              <!--*ngIf="userData?.show_gameplan_menu != 0"-->
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/mileage/gameplan'
								"
                      style="background-color: darkcyan"
                      (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-puzzle-piece" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Gameplan Tool
                  <!-- <sup><i style="position: absolute; top: -1px; right: -9px; font-size: 9px;">BETA</i></sup> -->
                </span>
              </button>
            </li>
            <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/mileage/study_planner'
								"
                      style="background-color: #005e8b"
                      (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-calendar-edit" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Study Planner</span>
                <!-- <span class="d-none" routerLinkActive="d-inline-block">
  <span class="fad fa-arrow-right ml-2" [ngClass]="menuhide"></span>
  </span> -->
              </button>
            </li>
            <!-- <li class="my-3 mx-1">
              <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate" routerLink="/lms/tutorial"
                class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                [ngClass]="textAlign" block="true" placement="bottom">
                <span class="fad fa-chalkboard-teacher" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Tutorials</span>
              </button>
            </li> -->
            <!-- <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate" [routerLink]="'/lms/tutorials'"
                (click)="closePopover(0);showNAPopup()"
                class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                [ngClass]="textAlign" block="true" placement="bottom">
                <span class="fad fa-laptop" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Tutorials</span>
              </button>
            </li> -->
            <!-- <li class="my-3 mx-1">
              <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate"
                (click)="showNAPopup();!is_only_aicpa_inter&&check1o1url()" style="background-color: darkcyan;"
                class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                [ngClass]="textAlign" block="true" placement="bottom">
                <span class="fad fa-file-certificate theme-ravenclaw" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Certifications</span>
              </button>
            </li> -->

            <li class="my-3 mx-1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      (click)="showNAPopup(true); showNAPOPUpCpaInter()"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-file-certificate theme-ravenclaw" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">CPA-Inter</span>
                <!-- <sup style="position: relative; right: -40px"
                  ><i
                    style="position: absolute; top: -1px; right: 20px; font-size: 9px; animation: menuText 1s infinite"
                    >NEW</i
                  ></sup
                > -->
              </button>
            </li>

            <li class="my-3 mx-1" *ngIf="is_cpa101_access == 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_aicpa_inter
										? 'lms/cpa-inter'
										: is_cpa101_access == 1
										? '/lms/jobs/platform/cpa-101'
										: '/lms/launchpad'
								"
                      (click)="showNAPopup()"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <!-- <span class="fad fa-briefcase theme-ravenclaw" [ngClass]="falarge"></span> -->
                <i class="fa-solid fa-file-certificate"></i>
                <span class="menu-name ml-2" [ngClass]="menuhide">CPA 101</span>
                <!-- <sup
                  style="position: relative;right: -40px;"><i
                    style="position: absolute; top: -1px; right: 20px; font-size: 9px;animation: menuText 1s infinite;">NEW</i></sup> -->
              </button>
            </li>

            <li class="my-3 mx-1">
              <!-- mdbPopover="MAC is getting upgraded, try again after 20:00(IST)" -->
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_aicpa_inter
										? 'lms/cpa-inter'
										: is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
										? '/lms/launchpad'
										: '/lms/fab'
								"
                      (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-calculator theme-ravenclaw" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Accounting.101</span>
                <!-- <sup
                  style="position: relative;right: -40px;"><i
                    style="position: absolute; top: -1px; right: 20px; font-size: 9px;animation: menuText 1s infinite;">NEW</i></sup> -->
              </button>
            </li>
            <!-- <li class="my-3 mx-1">
              <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate"
                routerLink="/milescpe/dashboard" style="background-color: darkcyan;"
                class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                [ngClass]="textAlign" block="true" placement="bottom">
                <span class="fad fa-hands-usd theme-ravenclaw" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">FOF</span>
              </button>
            </li> -->
            <!-- <li class="my-3 mx-1">
              <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate" routerLink="/lms/liqui-loan"
                style="background-color: darkcyan;"
                class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                [ngClass]="textAlign" block="true" placement="bottom">
                <span class="fad fa-hand-holding-usd theme-ravenclaw" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Liqui-Loan</span><sup
                  style="position: relative;right: -40px;"><i
                    style="position: absolute; top: -1px; right: 20px; font-size: 9px;animation: menuText 1s infinite;">NEW</i></sup>
              </button>
            </li> -->

            <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/practice-exam'
								"
                      style="background-color: darkcyan"
                      (click)="closePopover(0); showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-money-check-edit" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Practice Exam</span>
                <!-- <span class="d-none" routerLinkActive="d-inline-block">
  <span class="fad fa-arrow-right ml-2" [ngClass]="menuhide"></span>
  </span> -->
              </button>
            </li>
            <li class="my-3 mx-1 d-none" *ngIf="is_alumni != 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      routerLink="/lms/referral"
                      [routerLink]="
									is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/referral'
								"
                      (click)="showNAPopup(); showNAPopUp(); check1o1url(); closePopover(0); showAttentionPopup()"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-user-friends" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Referral</span>
                <!-- <span class="d-none" routerLinkActive="d-inline-block">
  <span class="fad fa-arrow-right ml-2" [ngClass]="menuhide"></span>
  </span> -->
              </button>
            </li>
            <li class="my-3 mx-1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_aicpa == 1 || is_only_integrity == 1 || is_only_aicpa_inter == 1
										? '/lms/launchpad'
										: '/lms/bridge-course'
								"
                      (click)="showNAPopUp(); closePopover(0)"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-graduation-cap" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Bridge Course</span>
                <!-- <span class="d-none" routerLinkActive="d-inline-block">
  <span class="fad fa-arrow-right ml-2" [ngClass]="menuhide"></span>
  </span> -->
              </button>
            </li>

            <li class="my-3 mx-1">
              <!-- mdbPopover="MAC is getting upgraded, try again after 20:00(IST)"
                [routerLink]="(is_only_bridgecourse  == 1 || is_only_aicpa  == 1) ? '/lms/launchpad' : '/lms/mac'"
                (click)="closePopover(0);showNAPopup();closePopover(0);showNAPopUp();!is_only_aicpa_inter&&checkurl()"
               -->
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      style="background-color: darkcyan"
                      (click)="
									closePopover(0); showNAPopup(); closePopover(0); showNAPopUp(); !is_only_aicpa_inter && checkurl()
								"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-address-card theme-ravenclaw" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">M.A.C</span><sup
                     style="position: relative; right: -45px"><i
                     style="position: absolute; top: -1px; right: -9px; font-size: 9px">BETA</i></sup>
              </button>
            </li>

            <!-- <li class="my-3 mx-1" *ngIf="is_alumni != 1">
              <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate"
                [routerLink]="(is_only_bridgecourse == 1 || is_only_aicpa  == 1 ||  is_only_integrity == 1 || is_only_aicpa_inter == 1) ? '/lms/launchpad' : '/lms/cpa-assessment'"
                style="background-color: darkcyan;"
                (click)="closePopover(0);showNAPopup();showNAPopUp();showAttentionPopup()"
                class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                [ngClass]="textAlign" block="true" placement="bottom">
                <span class="fad fa-money-check-edit" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Assessment</span>
              </button>
            </li> -->

            <!-- <li class="my-3 mx-1" *ngIf="userData?.is_noncommerce_track">
  <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate" routerLink="/lms/jain-mcom"
  style="background-color: darkcyan;"
  class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color d-flex"
  [ngClass]="textAlign" block="true" placement="bottom">
  <span class="fad fa-user-graduate theme-ravenclaw my-auto" [ngClass]="falarge"></span>
  <span class="menu-name ml-2 my-auto" [ngClass]="menuhide">Non-Commerce Track</span><sup class="d-none" style="position: relative;right: -60px;"><i style="position: absolute; top: -1px; right: -9px; font-size: 9px;">BETA</i></sup>
  </button>
  </li> -->

            <li class="my-3 mx-1" *ngIf="is_alumni == 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      (click)="newMenu(); closePopover(0)"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fas fa-user-md" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Placements</span>
              </button>
            </li>

            <li class="my-3 mx-1" *ngIf="is_alumni == 1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      (click)="newMenu(); closePopover(0)"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fas fa-id-badge" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">License</span>
              </button>
            </li>

            <!-- <li class="my-3 mx-1"  *ngIf="(is_alumni != 1 && userData?.is_bridge_course != 0)">
              <button mdbBtn mdbWavesEffect type="button" routerLinkActive="activate" routerLink="/lms/CPA-1o1"
                style="background-color: darkcyan;" (click)="closePopover(0);"
                class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                [ngClass]="textAlign" block="true" placement="bottom">
                <span class="fad fa-briefcase" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">CPA 1o1</span> -->
            <!-- <span class="d-none" routerLinkActive="d-inline-block">
                  <span class="fad fa-arrow-right ml-2" [ngClass]="menuhide"></span>
                </span> -->
            <!-- </button>
            </li> -->
            <!-- <li class="mb-auto">
  <app-footer></app-footer>
  </li> -->
          </ul>
          <ul *ngIf="userData?.is_only_iimi == 1" class="p-0" style="list-style: none; margin-top: 0px">
            <li *ngIf="userData?.iimi_university_id !== null && userData?.iimi_university_id !== ''" class="my-3 mx-1">
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="['/lms/university-programme']"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white web-grd other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-university" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">IIMI-PGCIAA</span>
                <sup style="position: relative; right: -40px"><i
                     style="position: absolute; top: -1px; right: 20px; font-size: 9px; animation: menuText 1s infinite">NEW</i></sup>
              </button>
            </li>
            <li class="my-3 mx-1">
              <!-- mdbPopover="MAC is getting upgraded, try again after 20:00(IST)" -->
              <button
                      mdbBtn
                      mdbWavesEffect
                      type="button"
                      routerLinkActive="activate"
                      [routerLink]="
									is_only_aicpa_inter
										? 'lms/cpa-inter'
										: is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
										? '/lms/launchpad'
										: '/lms/fab'
								"
                      (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()"
                      style="background-color: darkcyan"
                      class="transform-none text-left py-2 pl-3 pr-3 mx-auto text-white other-menu-color"
                      [ngClass]="textAlign"
                      block="true"
                      placement="bottom">
                <span class="fad fa-calculator theme-ravenclaw" [ngClass]="falarge"></span>
                <span class="menu-name ml-2" [ngClass]="menuhide">Accounting.101</span>
                <!-- <sup
                  style="position: relative;right: -40px;"><i
                    style="position: absolute; top: -1px; right: 20px; font-size: 9px;animation: menuText 1s infinite;">NEW</i></sup> -->
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- ###### Menu for website ends ####### -->
    <div class="w-100" [ngClass]="bodyblock">
      <mdb-navbar
                  SideClass="navbar navbar-expand-lg navbar-light white ie-nav custom-navbar position-fixed w-100"
                  [containerInside]="false">
        <mdb-navbar-brand class="d-flex justify-content-between w-100">
          <a
             class="d-none d-sm-none d-md-block d-lg-block nav-link miles-blue-text"
             [ngClass]="isMobile ? 'p-0' : 'pl-0'"
             (click)="menuclick()"><span class="fad fa-bars fa-2x"></span></a>
          <a class="d-block d-sm-block d-md-none d-lg-none nav-link miles-blue-text pl-0" (click)="menuclick()">
            <img
                 src="https://storage.googleapis.com/miles-next_website-lms-gptool/educational_documents/Miles%20Website%20Images/home/MilesEducation_Main_Logo.png"
                 alt="Miles CPA LMS"
                 class="miles-brand-logo my-auto"
                 [ngStyle]="{ width: isMobile ? '100px' : '300px' }" />
          </a>
          <links class="d-flex justify-content-between align-items-center">
            <div class="d-none d-sm-none d-md-none d-lg-inline-block">
              <ul class="navbar-nav mr-auto flex-column">
                <!-- <li class="nav-item waves-light" mdbWavesEffect *ngIf="is_alumni != 1">
  <a routerLink="/lms/launchpad"
  class="nav-link d-inline-block bclink miles-blue-text font-weight-bold">Launchpad</a>
  <i class="fad fa-chevron-right miles-blue-text"></i>
  </li> -->
                <!-- <li class="nav-item waves-light" mdbWavesEffect>
  <a class="nav-link bcactive miles-lblue-text">{{Breadcrumbs}}</a>
  </li> -->
                <!-- *ngIf="(is_only_bridgecourse || is_only_aicpa) == 1" -->
                <li class="nav-item waves-light" mdbWavesEffect *ngIf="is_only_bridgecourse == 1">
                  <a class="nav-link bcactive miles-lblue-text university_heading">M.Com (Professional Accounting &
                    Finance)</a>
                </li>
                <li class="nav-item waves-light" mdbWavesEffect *ngIf="is_only_aicpa == 1">
                  <a class="nav-link bcactive miles-lblue-text university_heading">AICPA (Audit and Tax Staff
                    Essentials)</a>
                </li>
                <li class="nav-item waves-light" mdbWavesEffect *ngIf="is_only_integrity == 1">
                  <a class="nav-link bcactive miles-lblue-text university_heading">Corporate Training Program</a>
                </li>
                <li class="nav-item waves-light" mdbWavesEffect>
                  <a class="nav-link bcactive miles-lblue-text university_heading">Attention!! Watch out for LMS 2024 -
                    to be launched on October 3!! <br />"(2023 contents and data will continue to be available)"</a>
                </li>
              </ul>
            </div>
            <ul
                class="navbar-nav nav-flex-icons ie-double-nav ie-hidden-double-nav"
                [ngClass]="fnav"
                style="right: 265px; position: absolute">
              <li
                  class="nav-item btn-group admin_forum_count initial"
                  *ngIf="
									(userData?.is_forum_admin && is_only_bridgecourse != 1) ||
									(userData?.is_forum_admin && is_only_aicpa != 1)
								">
                <span class="nav-link waves-light" *ngFor="let forumSub of forumLaunchpadData; let i = index">
                  <mdb-badge
                             class="rounded text-white font-weight-bold my-auto"
                             style="cursor: pointer"
                             mdbTooltip="Active {{ forumSub.subject_short_name }} Counts"
                             placement="right"
                             (click)="goToSubjectForum(forumSub.int_subject_id)"
                             [ngClass]="
											forumSub.int_subject_id == 101
												? 'aud-grd'
												: forumSub.int_subject_id == 102
												? 'bec-grd'
												: forumSub.int_subject_id == 103
												? 'far-grd'
												: forumSub.int_subject_id == 104
												? 'reg-grd'
												: ''
										">
                    {{ forumSub.activetickets_count }}</mdb-badge>
                </span>
              </li>

              <!-- <li class="nav-item  position-relative">
                <a *ngIf="userData?.is_aicpa_access != 1" class="nav-link waves-light" mdbWavesEffect
                  mdbTooltip="AICPA Payment" placement="left"
                  [routerLink]="(is_only_bridgecourse == 1 || is_only_aicpa == 1 ||  is_only_integrity == 1)  ? '/lms/launchpad' : '/lms/aicpa-payment'"
                  (click)="showNAPopup();showNAPopup();showAttentionPopup()">
                  <img src="../../../assets/img/AICPA.png" alt="AICPA" style="width: 20px; margin-top: -5px;">
                  <span class="blinking new">New</span>
                </a>
                <a *ngIf="userData?.is_aicpa_access == 1" class="nav-link waves-light" mdbWavesEffect
                  mdbTooltip="AICPA Payment" placement="left"
                  [routerLink]="(is_only_bridgecourse ==1  || is_only_aicpa ==1 ||  is_only_integrity == 1) ? '/lms/launchpad' : '/lms/certificate-program'">
                  <img src="../../../assets/img/AICPA.png" alt="AICPA" style="width: 20px; margin-top: -5px;">
                  <span class="blinking new">New</span>
                </a>
              </li> -->
              <li class="nav-item position-relative">
                <a
                   class="nav-link waves-light"
                   mdbWavesEffect
                   mdbTooltip="CPA Evolution"
                   placement="left"
                   (click)="cpaEvolution(); showAttentionPopup()">
                  <img
                       src="https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/CPA-Evolution.jpg"
                       alt="AICPA"
                       style="width: 20px" />
                  <!-- <span class="blinking new">New</span> -->
                </a>
              </li>
              <li class="nav-item">
                <ng-container>
                  <a
                     class="nav-link waves-light"
                     style="position: relative"
                     mdbWavesEffect
                     mdbTooltip="Liqui-Loan"
                     placement="left"
                     [routerLink]="
											is_only_bridgecourse == 1 || is_only_aicpa == 1 || is_only_integrity == 1
												? '/lms/launchpad'
												: '/lms/liqui-loan'
										">
                    <i class="fad fa-hand-holding-usd"></i>
                    <!-- <span class="blinking new" style="top: -5px; left: 6px;">New</span> -->
                  </a>
                </ng-container>
              </li>
              <li class="nav-item">
                <ng-container>
                  <a
                     class="nav-link waves-light"
                     style="position: relative"
                     mdbWavesEffect
                     mdbTooltip="Order Book"
                     placement="left"
                     [routerLink]="
											is_only_bridgecourse == 1 ||
											is_only_aicpa == 1 ||
											is_only_integrity == 1 ||
											is_only_aicpa_inter == 1
												? '/lms/launchpad'
												: '/lms/book-delivery'
										"
                     (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()">
                    <i class="fad fa-person-dolly"></i>
                    <!-- <span class="blinking new" style="top: -5px; left: 6px;">New</span> -->
                  </a>
                </ng-container>
              </li>
              <li class="nav-item" *ngIf="!isMobile && is_alumni != 1">
                <ng-container>
                  <a
                     class="nav-link waves-light"
                     style="position: relative"
                     mdbWavesEffect
                     mdbTooltip="UPS Courier"
                     placement="left"
                     [routerLink]="
											is_only_bridgecourse == 1 ||
											is_only_aicpa == 1 ||
											is_only_integrity == 1 ||
											is_only_aicpa_inter == 1
												? '/lms/launchpad'
												: '/lms/ups-courier'
										"
                     (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()">
                    <i class="fad fa-shipping-timed"></i>
                    <!-- <span class="blinking new" style="top: -5px; left: 6px;">New</span> -->
                  </a>
                </ng-container>
              </li>
              <li class="nav-item position-relative">
                <ng-container>
                  <a
                     class="nav-link waves-light"
                     style="position: relative"
                     mdbWavesEffect
                     mdbTooltip="Tutorials"
                     placement="bottom"
                     [routerLink]="
											is_only_bridgecourse == 1 ||
											is_only_aicpa == 1 ||
											is_only_integrity == 1 ||
											is_only_aicpa_inter == 1
												? '/lms/launchpad'
												: '/lms/tutorials'
										"
                     (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()">
                    <i class="fad fa-video"></i>
                    <!-- <span class="blinking new">New</span> -->
                  </a>
                </ng-container>
              </li>
              <li class="nav-item" *ngIf="!isMobile && is_alumni != 1">
                <ng-container *ngIf="showFacs">
                  <a
                     class="nav-link waves-light"
                     style="position: relative"
                     mdbWavesEffect
                     mdbTooltip="FACS-payment"
                     placement="left"
                     [routerLink]="
											is_only_bridgecourse == 1 ||
											is_only_aicpa == 1 ||
											is_only_integrity == 1 ||
											is_only_aicpa_inter == 1
												? '/lms/launchpad'
												: '/lms/miles-facs-payment'
										"
                     (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()">
                    <i class="fad fa-normal fa-sack-dollar"></i>
                    <!-- <span class="blinking new" style="top: -4px;">New</span> -->
                  </a>
                </ng-container>
                <ng-container *ngIf="!!!showFacs">
                  <a
                     class="nav-link waves-light"
                     style="position: relative"
                     mdbWavesEffect
                     mdbTooltip="FACS-payment"
                     placement="left"
                     (click)="showNAPopup(); showNAPopUp(); evaluationPaymentModal(); showAttentionPopup()">
                    <i class="fad fa-normal fa-sack-dollar"></i>
                    <!-- <span class="blinking new" style="top: -4px;">New</span> -->
                  </a>
                </ng-container>
              </li>
              <!-- <li class="nav-item">
                <a *ngIf="userData?.is_aicpa_access != 1" class="nav-link waves-light" mdbWavesEffect
                  mdbTooltip="AICPA Payment" placement="left"
                  [routerLink]="is_only_bridgecourse == 1 ? '/lms/launchpad' : '/lms/aicpa-payment'"
                  (click)="showNAPopup()">
                  <img src="../../../assets/img/AICPA.png" alt="AICPA"
                    style="width: 20px;filter: grayscale(1);margin: -5px 0 0 0;">
                  <span class="blinking new" style="top: -5px; left: 282px;">New</span>
                </a>
                <a *ngIf="userData?.is_aicpa_access == 1" class="nav-link waves-light" mdbWavesEffect
                  mdbTooltip="AICPA Payment" placement="left"
                  [routerLink]="is_only_bridgecourse == 1 ? '/lms/launchpad' : '/lms/jobs/profile/aicpa'"
                  (click)="showNAPopup()">
                  <img src="../../../assets/img/AICPA.png" alt="AICPA"
                    style="width: 20px;filter: grayscale(1); margin: -5px 0 0 0;">
                  <span class="blinking new" style="top: -5px; left: 282px;">New</span>
                </a>
              </li> -->
              <li class="nav-item">
                <ng-container>
                  <a
                     class="nav-link waves-light"
                     style="position: relative"
                     mdbWavesEffect
                     mdbTooltip="Attendance"
                     placement="left"
                     [routerLink]="
											is_only_bridgecourse == 1 ||
											is_only_aicpa == 1 ||
											is_only_integrity == 1 ||
											is_only_aicpa_inter == 1
												? '/lms/launchpad'
												: 'lms/mileage/attendance'
										"
                     (click)="showNAPopup(); showNAPopup(); showNAPOPUp(); showAttentionPopup()">
                    <i class="fas fa-normal fa-file-user text-muted"></i>
                    <!-- <span class="blinking new" style="top: -5px; left: 6px;">New</span> -->
                  </a>
                </ng-container>
              </li>
              <!-- <li class="nav-item">
                <ng-container>
                  <a class="nav-link waves-light" style="position: relative;" mdbWavesEffect mdbTooltip="Tutorials"
                    placement="bottom" [routerLink]="is_only_bridgecourse == 1 ? '/lms/launchpad' : '/lms/tutorials'"
                    (click)="showNAPopup()">
                    <i class="fas fa-normal fa-camera-movie text-muted"></i>
                    <span class="blinking new" style="top: -5px; left: 8px;">New</span>
                  </a>
                </ng-container>
              </li> -->

              <!-- forum -->
              <!-- routerLink="/lms/mileage/webinar/subject"    -->
              <!-- <li class="nav-item btn-group" *ngIf="(!isMobile) && (is_only_bridgecourse != 1) && (is_alumni != 1)"> -->
              <!-- <a [routerLink]="['/lms/forum']" class="nav-link waves-light" mdbWavesEffect
                  style="position: relative;"> -->
              <!-- <span class="d-lg-inline-block d-md-none d-sm-none d-none mr-2">Forum</span>  -->
              <!-- <i class="fad fa-normal fa-receipt" [ngClass]="isMobile?'fa-normalNew':'fa-normal'"></i> -->
              <!-- <span _ngcontent-cjg-c200="" class="blinking new">New</span> -->
              <!-- </a>
              </li> -->
              <li
                  class="nav-item"
                  *ngIf="(is_only_bridgecourse != 1 && is_alumni != 1) || (is_only_aicpa && is_alumni) != 1">
                <a
                   class="nav-link waves-light"
                   mdbWavesEffect
                   mdbTooltip="Search"
                   placement="left"
                   [routerLink]="
										is_only_bridgecourse == 1 ||
										is_only_aicpa == 1 ||
										is_only_integrity == 1 ||
										is_only_aicpa_inter == 1
											? '/lms/launchpad'
											: '/lms/search'
									"
                   (click)="showNAPopup(); showNAPopUp(); showAttentionPopup()">
                  <i class="fad fa-normal fa-search" [ngClass]="isMobile ? 'fa-normalNew' : 'fa-normal'"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                   class="nav-link waves-light"
                   mdbWavesEffect
                   mdbTooltip="Contact"
                   placement="left"
                   (click)="openContactModal(); showAttentionPopup()">
                  <i class="fad fa-normal fa-comment-lines" [ngClass]="isMobile ? 'fa-normalNew' : 'fa-normal'"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                   class="nav-link waves-light"
                   mdbWavesEffect
                   mdbTooltip="Content Update"
                   placement="bottom"
                   (click)="contentUpdate(); showAttentionPopup()">
                  <i class="fad fa-normal fa-books" [ngClass]="isMobile ? 'fa-normalNew' : 'fa-normal'"></i>
                  <!-- <span class="blinking new" style="right: 140px;">New</span> -->
                </a>
              </li>
              <!-- <li class="nav-item" *ngIf="(is_only_bridgecourse || is_only_aicpa || is_only_integrity) != 1">
                <a class="nav-link waves-light" mdbWavesEffect mdbTooltip="Additional Update" placement="bottom"
                  (click)="additionContentUpdate();showAttentionPopup()">
                  <i class="fad fa-normal fa-books-medical" [ngClass]="isMobile?'fa-normalNew':'fa-normal'"></i> -->
              <!-- <span class="blinking new" style="top: -4px;">New</span> -->
              <!-- <span class="blinking" style="position: absolute;top: -6px;right: 100px;">New</span> -->
              <!-- additionalupdateModal.show(); -->
              <!-- </a> -->
              <!-- <span class="blinking new" style="right: 100px;">New</span> -->
              <!-- </li> -->

              <!-- notification -->
              <li
                  class="nav-item dropdown btn-group"
                  dropdown
                  *ngIf="(is_only_bridgecourse || is_only_aicpa || is_only_integrity) != 1">
                <a dropdownToggle type="button" class="nav-link waves-light" mdbWavesEffect>
                  <i class="fad fa-normal fa-bell" [ngClass]="isMobile ? 'fa-normalNew' : 'fa-normal'"></i>
                  <!-- <span class="blinking new">New</span> -->
                  <mdb-badge
                             pill="danger"
                             danger="true"
                             class="notification-update-badge"
                             *ngIf="notificationData.length">{{ notificationData.length }}</mdb-badge>
                </a>

                <div
                     class="dropdown-menu dropdown-primary dropdown-menu-right p-0 notification-block"
                     role="menu"
                     style="border: 2px solid #c3c6ca; min-width: 20rem">
                  <div
                       *ngIf="notificationData.length"
                       class="notification-header font-weight-bold d-flex justify-content-between">
                    <span class="my-auto">NEW</span>
                    <button
                            size="sm"
                            color="link"
                            mdbBtn
                            mdbWavesEffects
                            class="m-0 font-weight-bold"
                            (click)="marksNotificaionsread(0)"
                            style="font-size: 11px; padding: 0.2rem 0.5rem; color: #2d6fdc !important">
                      Clear all
                    </button>
                  </div>
                  <ng-container *ngIf="notificationData.length">
                    <ng-container *ngFor="let row of notificationData">
                      <ng-container *ngIf="row.is_post_closed === 0">
                        <div class="alert alert-info mb-0" role="alert" *ngIf="row.is_forum_admin === 0">
                          <a
                             (click)="
															marksNotificaionsread(row.int_post_id);
															goToForum(
																row.int_thread_type_id,
																row.int_subject_id,
																row.txt_question_id,
																row.int_topic_id,
																row.int_subtopic_id,
																row.int_video_id
															)
														">
                            <div class="pr-2">
                              <i
                                 class="fad"
                                 [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-alt-lines' : 'fa-comments-alt'"
                                 style="font-size: 1.2rem"></i>
                            </div>
                            <div *ngIf="row.is_post_r_reply === 1">
                              <b>{{ row.txt_name }}</b> replied to your
                              <b>{{
                                row.int_thread_type_id == 1
                                ? 'mcq'
                                : row.int_thread_type_id == 2
                                ? 'sim'
                                : row.int_thread_type_id == 3
                                ? 'video'
                                : row.int_thread_type_id == 4
                                ? 'ebook'
                                : ''
                                }}</b>
                              post
                            </div>
                            <div *ngIf="row.is_post_r_reply != 1">
                              <b>{{ row.txt_name }}</b> replied to your reply on
                              <b>{{
                                row.int_thread_type_id == 1
                                ? 'mcq'
                                : row.int_thread_type_id == 2
                                ? 'sim'
                                : row.int_thread_type_id == 3
                                ? 'video'
                                : row.int_thread_type_id == 4
                                ? 'ebook'
                                : ''
                                }}</b>
                              post
                            </div>
                          </a>
                        </div>
                        <div class="alert alert-primary mb-0" role="alert" *ngIf="row.is_forum_admin === 1">
                          <a
                             (click)="
															marksNotificaionsread(row.int_post_id);
															goToForum(
																row.int_thread_type_id,
																row.int_subject_id,
																row.txt_question_id,
																row.int_topic_id,
																row.int_subtopic_id,
																row.int_video_id
															)
														">
                            <div class="pr-2">
                              <i
                                 class="fad"
                                 [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-lines' : 'fa-comments'"
                                 style="font-size: 1.2rem"></i>
                            </div>
                            <div *ngIf="row.is_post_r_reply === 1">
                              <b>{{ row.txt_name }}</b> replied to your
                              <b>{{
                                row.int_thread_type_id == 1
                                ? 'mcq'
                                : row.int_thread_type_id == 2
                                ? 'sim'
                                : row.int_thread_type_id == 3
                                ? 'video'
                                : row.int_thread_type_id == 4
                                ? 'ebook'
                                : ''
                                }}</b>
                              post
                            </div>
                            <div *ngIf="row.is_post_r_reply != 1">
                              <b>{{ row.txt_name }}</b> replied to your reply on
                              <b>{{
                                row.int_thread_type_id == 1
                                ? 'mcq'
                                : row.int_thread_type_id == 2
                                ? 'sim'
                                : row.int_thread_type_id == 3
                                ? 'video'
                                : row.int_thread_type_id == 4
                                ? 'ebook'
                                : ''
                                }}</b>
                              post
                            </div>
                          </a>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="row.is_post_closed === 1">
                        <div class="alert alert-success mb-0" role="alert">
                          <a
                             (click)="
															marksNotificaionsread(row.int_post_id);
															goToForum(
																row.int_thread_type_id,
																row.int_subject_id,
																row.txt_question_id,
																row.int_topic_id,
																row.int_subtopic_id,
																row.int_video_id
															)
														"
                             style="text-decoration: none !important"
                             *ngIf="row.is_post_closed === 1">
                            <div class="pr-2">
                              <div
                                   class="font-weight-bold success-color-dark my-auto text-center px-2 text-white"
                                   style="font-size: 20px">
                                R
                              </div>
                            </div>
                            <div class="my-auto" style="text-decoration: underline">
                              <b>{{ row.txt_name }}</b> marked your
                              <b>{{
                                row.int_thread_type_id == 1
                                ? 'mcq'
                                : row.int_thread_type_id == 2
                                ? 'sim'
                                : row.int_thread_type_id == 3
                                ? 'video'
                                : row.int_thread_type_id == 4
                                ? 'ebook'
                                : ''
                                }}</b>
                              post as resolved
                            </div>
                          </a>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <div class="text-center px-3 py-1" *ngIf="!notificationData.length">
                    <div class="mx-auto mb-2" style="width: 100px">
                      <img src="/assets/img/no_notifications.png" class="img-fluid" alt="No Notification Banner" />
                    </div>
                    <span class="d-block h6 small">No new notification</span>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown btn-group" dropdown>
                <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                  <i class="fad fa-normal fa-user-shield mr-2" [ngClass]="isMobile ? 'fa-normalNew' : 'fa-normal'"></i>
                </a>
                <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu" style="width: 250px">
                  <div class="text-center px-3 py-1">
                    <div class="mx-auto mb-2" style="width: 100px">
                      <img
                           [src]="userData.profile_img ? userData.profile_img : defaultImage"
                           class="img-thumbnail"
                           alt="Student Avatar" />
                    </div>
                    <span class="d-block h5">{{ userData.stud_name }}</span>
                    <span class="d-block h6 small" style="overflow: hidden; text-overflow: ellipsis">{{
                      userData.email
                      }}</span>
                  </div>
                  <div class="divider dropdown-divider"></div>
                  <a class="dropdown-item" routerLink="/lms/my-profile">My Profile</a>
                  <div class="divider dropdown-divider"></div>
                  <a class="dropdown-item" routerLink="/lms/change-password">Change Password</a>
                  <div class="divider dropdown-divider"></div>
                  <!-- <a class="dropdown-item" (click)="showFeedbackModal()">Feedback</a> -->
                  <a class="dropdown-item" routerLink="/lms/jobs/helpdesk">Help Desk & Feedback</a>
                  <!-- <a class="dropdown-item" routerLink="/lms/support">Support</a> -->
                  <div class="divider dropdown-divider"></div>
                  <a class="dropdown-item" (click)="logout()">Logout</a>
                  <div class="divider dropdown-divider"></div>
                  <a class="float-right p-0 m-0"><small><code>App Version - 8</code></small></a>
                </div>
              </li>
            </ul>
          </links>
        </mdb-navbar-brand>
      </mdb-navbar>

      <!-- ###### Menu for mobile begin ####### -->
      <div class="d-block d-sm-block d-md-none d-lg-none">
        <footer
                class="font-small miles-footer"
                style="left: 0px; width: 100%; position: fixed; bottom: 0; z-index: 1050">
          <div
               class="row mx-0"
               style="background-image: url(/assets/img/sidenav.jpg); box-shadow: 0px 0px 30px -20px, 0px 0px 40px -25px">
            <div SideClass="navbar-dark indigo fixed-bottom" style="width: 100%">
              <links>
                <!-- Links -->
                <ul class="mb-0 px-0 row mx-0 w-100">
                  <li class="nav-item d-inline-block col px-0 launch-li">
                    <button
                            block="true"
                            #launch_m="bs-mdbPopover"
                            class="transform-none z-depth-0 rounded-0 text-center py-2 px-3 text-white web-grd other-menu-color btn-block btn"
                            mdbBtn
                            mdbWavesEffect
                            routerLinkActive="active"
                            (click)="closePopover(0); showAttentionPopup()"
                            type="button"
                            tabindex="0"
                            [mdbPopover]="SideNavTopicsPopOverForlaunchPad"
                            placement="top"
                            triggers="click">
                      <span class="fad fa-rocket fa-large-mobile" aria-hidden="true"></span>
                    </button>
                    <ng-template #SideNavTopicsPopOverForlaunchPad>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/fab"
                              (click)="closePopover(0); showNAPopup()">
                        <div>Accounting.101</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/jobs"
                              (click)="closePopover(0); showNAPopup()">
                        <div>Certification</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/fab"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>FAB</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/mac"
                              (click)="closePopover(0); checkurl(); showAttentionPopup(); showNAPopup()">
                        <div>
                          M.A.C
                          <sup style="position: relative; right: -60px"><i
                               style="position: absolute; top: -1px; right: 20px; font-size: 9px">BETA</i></sup>
                        </div>
                      </button>

                      <!-- <button type="button" class="transform-none my-2 mx-0 py-2" mdbBtn block="true" color="blue-grey"
                        mdbWavesEffect routerLink="/lms/mac"
                        (click)="undermaintenance()">
                        <div>M.A.C <sup style="position: relative;right: -60px;"><i
                              style="position: absolute; top: -1px; right: 20px; font-size: 9px;">BETA</i></sup></div>
                      </button> -->

                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/bridge-course"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>Bridge Course</div>
                      </button>

                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/practice-exam"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>Practice Test</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              [routerLink]="['/lms/university-programme']"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>IIMI-PGCIAA</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/mileage/appointments/new"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>Mentoring</div>
                      </button>

                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/mileage/study_planner"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>Study Planner</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/mileage/webinar/subject"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>Webinar</div>
                      </button>

                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/classroom"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>
                          Face to Face
                          <sup style="position: relative; right: -60px"><i
                               style="
																position: absolute;
																top: -1px;
																right: 20px;
																font-size: 9px;
																animation: menuText 1s infinite;
															">NEW</i></sup>
                        </div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              routerLink="/lms/launchpad"
                              (click)="closePopover(0); showAttentionPopup(); showNAPopup()">
                        <div>Launchpad</div>
                      </button>
                    </ng-template>
                  </li>
                  <li
                      class="nav-item d-inline-block col px-0 aud-li"
                      *ngIf="subjectDetails[0]?.is_mcqs_purchased === 2">
                    <button
                            block="true"
                            class="transform-none z-depth-0 rounded-0 text-center py-2 px-3 text-white btn-block btn"
                            mdbBtn
                            mdbWavesEffect
                            type="button"
                            tabindex="0"
                            #aud_m="bs-mdbPopover"
                            (click)="closePopover(1)"
                            [mdbPopover]="SideNavTopicsPopOverForAud"
                            placement="top"
                            triggers="click">
                      <span class="fad fa-search-dollar fa-large-mobile" aria-hidden="true"></span>
                    </button>
                    <ng-template #SideNavTopicsPopOverForAud>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/topic', subjectDetails[0]?.subject_id_encrypt]"
                              (click)="aud_m.hide()">
                        <div>Launch AUD</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              (click)="aud_m.hide()"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/result', subjectDetails[0]?.subject_id_encrypt]">
                        <div>View Report</div>
                      </button>
                    </ng-template>
                  </li>
                  <li
                      class="nav-item d-inline-block col px-0 bec-li"
                      *ngIf="subjectDetails[1]?.is_mcqs_purchased === 2">
                    <button
                            block="true"
                            class="transform-none z-depth-0 rounded-0 text-center py-2 px-3 text-white btn-block btn"
                            mdbBtn
                            mdbWavesEffect
                            type="button"
                            tabindex="0"
                            #bec_m="bs-mdbPopover"
                            (click)="closePopover(2)"
                            [mdbPopover]="SideNavTopicsPopOverForBec"
                            placement="top"
                            triggers="click">
                      <span class="fad fa-chart-line fa-large-mobile" aria-hidden="true"></span>
                    </button>
                    <ng-template #SideNavTopicsPopOverForBec>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              (click)="bec_m.hide()"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/topic', subjectDetails[1]?.subject_id_encrypt]">
                        <div>Launch BEC</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              (click)="bec_m.hide()"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/result', subjectDetails[1]?.subject_id_encrypt]">
                        <div>View Report</div>
                      </button>
                    </ng-template>
                  </li>
                  <li
                      class="nav-item d-inline-block col px-0 far-li"
                      *ngIf="subjectDetails[2]?.is_mcqs_purchased === 2">
                    <button
                            block="true"
                            class="transform-none z-depth-0 rounded-0 text-center py-2 px-3 text-white btn-block btn"
                            mdbBtn
                            mdbWavesEffect
                            type="button"
                            tabindex="0"
                            #far_m="bs-mdbPopover"
                            (click)="closePopover(3)"
                            [mdbPopover]="SideNavTopicsPopOverForFar"
                            placement="top"
                            triggers="click">
                      <span class="fad fa-university fa-large-mobile" aria-hidden="true"></span>
                    </button>
                    <ng-template #SideNavTopicsPopOverForFar>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              (click)="far_m.hide()"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/topic', subjectDetails[2]?.subject_id_encrypt]">
                        <div>Launch FAR</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              (click)="far_m.hide()"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/result', subjectDetails[2]?.subject_id_encrypt]">
                        <div>View Report</div>
                      </button>
                    </ng-template>
                  </li>
                  <li
                      class="nav-item d-inline-block col px-0 reg-li"
                      *ngIf="subjectDetails[3]?.is_mcqs_purchased === 2">
                    <button
                            block="true"
                            class="transform-none z-depth-0 rounded-0 text-center py-2 px-3 text-white btn-block btn"
                            mdbBtn
                            mdbWavesEffect
                            type="button"
                            tabindex="0"
                            #reg_m="bs-mdbPopover"
                            (click)="closePopover(4)"
                            [mdbPopover]="SideNavTopicsPopOverForReg"
                            placement="top"
                            triggers="click">
                      <span class="fad fa-gavel fa-large-mobile" aria-hidden="true"></span>
                    </button>
                    <ng-template #SideNavTopicsPopOverForReg>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              (click)="reg_m.hide()"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/topic', subjectDetails[3]?.subject_id_encrypt]">
                        <div>Launch REG</div>
                      </button>
                      <button
                              type="button"
                              class="transform-none my-2 mx-0 py-2"
                              mdbBtn
                              block="true"
                              color="blue-grey"
                              (click)="reg_m.hide()"
                              mdbWavesEffect
                              [routerLink]="['/lms/subject/subjectname/result', subjectDetails[3]?.subject_id_encrypt]">
                        <div>View Report</div>
                      </button>
                    </ng-template>
                  </li>
                  <!-- <li class="nav-item d-inline-block col px-0">
  <button block="true"
  class="transform-none z-depth-0 rounded-0 text-center py-2 px-3 text-white web-grd other-menu-color btn-block btn"
  mdbBtn mdbWavesEffect type="button" tabindex="0" routerLink="/webinar/connect/subject"
  (click)="closePopover(0);">
  <span class="fad fa-laptop fa-large-mobile" aria-hidden="true"></span>
  </button>
  </li> -->
                </ul>
                <!-- Links -->
              </links>
            </div>
          </div>
        </footer>
      </div>
      <!-- ###### Menu for mobile ends ####### -->

      <div>
        <router-outlet></router-outlet>
        <div class="d-block d-sm-block d-md-none d-lg-none mb-5"></div>
      </div>
    </div>
  </div>
</header>