import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IndexdbServiceService {
  tokenSubject = new BehaviorSubject(null);
  token = this.tokenSubject.asObservable();
  testoken: any = null
  constructor(private dbService: NgxIndexedDBService) { }

  addTokenToIndexBd(token: any) {
    localStorage.setItem('secrettoken', token);
  }

  addDataToIndexBd(data: any) {
    this.dbService
      .add('credentials', { login_Data: data })
      .subscribe((key) => {
        // console.log('key: ', key);
      });
  }

  getToken() {
    return localStorage.getItem('secrettoken');
  }

  getUserData() {
    return this.dbService.getAll('credentials');
  }

  deleteAll() {
    this.dbService.clear('credentials').subscribe((credentials) => {
      // console.log('all credentials cleard:', credentials);
    });
  }
}
