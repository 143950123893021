import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { url } from 'inspector';
import { AnyCnameRecord } from 'dns';
import { Param } from 'cloudinary-core';

@Injectable({
  providedIn: 'root'
})
export class GamePlanService {
  // Url = 'https://cpa.miles-api.com/';
  Url = `${environment.apiUrl}`;
  // Url = 'https://d0f78ce60b9a.ngrok.io/';
  universityListSubject: Subject<any> = new Subject();
  universityList = this.universityListSubject.asObservable();
  evaluationAgencySubject: Subject<any> = new Subject();
  evaluationAgencyList = this.evaluationAgencySubject.asObservable();
  stateBoardSubject: Subject<any> = new Subject();
  stateBoardList = this.stateBoardSubject.asObservable();
  plannerGraphsubject: Subject<any> = new Subject();
  plannerGraphdata = this.plannerGraphsubject.asObservable();
  getEducationsubject: Subject<any> = new Subject();
  getEducationdata = this.getEducationsubject.asObservable();
  getStatusSubject: Subject<any> = new Subject();
  plannerFiltersubject: Subject<any> = new Subject();
  plannerFilterdata = this.plannerFiltersubject.asObservable();

  getProgressList = new Subject();
  metadataObserver = new Subject();
  getEvaluationObserver = new Subject();
  getEvaluationAdminObserver = new Subject();
  getNtsObserver = new Subject();
  getNtsAdminObserver = new Subject();
  getEvaluationChatsObserver = new Subject();
  getEducationChatsObserver = new Subject();
  agencyListObserver = new Subject();
  agencyAdminListObserver = new Subject();
  getExamObserver = new Subject();
  mainmetadataObserver = new Subject();
  chatsMetaDataObserver = new Subject();
  newUniListObserver = new Subject();
  getEvalObserver = new Subject();
  getEvalmetaObserver = new Subject();
  getcurrentgpObserver = new Subject();
  getcurrentgpObserver1 = new Subject();
  getOtbStatusMessageObserver = new Subject();
  getstudentdetailsObserver = new Subject();
  getDashboardDetailsObserver = new Subject();
  getDashboardMetaDetailsObserver = new Subject();
  getAdminExamDataObserver = new Subject();
  getStudentGameplanStatusDataObserver = new Subject();
  getStudentGameplanStatusMetaDataObserver = new Subject();

  student_eligibility_report_DataObserver: BehaviorSubject<any> = new BehaviorSubject([]);
  studentDetailsObserver = new Subject();

  // done by sachin singh
  getLicenseDocsDetailsObserver = new Subject();
  getLicenseEvalDetailsObserver = new Subject();
  getLicenseFormsDetailsObserver = new Subject();
  getLicenseInstructionDetailsObserver = new Subject();


  constructor(private http: HttpClient) { }
  updateEducation(data) {
    this.http.post(this.Url + 'gameplan/updateEducation',
      { education_data: [...data] }).subscribe((res: any) => {
        if (res && res.status) {
          this.getEducation();
        }
      });
  }
  getEducation() {
    this.http.post(this.Url + 'gameplan/getEducation', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.getEducationsubject.next(res.data);
      }
    });
  }
  deleteEducation(id) {
    this.http.post(this.Url + 'gameplan/deleteEducation', { id }).subscribe((res: any) => {
      if (res && res.status) {
        this.getEducation();
      }
    });
  }
  getEvaluation() {
    return this.http.post(this.Url + 'gameplan/getEvaluation', {});
  }
  updateEvaluation(evaluationData) {
    return this.http.post(this.Url + 'gameplan/updateEvaluation', { ...evaluationData });
  }
  getNts() {
    return this.http.post(this.Url + 'gameplan/getNts', {});
  }
  updateNts(nts_data) {
    return this.http.post(this.Url + 'gameplan/updateNts',
      { nts_data: [...nts_data] })
  }
  uploadEvaluationImage(evaluation_image) {
    return this.http.post(this.Url + 'gameplan/uploadEvaluationImage', { evaluation_image });
  }
  updateExam(exam_data) {
    return this.http.post(this.Url + 'gameplan/updateExam',
      { exam_data: [...exam_data] })
  }
  // getExam() {
  //   return this.http.post(this.Url + 'gameplan/getExam', {})
  // }

  getExam() {
    return this.http.post(this.Url + 'gameplan/getExamSchedules', {})
  }
  getDegree(type) {
    return this.http.post(this.Url + 'gameplan/getDegree', { type });
  }
  getUniversity() {
    this.http.post(this.Url + 'gameplan/getUniversity', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.universityListSubject.next(res.data);
      }
    });
  }
  getEvaluationAgency() {
    this.http.post(this.Url + 'gameplan/getEvaluationAgency', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.evaluationAgencySubject.next(res.data);
      }
    });
  }
  getStateBoard() {
    this.http.post(this.Url + 'gameplan/getStateBoard', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.stateBoardSubject.next(res.data);
      }
    });
  }
  getstudyplanner(subject_id, plannerFormData?) {
    this.http.post(this.Url + 'getstudyplanner',
      { subject_id, ...plannerFormData }).subscribe((res: any) => {
        if (res && res.status) {
          this.plannerGraphsubject.next(res);
        }
      });
  }
  getstudyplannerFilter(subject_id) {
    this.getstudyplanner(subject_id);
    this.http.post(this.Url + 'getstudyplannerFilter',
      { subject_id }).subscribe((res: any) => {
        if (res && res.status) {
          this.plannerFiltersubject.next(res)
        }
      });
  }
  getstatusdropdown() {
    this.http.post(this.Url + 'gameplan/getstatusdropdown', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.getStatusSubject.next(res.data);
      }
    });
  }

  // added by durgesh

  updateBachelors(params) {
    return this.http.post(this.Url + 'gameplan/updateEducation', params);
  }

  getProgressReport(params) {
    this.http.post(this.Url + 'gameplan/getEducation', params).subscribe((res: any) => {
      if (res && res.status) {
        if (res.data.bachelor?.length > 0) {
          res.data.bachelor.forEach(element => {
            element.document_upload = JSON.parse(element.document_upload);
          });
        }
        if (res.data.certification?.length > 0) {
          res.data.certification.forEach(element => {
            element.document_upload = JSON.parse(element.document_upload);
          });
        }

        if (res.data.diploma?.length > 0) {
          res.data.diploma.forEach(element => {
            element.document_upload = JSON.parse(element.document_upload);
          });
        }

        if (res.data.master?.length > 0) {
          res.data.master.forEach(element => {
            element.document_upload = JSON.parse(element.document_upload);
          });
        }
        // console.log('data', res);
        this.getProgressList.next(res.data);
        this.metadataObserver.next(res.metadata);
      }
    });
  }

  getMainProgressReport(params) {
    this.http.post(this.Url + 'gameplan/getEducation', params).subscribe((res: any) => {
      if (res.status) {
        this.mainmetadataObserver.next(res.metadata);
      }
    });
  }

  deleteDegree(gameplan_edu_id) {
    return this.http.post(this.Url + 'gameplan/deleteEducation', { id: gameplan_edu_id });
  }

  getNewEvaluation() {
    this.http.post(this.Url + 'gameplan/getEvaluation', {}).subscribe((res: any) => {
      if (res.data.length > 0 && res.status) {
        //// console.log('data evalucation', res.data);
        if (res.data[0]?.uploaded_file_name) {
          res.data[0].uploaded_file_name = JSON.parse(res.data[0].uploaded_file_name);
        }
        this.getEvaluationObserver.next(res.data);

      }
      if (res.status) {
        this.metadataObserver.next(res.metadata);
      }
    });
  }

  updateNewEvaluation(params) {
    return this.http.post(this.Url + 'gameplan/updateEvaluation', params);

  }

  getNewNts() {
    this.http.post(this.Url + 'gameplan/getNts', {}).subscribe((res: any) => {
      if (res.data.length > 0 && res.status) {
        //// console.log('data nts', res.data);
        if (res.data[0].uploaded_file_name != '') {
          res.data[0].uploaded_file_name = JSON.parse(res.data[0].uploaded_file_name);
        }
        if ((res.data[0].passport_file !== undefined) && (res.data[0].passport_file != '')) {
          res.data[0].passport_file = JSON.parse(res.data[0].passport_file);
        }

        this.getNtsObserver.next(res.data);
        this.metadataObserver.next(res.metadata);
      }
    });
  }

  updateNewNts(params) {
    return this.http.post(this.Url + 'gameplan/updateNts', params);

  }

  updateExamPrometric(params) {
    return this.http.post(this.Url + 'gameplan/updateExamPrometric', params);
  }

  getNewExam() {
    this.http.post(this.Url + 'gameplan/getExam', {}).subscribe((res: any) => {
      if (res.data.length > 0 && res.status) {
        if (res.data[0].international_testing_email) {
          res.data[0].international_testing_email = JSON.parse(res.data[0].international_testing_email);
        }
        if (res.data[0].exam_scheduling_email) {
          res.data[0].exam_scheduling_email = JSON.parse(res.data[0].exam_scheduling_email);
        }
        if (res.data[0].uploaded_file_name != '') {
          res.data[0].uploaded_file_name = JSON.parse(res.data[0].uploaded_file_name);
        }
        this.getExamObserver.next(res.data);
      }
    });
  }

  getNewEvaluationChats(msg_panel) {
    this.http.post(this.Url + 'gameplan/listChat', { msg_panel }).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('data', res.data);
        this.getEvaluationChatsObserver.next(res.data);
        this.chatsMetaDataObserver.next(res.metadata);
      }
    });
  }

  pushevaluationChats(params) {
    return this.http.post(this.Url + 'gameplan/chat', params);

  }

  getNewEducationChats() {
    this.http.post(this.Url + 'gameplan/listChat', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('data', res.data);
        this.getEducationChatsObserver.next(res.data);
        this.chatsMetaDataObserver.next(res.metadata);
      }
    });
  }

  getDashboardDetails() {
    this.http.post(this.Url + 'gameplan/getDashboard', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('data', res.data);
        this.getDashboardDetailsObserver.next(res.data);
        this.getDashboardMetaDetailsObserver.next(res.metadata);
      }
    });
  }

  getStatusMessages() {
    // //// console.log('Params', params);
    this.http.post(this.Url + 'student/details', {}).subscribe((res: any) => {
      if (res && res.status) {

        this.getOtbStatusMessageObserver.next(res.metadata);
        // //// console.log('messageDAta', res.metadata);
        // this.chatsMetaDataObserver.next(res.metadata);
      }
    });
  }

  getStudentDetails() {
    // //// console.log('Params', params);
    this.http.post(this.Url + 'student/details', {}).subscribe((res: any) => {
      if (res && res.status) {

        this.getstudentdetailsObserver.next(res.data);
        // //// console.log('messageDAta', res.metadata);
        // this.chatsMetaDataObserver.next(res.metadata);
      }
    });
  }

  sendDocumentUpdateMail(param) {
    this.getStudentDetails();
    var temp;
    var mailparam;
    var userData = param.userData
    temp = `<html>
      <body style="font-family: 'Overpass', sans-serif;font-size: 0.875rem;">
          <div style="width:75%;margin:auto;border:1px solid #1d2c51;border-radius: calc(0.4rem - 1px);">
            <div style="background:#1d2c51;padding:0.5rem!important;border-bottom:1px solid #e8ebf1;border-top-left-radius:calc(0.3rem - 1px);border-top-right-radius:calc(0.3rem - 1px)">
                <div style="display:flex; flex-direction: column !important;justify-content:space-between">
                    <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/MilesEducationWebsite/MilesEducation_Logos/mileslogoWhite1.png" alt="Miles Education Official Logo" style="width: 130px" class="p-1">
                </div>
            </div>
              <div style="background: #fafafa;  position: relative; flex: 1 1 auto; padding: 0.937rem;border-radius: calc(0.4rem - 1px);">
                  <p style="text-align: left;padding: 0rem;margin: 0rem"> 
                      Hi <span style="font-weight:bold">${userData.spoc_name}</span>,<br>
                      <br>Greetings from Miles!<br><br>
                     <p><b>${userData.stud_name}</b> with email id <b>${userData.email}</b> and eligibility code <b>${param.eligibilityCode}</b>, has updated the education documents. Please check the same.</p>

                      <br><br>
                      Thanks,<br>
                      <b>Team Miles</b>
                  </p> 
              </div>
          </div>
      </body>
  </html>`;
    mailparam = {
      template: temp,
      emailSubject: 'Additional document uploaded by eligibility code ' + param.eligibilityCode,
      email: userData.email,
      //email:'mamatha.epili@mileseducation.com',
      spocEmail: 'mansi.bhalla@mileseducation.com,mamatha.epili@mileseducation.com,chakradhar.gollapudi@mileseducation.com'
    }
    return this.http.post(this.Url + 'sendGPNotificationEmail', mailparam)
  }



  pushEducationChats(params) {
    return this.http.post(this.Url + 'gameplan/chat', params);

  }


  previewAndUpdateEducation(params) {
    return this.http.post(this.Url + 'gameplan/updateStudentEducationStatus', params);
  }

  previewAndUpdateEvaluation(params) {
    return this.http.post(this.Url + 'gameplan/updateStudentEvaluationStatus', params);
  }

  previewAndUpdateNts(params) {
    return this.http.post(this.Url + 'gameplan/updateStudentNtsStatus', params);
  }
  previewAndUpdateExamScheduling(params) {
    return this.http.post(this.Url + 'gameplan/updateStudentExamStatus', params)
  }

  assignStudentSpocName() {
    this.http.post(this.Url + 'gameplan/assignStudentSpocName', {}).subscribe(res => {
      //// console.log(res);
    })
  }

  getevalConfirmation() {
    this.http.post(this.Url + 'gameplan/getEvaluationConfirmation', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('data', res.data);
        this.getEvalObserver.next(res.data);
      }
    });

  }

  getcurrentgpstatus() {
    this.http.post(this.Url + 'gameplan/getcurrentgpstatus', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('data', res.data);
        this.getcurrentgpObserver.next(res.data);
      }
    });
  }

  getcurrentgpstatus1() {
    this.http.post(this.Url + 'gameplan/getcurrentgpstatus', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('data', res.data);
        this.getcurrentgpObserver1.next(res.data);
      }
    });
  }

  evalConfirmationPopup(params) {
    return this.http.post(this.Url + 'gameplan/evalConfirmationPopup', params);
  }
  // 'https://cpa-api-uat.mileseducation.com/gameplan/admin/getAgencyList'
  getAgencyList() {
    this.http.post(this.Url + 'gameplan/admin/getAgencyList', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('getAgencyList', res);
        this.agencyListObserver.next(res['data']);
      }
    });
  }

  newUniversityList(type) {
    this.http.post(this.Url + 'gameplan/getUniversityList', { type }).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('getAgencyList', res);
        this.newUniListObserver.next(res['data']);
      }
    });
  }

  getAdminAgencyList() {
    this.http.post(this.Url + 'gameplan/admin/getAgencyList', {}).subscribe((res: any) => {
      if (res && res.status) {
        // console.log('getAgencyList', res);
        this.agencyAdminListObserver.next(res['data']);
      }
    });
  }

  //=================== Admin EligiblityReport =======================

  geteligibility_report(params) {
    this.http.post(this.Url + 'gameplan/admin/eligibilityreport', params).subscribe((res: any) => {
      if (res && res.status) {
        //  console.log('datajjj',res);
        this.student_eligibility_report_DataObserver.next(res.data[0]);
      }
    })
  }
  studentDetails(student_id) {
    this.http.post(this.Url + 'gameplan/admin/studentDetails', { student_id }).subscribe((res: any) => {
      if (res && res.status) {
        this.studentDetailsObserver.next(res.data);
      }
    });
  }


  getNextActionUrl(id) {
    let url = {
      9: '/lms/mileage/gameplan/qualification/bachelor',
      1: '/lms/mileage/gameplan/qualification/preview',
      2: '/lms/mileage/gameplan/evaluation/upload-evaluation-report',
      3: '/lms/mileage/gameplan/evaluation/upload-evaluation-report',
      4: '/lms/mileage/gameplan/evaluation/upload-evaluation-report',
      10: '/lms/mileage/gameplan/nts/upload-nts',
      5: '/lms/mileage/gameplan/nts/upload-nts',
      6: '/lms/mileage/gameplan/exam-scheduling',
      7: '/lms/mileage/gameplan/exam-scheduling',
      8: '/lms/mileage/gameplan/exam-scheduling',
      11: '/lms/mileage/gameplan/dashboard',
      12: '/lms/mileage/gameplan/dashboard',
      14: '/lms/mileage/gameplan/exam-scheduling',
      15: '/lms/mileage/gameplan/last-minute-check',
      16: '/lms/mileage/gameplan/exam-scheduling',

    };
    return url[id];
  }

  // submit_eligibility() {
  //   return this.http.post(this.Url + 'submit_eligibility', {})
  // }
  submit_eligibility(param) {
    return this.http.post(this.Url + 'submit_eligibility', param)
  }

  evaluation_payment(param) {
    return this.http.post(this.Url + 'createPaymentinitiate', param);
  }

  student_facsdetails() {
    return this.http.post(this.Url + 'student_facsdetails', {});
  }

  updateEducationStatus(params) {
    return this.http.post(this.Url + 'gameplan/admin/updateEducationStatus', params);
  }


  updateAdminEvaluation(params) {
    return this.http.post(this.Url + 'gameplan/admin/updateEvaluation', params);

  }


  getAdminEvaluation(student_id, call_from) {
    this.http.post(this.Url + 'gameplan/admin/getEvaluation', { student_id, call_from }).subscribe((res: any) => {
      if (res && res.status) {
        // console.log('data1', res.data);
        if (res.data.length > 0) {
          // console.log('enter', res.data);
          res.data[0].update_status = res.data[0].evaluation_status
          res.data[0].uploaded_file_name = JSON.parse(res.data[0].uploaded_file_name);
          this.getEvaluationAdminObserver.next(res.data);
          // this.metadataObserver.next(res['metadata']);
        }

      }
    });
  }


  getAdminNts(student_id, call_from) {
    this.http.post(this.Url + 'gameplan/admin/getNts', { student_id, call_from }).subscribe((res: any) => {
      if (res && res.status) {
        // console.log('data1', res.data);
        if (res.data.length > 0) {
          // console.log('enter', res.data);
          res.data[0].update_status = res.data[0]?.nts_status
          if (!(res.data[0]?.uploaded_file_name == "" || res.data[0]?.uploaded_file_name == null)) {
            res.data[0].uploaded_file_name = JSON.parse(res.data[0].uploaded_file_name);
            res.data[0].passport_file = JSON.parse(res.data[0].passport_file);
          }
          if (res.data[0].email) {
            res.data[0].email = JSON.parse(res.data[0].email);
          }
          // console.log('final nts data after eval', res.data);
          this.getNtsAdminObserver.next(res.data);
          // this.metadataObserver.next(res['metadata']);

        }
      }
    });
  }

  updateAdminNts(params) {
    return this.http.post(this.Url + 'gameplan/admin/updateNts', params);
  }


  updateAdminExamEmail(params) {
    return this.http.post(this.Url + 'gameplan/admin/updateExam', params);

  }

  getAdminExamData(student_id, call_from) {
    this.http.post(this.Url + 'gameplan/admin/getExam', { student_id, call_from }).subscribe((res: any) => {
      if (res && res.status) {
        // console.log('data', res.data);
        if (res.data[0]?.international_testing_email) {
          res.data[0].international_testing_email = JSON.parse(res.data[0].international_testing_email);
        }
        if (res.data[0]?.exam_scheduling_email) {
          res.data[0].exam_scheduling_email = JSON.parse(res.data[0].exam_scheduling_email);
        }

        this.getAdminExamDataObserver.next(res.data);

      }
    });
  }

  getStudentGameplanStatus(param) {
    this.http.post(this.Url + 'gameplan/candGameplanStatus', param).subscribe((res: any) => {
      this.getStudentGameplanStatusDataObserver.next(res.data);
      this.getStudentGameplanStatusMetaDataObserver.next(res['metadata']);
    })
  }
  // done by Sachin singh
  licenseDocuments(param: any) {
    return this.http.post(this.Url + 'gameplan/licenseDocuments', param)
  }
  licenseEvaluation(param: any) {
    return this.http.post(this.Url + 'gameplan/licenseEvaluation', param)
  }
  licenseForms(param: any) {
    return this.http.post(this.Url + 'gameplan/licenseForms', param)
  }
  licenseInstruction(param: any) {
    return this.http.post(this.Url + 'gameplan/licenseInstruction', param)
  }

  getLicenseDocsDetails() {
    this.http.post(this.Url + "gameplan/getLicenseDocsDetails", {}).subscribe((res: any) => {
      this.getLicenseDocsDetailsObserver.next(res.data)
    })
  }
  getLicenseEvalDetails() {
    this.http.post(this.Url + "gameplan/getLicenseEvalDetails", {}).subscribe((res: any) => {
      this.getLicenseEvalDetailsObserver.next(res.data)
    })
  }
  getLicenseFormsDetails() {
    this.http.post(this.Url + "gameplan/getLicenseFormDetails", {}).subscribe((res: any) => {
      this.getLicenseFormsDetailsObserver.next(res.data)
    })
  }
  getLicenseInstructionDetails() {
    this.http.post(this.Url + "gameplan/getLicenseInstructionDetails", {}).subscribe((res: any) => {
      this.getLicenseInstructionDetailsObserver.next(res.data)
    })
  }
  getlicenceStepStatus() {
    return this.http.post(this.Url + "gameplan/getlicenceStepStatus", {})
  }
  getLastMinute(param) {
    return this.http.post(this.Url + "gameplan/getLastMinute", param)
  }
}
