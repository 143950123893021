import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable, BehaviorSubject, Subject, of, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { AngularFirestore } from '@angular/fire/compat/firestore'
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  Url = `${environment.apiUrl}`

  picImageData: BehaviorSubject<any> = new BehaviorSubject([])
  dashboardMeta = new Subject()
  cityModalSubject = new Subject()
  cityModalData = this.cityModalSubject.asObservable()

  dashBoardDataControl: {
    call: boolean
    data: any
    meta: any
    count: number
  } = {
      call: false,
      data: '',
      meta: '',
      count: 0,
    }

  constructor(private http: HttpClient, private firestore: AngularFirestore) { }

  login(registration_id, password) {
    localStorage.clear()
    return this.http.post(this.Url + 'studentLogin', { registration_id, password })
  }

  // getUserByToken(subject_id?: string): Observable<any> {
  //   this.dashBoardDataControl.count++
  //   if (this.dashBoardDataControl.count > 2) {
  //     if (this.dashBoardDataControl.call === false) {
  //       console.log("trest hgwvkuef gquweyt ri")
  //       const myPipe = this.http.post(this.Url + 'dashboard', { request_from: 'web', subject_id }).pipe(
  //         map((response: Response) => {
  //           if (response.status) {
  //             console.log(this.dashBoardDataControl, 'dashboard control')
  //             this.dashBoardDataControl.call = true;
  //             this.dashBoardDataControl.meta = response['metadata'];
  //             this.dashBoardDataControl.data = response['data'];
  //             this.dashboardMeta.next(response['metadata'])
  //             return response['data'];
  //           }
  //         }),
  //         catchError(err => this.handleError(err))
  //       );
  //       return myPipe
  //     } else {
  //       this.dashboardMeta.next(this.dashBoardDataControl.meta)
  //       return this.dashBoardDataControl.data;
  //     }
  //   }

  // }

  getUserByToken(subject_id?: string): Observable<any> {
    return this.http.post(this.Url + 'dashboard', { request_from: 'web', subject_id }).pipe(
      map((response: Response) => {
        // if (response.status) {
        this.dashboardMeta.next(response['metadata'])
        return response['data']
        // }
      }),
      catchError((err) => this.handleError(err))
    )
  }
  // {
  //     "stud_name": "Pulluru Krishnareddy",
  //     "email": "pulluru.krishnareddy@mileseducation.com",
  //     "alternate_email": null,
  //     "phone_no": "",
  //     "alternate_phone_no": null,
  //     "college_company_name": "",
  //     "gender": null,
  //     "profile_img": "https://content.careergraph.com/lms-profile-pics/img-5_1682657912276.png",
  //     "about_me": "",
  //     "user_type": 1,
  //     "profile_background_image": "",
  //     "otb_id": "85525f420e1c53e04918689535698f35",
  //     "is_admin": 1,
  //     "date_created": "2022-11-07 15:52:28",
  //     "txt_registration_id": "pulluru.krishnareddy@mileseducation.com",
  //     "int_city_id": "2",
  //     "city_name": "BANGALORE",
  //     "int_spoc_id": 54,
  //     "spoc_email": "neha.agarwal@mileseducation.com",
  //     "spoc_name": "Neha Agarwal",
  //     "is_demo_access": 0,
  //     "is_mobile_verified": null,
  //     "is_forum_admin": 0,
  //     "is_eligibility": null,
  //     "mwb_id": null,
  //     "identity": null,
  //     "is_bridge_course": 2,
  //     "txt_jain_batch": "Calendar-year",
  //     "enrollment_date": "2022-11-07",
  //     "is_alumni": 0,
  //     "int_mf_spoc_id": null,
  //     "int_mf_gm_id": null,
  //     "is_noncommerce_track": null,
  //     "int_course_id": 1,
  //     "int_user_id": 11070,
  //     "is_only_aicpa": 0,
  //     "is_only_integrity": null,
  //     "is_cpa101_access": 0,
  //     "is_integrity_auditing_access": null,
  //     "is_integrity_taxation_access": null,
  //     "current_city": "Bengaluru",
  //     "is_forum_super_admin": null,
  //     "is_only_aicpa_inter": null,
  //     "iimi_university_id": null,
  //     "is_iimiaa_access": null,
  //     "iimi_university_name": null,
  //     "batch_name": null,
  //     "notfication_count": 0,
  //     "show_gameplan_menu": 1
  // }
  studentDetails(): Observable<any> {
    return this.http.post(this.Url + 'student/details', { mf_token: localStorage.getItem('mf_token') }).pipe(
      map((response: any) => {
        localStorage.setItem('iimi-university', JSON.stringify(response['data']))
        return response['data']
      }),
      catchError((err) => this.handleError(err))
    )
  }

  private handleError(error: Response) {
    return throwError(error.statusText)
  }

  forgotPassword(registered_email) {
    return this.http.post(this.Url + 'student/forgotPassword', { registered_email })
  }

  forgotfofPassword(registered_email) {
    return this.http.post(this.Url + 'fof/forgotfofPassword', { registered_email })
  }

  changepassword(old_password, new_password, resent_new_password) {
    return this.http.post(this.Url + 'student/changepassword', {
      old_password,
      new_password,
      resent_new_password,
      mf_token: localStorage.getItem('mf_token'),
    })
  }

  SupportEmail(support_subject, support_message, student_email) {
    return this.http.post(this.Url + 'student/saveSupport', {
      support_subject,
      support_message,
      student_email,
    })
  }

  uploadImage(profile_image) {
    this.http.post(this.Url + 'student/uploadImage', { profile_image }).subscribe((res: any) => {
      if (res && res.status) {
        this.picImageData.next(res.data)
      }
    })
  }

  getCityList() {
    return this.http.post(`${this.Url}getCityList`, '')
  }

  updateCurrentCity(data) {
    return this.http.post(`${this.Url}updateCurrentCity`, data)
  }

  setCityModalData(data: any) {
    this.cityModalSubject.next(data)
  }

  usazoomRegister(data) {
    return this.http.post(this.Url + 'admin/webinar/usazoomRegister', data)
  }
}
