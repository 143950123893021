<!-- <div mdbModal #infoModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content"> -->
<div>
    <!-- <div class="modal-header custom_header modal_transparent_header">
        <h4 class="modal-title w-100" id="myModalLabel"><i class="fas fa-clipboard-check mr-2"></i>IMA Registration</h4>
    </div> -->

    <div class="modal-body custom_body custom_video_ratio p-0">

        <div class="img-back">
            <div class="row">
                <div class="col-lg-6 col-12 my-auto">
                    <img src="../../../../assets/img/ima-pop.png" alt="IMA Registration" class="img-fluid">
                </div>
                <div class="col-lg-6 col-12">
                    <div class="card" style="border:none">
                        <div class="card-body">
                            <form>
                                <ng-container *ngIf="imaData.length && imaData[0].ima_code">
                                    <p>Is your IMA Customer ID/Membership Number Correct?</p>
                                    <div class="md-form">
                                        <input [value]="imaData[0].ima_code" type="text" id="form6" class="form-control"
                                            placeholder="IMA Customer ID/Membership Number" mdbInput disabled>
                                        <!-- <label for="form6">IMA Customer ID/Membership Number </label> -->
                                    </div>
                                    <!-- Default inline 1-->
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="defaultInline1"
                                            name="inlineDefaultRadiosExample" mdbInput value="yes"
                                            [(ngModel)]="isImaCorrect">
                                        <label class="custom-control-label" for="defaultInline1">Yes</label>
                                    </div>
                                    <!-- Default inline 2-->
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="defaultInline2" value="no"
                                            name="inlineDefaultRadiosExample" mdbInput [(ngModel)]="isImaCorrect">
                                        <label class="custom-control-label" for="defaultInline2">No</label>
                                    </div>
                                </ng-container>

                                <div class="text-center" *ngIf="!imaData.length || !imaData[0].ima_code">
                                    <i class="fas fa-info-circle text-info fa-2x mb-2"></i><br />
                                    <strong>ATTENTION!</strong>
                                    <p class="py-3">We noticed that your IMA Customer ID/Membership Number is not
                                        updated in our database!
                                    </p>
                                </div>

                                <div class="md-form"
                                    *ngIf="isImaCorrect === 'no' || (!imaData.length || !imaData[0].ima_code)">
                                    <input type="text" id="form6" (keypress)="keyPress($event)" maxlength="12"
                                        class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="imaCode"
                                        [ngClass]="{'border-danger': submitted && (!imaCode ||imaCode.toString().length!=12)}"
                                        placeholder="IMA Customer ID/Membership Number">
                                    <!-- <label for="form6">IMA registration Number </label> -->
                                    <p class="text-danger"
                                        *ngIf="submitted && (!imaCode ||imaCode.toString().length!=12)">
                                        IMA code
                                        should
                                        have 12 digits</p>
                                    <a (click)="toggleShow()" class="text-danger"><small>Haven’t registered with IMA
                                            yet?Click here</small>
                                    </a>

                                    <ul class="pl-5 modl-text" *ngIf="isShown" id="divshow">
                                        <p style="text-decoration:underline">Steps to create Customer ID on IMA Website
                                        </p>
                                        <li class="mb-1">Click on the following link <br><a
                                                href="https://bit.ly/3Q1o42X"
                                                target="_blank">(https://bit.ly/3Q1o42X)</a>
                                        </li>
                                        <li class="mb-1">Click on Register Now, fill in the necessary details and
                                            register.
                                        </li>
                                        <li class="mb-1">On the top LHC click on myIMA > myProfile >My Account ; you'll
                                            find
                                            the IMA Customer ID under My Account.
                                        </li>
                                    </ul>

                                </div>

                                <div class="text-center">
                                    <button mdbBtn type="button" class="download_btn btn"
                                        style="padding: .6rem 2rem!important" rounded="true" (click)="submitIma()"
                                        mdbWavesEffect>Submit</button>
                                    <button mdbBtn type="button" class="btn-outline-primary btn"
                                        *ngIf="imaMetaData.can_skip" style="padding: .6rem 2rem!important"
                                        rounded="true" (click)="skip()" mdbWavesEffect>Skip</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </div>
  </div>
</div> -->