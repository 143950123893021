import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StudyplannerService {
  plannerGraphsubject: Subject<any> = new Subject();
  plannerGraphdata = this.plannerGraphsubject.asObservable();
  Url = `${environment.cmaapiUrl}`;
  constructor(private http: HttpClient) { }
  getGameStatusList() {
    return this.http.post(this.Url + 'getGameplanOptions', {});
  }

  getGameStatusList_mf(userToken: any) {
    const headers = new HttpHeaders({
      'Authorization': `${userToken}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.Url + 'getGameplanOptions', {}, { headers });
  }

  saveGpStatus(payload) {
    return this.http.post(this.Url + 'insertGameplanStatus', payload);
  }
  getGpStatus(userToken: string) {
    return this.http.post(this.Url + 'getGameplanStatus', {});
  }

  getGpStatus_mf(userToken: string) {
    const headers = new HttpHeaders({
      'Authorization': `${userToken}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.Url + 'getGameplanStatus', {}, { headers });
  }

  update_sr_log_and_gp_details(payload, userToken: string) {
    const headers = new HttpHeaders({
      'Authorization': `${userToken}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.Url + 'update_sr_log_and_gp_details', payload, { headers });
  }

  saveExamStatus(payload) {
    return this.http.post(this.Url + 'insertExamSchedule', payload);
  }

  saveExamStatus2(userToken, payload) {
    const headers = new HttpHeaders({
      'Authorization': `${userToken}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.Url + 'insertExamSchedule', payload, { headers });
  }

  saveExamStatusPopup(exam_data) {
    return this.http.post(this.Url + 'insertModalExamSchedule', {
      GPExamScheduleData: [...exam_data],
    });
  }

  getExamStatusList(payload) {
    const headers = new HttpHeaders({
      'Authorization': `${payload.userToken}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.Url + 'getExamStatus', { subjectId: payload.subjectId }, { headers });
  }

  getSubjectEs(payload) {
    const headers = new HttpHeaders({
      'Authorization': `${payload.userToken}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.Url + 'getSubjectExamStatus', { subjectId: payload.subjectId }, { headers });
  }

  getStudyplanner(subject_id, userToken, plannerFormData?) {
    const headers = new HttpHeaders({
      'Authorization': `${userToken}`,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.Url + 'getstudyplanner', {
      subject_id,
      ...plannerFormData,
    }, { headers });
  }

  getSubjectDetails(subjectId) {
    switch (subjectId) {
      case '41d4d05f967c1b913cabe95876b8a2c7':
        return {
          subjectName: 'CMA Part-1',
          logo: 'fa-search-dollar',
          shortName: 'Part1',
        };
        break;
      case '6c7ccca37633e15249a4ba916d82317e':
        return {
          subjectName: 'CMA Part-2',
          logo: 'fa-chart-line',
          shortName: 'Part2',
        };
        break;

      default:
        break;
    }
  }
}
