import { Component, Input, OnInit } from '@angular/core';
import { LaunchpadService } from '../../../clusters/services/launchpad.service';
import { PopupService } from 'projects/cma/src/app/clusters/services/popup.service';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { StudyplannerService } from '../../studyplanner/common/studyplanner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ima-popup',
  templateUrl: './ima-popup.component.html',
  styleUrls: ['./ima-popup.component.scss'],
})
export class ImaPopupComponent implements OnInit {
  @Input() imaData: any;
  @Input() imaMetaData: any;
  isImaCorrect;
  imaCode;
  submitted = false;
  isShown: boolean = false; // hidden by default
  gameplanPreviousStatus: any;
  constructor(
    private launchpadService: LaunchpadService,
    private popupService: PopupService,
    private studyPlannerService: StudyplannerService,
    public modalRef: MDBModalRef
  ) {}

  ngOnInit(): void {
    this.getGamePlanner();
  }
  getGamePlanner() {
    this.studyPlannerService.getGpStatus().subscribe((res: any) => {
      if (res && res.status) {
        if (res.data.length > 0) {
          this.gameplanPreviousStatus = res.metadata[0].int_gameplan_id;
        }
      }
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
  success(message: any, timer?: number) {
    Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      confirmButtonText: 'Ok',
      timer: timer,
    }).then(() => {
      window.location.reload();
    });
  }

  submitIma() {
    this.submitted = true;
    let reqData = { ima_code: '', skip_code: '0' };
    if (this.isImaCorrect === 'yes') {
      reqData.ima_code = this.imaData[0].ima_code;
    } else if (this.isImaCorrect === 'no') {
      if (this.imaCode) {
        reqData.ima_code = this.imaCode;
      } else {
      }
    } else {
      reqData.ima_code = this.imaCode;
    }

    if (reqData.ima_code && reqData.ima_code.toString().length == 12) {
      this.launchpadService.updateImaData(reqData).subscribe(
        (data: any) => {
          this.modalRef.hide();
          this.launchpadService.setImaModalData('ima updated');
          this.success(
            'Your IMA Customer ID/Membership Number is successfully updated. Note that we will verify the same with IMA.',
            2000
          );
          if (this.gameplanPreviousStatus == 1) {
            let payload = {
              int_gameplan_id: 2,
            };
            this.studyPlannerService.saveGpStatus(payload).subscribe();
          }
        },
        (e) => {
          this.launchpadService.setImaModalData('ima updated');
          this.popupService.error('something went wrong', 2000);
          this.modalRef.hide();
          console.log('err', e);
        }
      );
    }
  }

  skip() {
    this.modalRef.hide();
    const reqData = {
      skip_code: 1,
    };
    this.launchpadService.updateImaData(reqData).subscribe(
      (data: any) => {
        this.launchpadService.setImaModalData('ima updated');
      },
      (e) => {
        this.launchpadService.setImaModalData('ima updated');
        console.log('err', e);
      }
    );
    window.location.reload();
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
