import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StudyplannerService {
  plannerGraphsubject: Subject<any> = new Subject();
  plannerGraphdata = this.plannerGraphsubject.asObservable();
  Url = `${environment.cmaapiUrl}`;
  constructor(private http: HttpClient) {}
  getGameStatusList() {
    return this.http.post(this.Url + 'getGameplanOptions', {});
  }

  saveGpStatus(payload) {
    return this.http.post(this.Url + 'insertGameplanStatus', payload);
  }
  getGpStatus() {
    return this.http.post(this.Url + 'getGameplanStatus', {});
  }

  saveExamStatus(payload) {
    return this.http.post(this.Url + 'insertExamSchedule', payload);
  }

  saveExamStatusPopup(exam_data) {
    return this.http.post(this.Url + 'insertModalExamSchedule', {
      GPExamScheduleData: [...exam_data],
    });
  }

  getExamStatusList(payload) {
    return this.http.post(this.Url + 'getExamStatus', payload);
  }

  getSubjectEs(payload) {
    return this.http.post(this.Url + 'getSubjectExamStatus', payload);
  }

  getStudyplanner(subject_id, plannerFormData?) {
    return this.http.post(this.Url + 'getstudyplanner', {
      subject_id,
      ...plannerFormData,
    });
  }

  getSubjectDetails(subjectId) {
    switch (subjectId) {
      case '41d4d05f967c1b913cabe95876b8a2c7':
        return {
          subjectName: 'CMA Part-1',
          logo: 'fa-search-dollar',
          shortName: 'Part1',
        };
        break;
      case '6c7ccca37633e15249a4ba916d82317e':
        return {
          subjectName: 'CMA Part-2',
          logo: 'fa-chart-line',
          shortName: 'Part2',
        };
        break;

      default:
        break;
    }
  }
}
