import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { retry } from 'rxjs/operators';
import * as constant from 'src/environments/environment';
import { IndexdbServiceService } from './indexdb-service.service';
import { PopupService } from './popup.service';
import Swal from 'sweetalert2';
// import { Token } from '@angular/compiler'
@Injectable({
  providedIn: 'root',
})
export class LaunchpadService {
  testURL = constant.environment.cmaapiUrl;
  api_URL = constant.environment.cmaapiUrl;
  cmaurl = constant.environment.apiUrl;
  cpaUrl = constant.environment.apiUrl;
  superBaseUrl = constant.environment.superBaseUrl;
  dashboardSubject = new BehaviorSubject([]);
  dashboardData = this.dashboardSubject.asObservable();
  getdashboardMetaSubject = new Subject();
  getdashboardMetaData = this.getdashboardMetaSubject.asObservable();
  topicSubject = new Subject();
  topicData = this.topicSubject.asObservable();
  topicReportSubject = new Subject();
  topicReportData = this.topicReportSubject.asObservable();
  topicDetailsSubject = new Subject();
  topicDetailsData = this.topicDetailsSubject.asObservable();
  subtopicDetailsSubject = new Subject();
  subtopicDetailsData = this.subtopicDetailsSubject.asObservable();
  profileSubject = new Subject();
  profileData = this.profileSubject.asObservable();
  profileMetaSubject = new Subject();
  profileMetaData = this.profileMetaSubject.asObservable();
  beadCreamSubject = new Subject();
  breadcrumData = this.beadCreamSubject.asObservable();
  profileImageSubject = new Subject();
  profileImageData = this.profileImageSubject.asObservable();
  setActiveSubject = new Subject();
  setActiveData = this.setActiveSubject.asObservable();
  imaModalSubject = new Subject();
  imaModalData = this.imaModalSubject.asObservable();
  cityModalSubject = new Subject();
  cityModalData = this.cityModalSubject.asObservable();
  gamePlanModalSubject = new Subject();
  gamePlanModalData = this.gamePlanModalSubject.asObservable();

  getProfileApiControl: {
    call: boolean;
    data: any;
    meta: any;
  } = {
      call: false,
      data: '',
      meta: '',
    };

  headerAPIControl: {
    dashboard: boolean;
    getProfile: boolean;
    forumLaunchPad: boolean;
    forumNotifications: boolean;
  } = {
      dashboard: false,
      getProfile: false,
      forumLaunchPad: false,
      forumNotifications: false,
    };

  dashboardApiControl: {
    call: boolean;
    data: any;
    meta: any;
  } = {
      call: false,
      data: '',
      meta: '',
    };

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private popupService: PopupService,
    private router: Router,
    private dbService: IndexdbServiceService
  ) { }

  getDashboardData(params: any) {
    // !this.dashboardApiControl.call
    //   ?
    this.http.post(this.testURL + 'dashboard', params).subscribe((res: any) => {
      if (res && res.status) {
        this.dashboardApiControl.call = true;
        this.dashboardSubject.next(res.data);
        this.getdashboardMetaSubject.next(res);
        this.dashboardApiControl.data = res.data;
        this.dashboardApiControl.meta = res;
      }
    });

    // : (this.dashboardSubject.next(this.dashboardApiControl.data),
    //   this.getdashboardMetaSubject.next(this.dashboardApiControl.meta))
  }

  getDashboardData_mf(param: any) {
    const userToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdHVkZW50X2lkIjozNDUzLCJpbnRfc3RhdHVzIjoxLCJtYWNfaWQiOiJNQUN0Yjh4aWtpIiwiaW50X2NvdXJzZV9pZCI6MiwicGFydDFfbW9kZSI6MCwicGFydDJfbW9kZSI6MCwiaXNfZm9ydW1fYWRtaW4iOjEsImlhdCI6MTcyODI5NTk5Nn0.ZYapUti0WShz6y5LsOaPC6Ek8-pmeBpmroFfnNfa99U';
    const headers = new HttpHeaders({
      Authorization: `${userToken}`,
      'Content-Type': 'application/json',
    });

    this.http.post(this.testURL + 'dashboard', param, { headers }).subscribe({
      next: (res: any) => {
        if (res && res.status) {
          // this.dashboardApiControl.call = true
          // console.log("res", res)
          this.dashboardSubject.next(res);
          // this.getdashboardMetaSubject.next(res)
          // this.dashboardApiControl.data = res.data
          // this.dashboardApiControl.meta = res
        }
      },
    });
  }

  getTopics(params: any) {
    this.http
      .post(this.api_URL + 'getSubjectsDetails', params)
      .subscribe((res: any) => {
        if (res && res.status) {
          this.topicSubject.next(res.data);
        } else {
          this.popupService.noAccessPopup(res.message);
        }
      });
  }

  getTopicsReport(params: any) {
    this.http
      .post(this.api_URL + 'getSubjectReport', params)
      .subscribe((res: any) => {
        if (res && res.status) {
          this.topicReportSubject.next(res.data);
        }
      });
  }

  getTopicDetails(params: any) {
    this.http
      .post(this.api_URL + 'gettopicDetails', params)
      .subscribe((res: any) => {
        if (res && res.status) {
          this.topicDetailsSubject.next(res.data);
        }
      });
  }

  getSubTopicDetails(params: any) {
    this.http
      .post(this.api_URL + 'getsubtopicDetails', params)
      .subscribe((res: any) => {
        if (res && res.status) {
          this.subtopicDetailsSubject.next(res.data);
        }
      });
  }

  getSubTopicList(params: any) {
    return this.http.post(this.api_URL + 'gettopicDetails', params);
  }

  checkLoginSession(id: any) {
    this.firestore
      .collection('isLoggedIn_CMA')
      .doc(id)
      .snapshotChanges()
      .subscribe((snapshots: any) => {
        const token = snapshots.payload.data();
        // console.log('logout.....',token);
        if (token.token != this.dbService.getToken()) {
          // this.router.navigate(['/'], { replaceUrl: true });
          Swal.fire({
            html: `<p>Your session was terminated due to login from another browser/device
            . If it wasn't you,​ ​please change your password on next login or contact <a href="mailto:support@mileseducation.com">
            Miles Support Team</a>.</p>`,
            icon: 'error',
            backdrop: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
            allowEscapeKey: false,
          }).then((result) => {
            if (result.value) {
              // this.modalRef.hide();
              document.location.href = '/';
              // this.router.navigate(['/']);
            }
          });
        }
      });
  }

  getProfile() {
    this.http.post(this.api_URL + 'getProfile', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.getProfileApiControl.call = true;
        this.profileSubject.next(res.data);
        this.profileMetaSubject.next(res.metadata);
        this.getProfileApiControl.data = res.data;
        this.getProfileApiControl.meta = res.metadata;
        localStorage.setItem(
          'cma_profile_image',
          res.metadata[0].cdn_path + res.data[0].txt_profile_image
        );
      }
    });
  }

  getProfile_mf(userToken) {
    const headers = new HttpHeaders({
      Authorization: `${userToken}`,
      'Content-Type': 'application/json',
    });
    this.http
      .post(this.api_URL + 'getProfile', {}, { headers })
      .subscribe((res: any) => {
        if (res && res.status) {
          // console.log("res", res);

          this.getProfileApiControl.call = true;
          this.profileSubject.next(res.data);
          this.profileMetaSubject.next(res.metadata);
          this.getProfileApiControl.data = res.data;
          this.getProfileApiControl.meta = res.metadata;
          localStorage.setItem(
            'cma_profile_image',
            res.metadata[0].cdn_path + res.data[0].txt_profile_image
          );
        }
      });
  }

  getprofileinstant() {
    return this.http.post(this.api_URL + 'getProfile', {});
  }

  usazoomRegister(data) {
    return this.http.post(this.cmaurl + 'admin/webinar/usazoomRegister', data);
  }

  changePassword(params: any) {
    return this.http.post(this.api_URL + 'student/changepassword', params);
  }

  saveSupport(params: any) {
    return this.http.post(this.api_URL + 'saveSupport', params);
  }

  saveFeedback(params: any) {
    return this.http.post(this.api_URL + 'saveFeedback', params);
  }

  uploadProfileImage(params: any) {
    return this.http.post(this.api_URL + 'uploadProfileImage', params);
  }

  setBedCrem(param) {
    this.beadCreamSubject.next(param);
  }

  getimapayment_code() {
    return this.http.post(this.api_URL + 'imapayment_code', {});
  }

  uploadimaidproof(params) {
    const header = new Headers();
    this.http
      .post(this.api_URL + 'uploadimaidproof', params)
      .pipe(retry(1))
      .subscribe();
  }

  getConatctDetailsList() {
    return this.http.post(this.cmaurl + 'studentContactDetails', {
      courseId: 2,
    });
  }

  generatecustomerima(data) {
    return this.http.post(this.api_URL + 'generatecustomerima', data);
  }

  claimexamcodes(data) {
    return this.http.post(this.api_URL + 'claimexamcodes', data);
  }

  getuserinfo() {
    return this.http.post(this.api_URL + 'getuserinfo', {});
  }

  changeQuestionsMode(param) {
    return this.http.post(this.api_URL + 'changeQuestionsMode', param);
  }

  getImaData() {
    return this.http.get(`${this.api_URL}imaCode`);
  }

  getImaData2(userToken) {
    const headers = new HttpHeaders().set('authorization', '' + userToken);
    return this.http.get(this.api_URL + 'imaCode', { headers });
  }

  updateImaData(data) {
    return this.http.post(`${this.api_URL}imaCodeUpdate`, data);
  }

  getCityList() {
    return this.http.post(`${this.api_URL}getCityList`, '');
  }

  updateCurrentCity(data) {
    return this.http.post(`${this.api_URL}updateCurrentCity`, data);
  }

  setImaModalData(data: any) {
    this.imaModalSubject.next(data);
  }

  setCityModalData(data: any) {
    this.cityModalSubject.next(data);
  }
  setGamePlanerModalData(data: any) {
    this.gamePlanModalSubject.next(data);
  }

  updateDobAnniversaryDetails(param) {
    return this.http.post(this.api_URL + 'getDobAnniversaryDetails', param);
  }
  getDobAnniversaryDetails() {
    return this.http.post(this.api_URL + 'getDobAnniversaryDetails', {
      updateFlag: 0,
    });
  }

  getRecruitmentDetails() {
    // return this.http.get(this.superBaseUrl);
    return this.http.get('https://mfx-prod-154625965269.asia-south1.run.app/webinar/get-all-upcoming-webinar');
  }

  insertAdditionalUpdates(payload: any) {
    return this.http.post(this.api_URL + 'insertAdditionalUpdates', payload);
  }

  updateAdditionalUpdatesStatus(data) {
    return this.http.post(this.api_URL + 'updateAdditionalUpdatesStatus', data);
  }
  requestConclave(param) {
    return this.http.post(
      'https://mfx-prod-154625965269.asia-south1.run.app/website-forms/large-event-registration',
      param
    );
  }
  getMasterclasslist(param) {
    return this.http.post(this.cpaUrl + 'get-masterclasslist', param);
  }
  playfromstartMasterclass(int_masterclass_id) {
    return this.http.post(this.cpaUrl + 'subject/playfromstartMasterclass', { int_masterclass_id });
  }
  saveMasterclassVideoViews(masterclass_id, duration_watched) {
    this.http.post(this.cpaUrl + 'subject/saveMasterclassVideoViews', { masterclass_id, duration_watched }).subscribe();
  }
}
