<div class="modal-header py-1">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();"
          style="margin-top: -12px;">
    <span aria-hidden="true">×</span>
  </button>
  <span class="modal-title w-100" id="myModalLabel" style="font-weight: normal;font-size: 1.3rem !important;">Content
    Updates</span>
</div>
<div class="modal-body">
  <table class="table table-bordered content_updates_table">
    <thead>
      <tr>
        <th colspan="4">If you are using the 2023 edition Miles Concept Notes, refer updates below
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td width="40%">Audit (AUD)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q32023_Updatev0.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Financial (FAR)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR2023_Q22023_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Regulation (REG)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/REGUpdates-Q2_2023_Final_v1.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2023_updates_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
    </tbody>

  </table>
  <table class="table table-bordered content_updates_table">
    <thead>
      <tr>
        <th colspan="4">If you are using the 2022 edition Miles Concept Notes, refer updates below
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td width="40%">Auditing (AUD)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD_Q3_2022_Updates.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.2
          </a>
          <a (click)="audModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn"
             style="background-color: #FF0000 !important;">
            <i class="fas fa-play"></i> AUD-7.2
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q1_2023_UPDATES.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q1 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD-6.5_Errata.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-6.5 Errata
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q32023_Updatev0.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Financial (FAR)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR_Updates_Q3_2022.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a (click)="farModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn"
             style="background-color: #FF0000 !important;">
            <i class="fas fa-play"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR2023_Q22023_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Regulation (REG)</td>
        <td width="60%">
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2022.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/REGUpdates-Q2_2023_Final_v1.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2023_updates_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
    </tbody>

  </table>

  <table class="table table-bordered content_updates_table">
    <thead>
      <tr>
        <th colspan="4">If you are using the 2021 edition Miles Concept Notes, refer updates below
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td width="40%">Auditing (AUD)</td>
        <td width="60%">
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_4_1_Appendix_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> AUD-4.1
            </a> -->
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD-4.1_(I).pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-4.1 (I)
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.4
          </a>
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4%20_Additonal_Jul_1_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> AUD-7.4 - Additional MCQs
            </a> -->
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_6_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.6
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_July_2021_Updates.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> July 2021 – Key Updates
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_Q42021_Updates_Errata.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>Q4 2021
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-6_2022_Reporting_Model.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>AUD-6
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-3.4_2022.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>AUD-3.4-Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-7.7_2022.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>AUD-7.7
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD_Q12022_Updates.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>Q1 2022
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD_Q3_2022_Updates.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-7.2
          </a>
          <a (click)="audModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn"
             style="background-color: #FF0000 !important;">
            <i class="fas fa-play"></i> AUD-7.2
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q1_2023_UPDATES.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q1 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD-6.5_Errata.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> AUD-6.5 Errata
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q32023_Updatev0.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
      <tr>
        <td width="40%">Business (BEC)</td>
        <td width="60%">
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_July_2021_Updates.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> July 2021 – Key updates
          </a>
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_1_2_Business_Processes_(Operating%20cycle).pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-1.2 MCQ's
            </a>
            <span class="blinking">New</span> -->
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_1_2_Appendix_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-1.2
          </a>
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_9_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.9
          </a>
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_9_Data_management_and_relationships.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.9 MCQ's
            </a>
            <span class="blinking">New</span> -->
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_10_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.10
          </a>
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_10_Outsourced_IT_Functions.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.10 MCQ's
            </a>
            <span class="blinking">New</span> -->
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_11_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.11
          </a>
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_11_Change_Management.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.11 MCQ's
            </a>
            <span class="blinking">New</span> -->
          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_12_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> BEC-7.12
          </a>
          <!-- <span class="blinking">New</span> -->
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_12_Resilience.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.12 MCQ's
            </a>
              <span class="blinking">New</span> -->
        </td>
      </tr>
      <tr>
        <td width="40%">Financial (FAR)</td>
        <td width="60%">

          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_July_2021_Updates.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> July 2021 – Key Updates
          </a>

          <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR-4_5_July_2021.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> FAR-4.5
          </a>

          <a href="https://streaming.mileseducation.com/cpa_ebooks/FAR_2022_Errata.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> FAR Errata 2022
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR_Updates_Q3_2022.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a (click)="farModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn"
             style="background-color: #FF0000 !important;">
            <i class="fas fa-play"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR2023_Q22023_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <span class="blinking">New</span>

          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_4_5_Additional_MCQs.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> FAR 4.5 - Additional MCQs
            </a>
            <span class="blinking">New</span> -->
        </td>
      </tr>
      <tr>
        <td width="40%">Regulation (REG)</td>
        <td width="60%">
          <!-- <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q4_2020_CARES_Act.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> CARES Act Q4 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q3_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> Q3 2021 Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q4_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q4 2021 Updates
            </a> -->
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/CPA_Exam_Blueprint%20Changes_effective.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>CPA Exam Blueprint Changes effective Jul 1, 2021
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2022.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/REGUpdates-Q2_2023_Final_v1.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q2 2023 Updates
          </a>
          <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2023_updates_Final.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2023 Updates
          </a>
          <span class="blinking">New</span>
        </td>
      </tr>
    </tbody>

  </table>

  <!-- <table class="table table-bordered content_updates_table">
      <thead>
        <tr>
          <th colspan="4">If you are using the 2020 edition Miles Concept Notes, refer updates below
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td width="40%">Auditing (AUD)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_4_1_Appendix_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> AUD-4.1
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> AUD-7.4
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_6_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> AUD-7.6
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_Q42021_Updates_Errata.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>Q4 2021
            </a>
            <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-6_2022_Reporting_Model.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>AUD-6
             </a>
             <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-3.4_2022.pdf"
             target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
             <i class="fas fa-download"></i>AUD-3.4-Updates
              </a>
              <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-7.7_2022.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i>AUD-7.7
               </a>
               <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD_Q12022_Updates.pdf"
                 target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                 <i class="fas fa-download"></i>Q1 2022
              </a>
            <span class="blinking">New</span>

            <a href="https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/pdf/AUD_July_2022_Updates.pdf"
                 target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                 <i class="fas fa-download"></i> AUD-7.2
              </a>
              <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/AUD_Q3_2022_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> Q3 2022 Updates
              </a>
              <a (click)="audModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn" style="background-color: #FF0000 !important;">
                <i class="fas fa-play"></i> Q3 2022 Updates
              </a>
            <span class="blinking">New</span>

          </td>
        </tr>
        <tr>
          <td width="40%">Business (BEC)
          </td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_1_2_Appendix_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-1.2
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_9_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.9
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_10_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.10
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_11_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.11
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_12_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.12
            </a>

             <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_Errata_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Errata – Q1 2021
            </a>
            <span class="blinking">New</span>
          </td>
        </tr>
        <tr>
          <td width="40%">Financial (FAR)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_CARES_Act_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> CARES Act_Q4 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_SEC_Filers_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> SEC_Filers_Q4 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_Errata_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i>Q4 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_Long_term_Construction_Contracts.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> LONG-TERM CONSTRUCTION CONTRACTS
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Revenue_Recognition_Additional_Examples.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i>Revenue Recognition
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR-4_5_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> FAR-4.5
            </a>

            <a href="https://streaming.mileseducation.com/cpa_ebooks/FAR_2022_Errata.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> FAR Errata 2022
            </a>
            <span class="blinking">New</span>

            <a href="https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/pdf/FAR-Updates-Q3_2022.pdf"
                 target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                 <i class="fas fa-download"></i> Q3 2022
              </a>
            <span class="blinking">New</span>

            <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/FAR_Updates_Q3_2022.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
            </a>
            <a (click)="farModal.show()" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn" style="background-color: #FF0000 !important;">
              <i class="fas fa-play"></i> Q3 2022 Updates
           </a>
            <span class="blinking">New</span>
          </td>
        </tr>
        <tr>
          <td width="40%">Regulation (REG)</td>
          <td width="60%">
            <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/CPA_Exam_Blueprint%20Changes_effective.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i>CPA Exam Blueprint Changes effective Jul 1, 2021
            </a>
            <a href="https://streaming.mileseducation.com/cpa_ebooks/contentupdates/Q3_2022.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q3 2022 Updates
            </a>
            <span class="blinking">New</span>
          </td>
        </tr>

      </tbody>

    </table> -->

  <!-- <table class="table table-bordered content_updates_table">
      <thead>
        <tr>
          <th colspan="4">If you are using the 2019 edition Miles Concept Notes, refer updates below
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td width="40%">Auditing (AUD)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD7.5_Analytics_Q32019.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2019 ( ADA )
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_4_1_Appendix_July_2021.pdf"
                target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                  <i class="fas fa-download"></i> AUD-4.1
              </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> AUD-7.4
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_6_July_2021.pdf"
                target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                  <i class="fas fa-download"></i> AUD-7.6
             </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_July_2021_Updates.pdf"
                target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                  <i class="fas fa-download"></i> July 2021 – Key Updates
              </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_Q42021_Updates_Errata.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i>Q4 2021
              </a>
              <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-6_2022_Reporting_Model.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i>AUD-6
               </a>
               <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-3.4_2022.pdf"
               target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
               <i class="fas fa-download"></i>AUD-3.4-Updates
                </a>
                <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD-7.7_2022.pdf"
                target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i>AUD-7.7
                 </a>
                 <a href="https://streaming.mileseducation.com/cpa_ebooks/AUD/AUD_Q12022_Updates.pdf"
                 target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                 <i class="fas fa-download"></i>Q1 2022
                  </a>
              <span class="blinking">New</span>
            </td>
        </tr>
        <tr>
          <td width="40%">Business (BEC)
          </td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_July_2021_Updates.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
            <span class="blinking">New</span>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_1_2_Appendix_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-1.2
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_9_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.9
            </a>
            <span class="blinking">New</span>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_10_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.10
            </a>
            <span class="blinking">New</span>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_11_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.11
            </a>
            <span class="blinking">New</span>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_12_July_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> BEC-7.12
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_Errata_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Errata – Q1 2021
            </a>
            <span class="blinking">New</span>

          </td>
        </tr>
        <tr>
          <td width="40%">Financial (FAR)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/CPA_Content Updates_FAR_Q3 2019.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2019
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/CPA_FAR_Content%20Updates_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q1 2020
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_CARES_Act_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> CARES Act_Q4 2020
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_SEC_Filers_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> SEC_Filers_Q4 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_Errata_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i>Q4 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_Long_term_Construction_Contracts.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> LONG-TERM CONSTRUCTION CONTRACTS
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Revenue_Recognition_Additional_Examples.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i>Revenue Recognition
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR-4_5_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> FAR-4.5
            </a>
            <a href="https://streaming.mileseducation.com/cpa_ebooks/FAR_2022_Errata.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> FAR Errata 2022
            </a>
              <span class="blinking">New</span>
          </td>
        </tr>

        <tr>
          <td width="40%">Regulation (REG)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Miles%20CPA_Updates%202019_REG.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2019
             </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MilesCPA_Updates2020_REG.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q1 2020
             </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Miles_CPA_Updates_Q3_2020_REG.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2020
             </a><br>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q4_2020_CARES_Act.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> CARES Act Q4 2020
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
              </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q3_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> Q3 2021 Updates
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q4_2021.pdf"
            target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
            <i class="fas fa-download"></i> Q4 2021 Updates
            </a>
            <span class="blinking">New</span>
          </td>
        </tr>
      </tbody>

    </table> -->

  <!-- <table class="table table-bordered content_updates_table mb-0">
      <thead>
        <tr>
          <th colspan="4">If you are using the 2018 edition Miles Concept Notes, refer updates below</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td width="40%">Auditing (AUD)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD7.5_Analytics_Q32019.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2019 ( ADA )
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_4_1_Appendix_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> AUD-4.1
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> AUD-7.4
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_4%20_Additonal_Jul_1_2021.pdf"
               target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                 <i class="fas fa-download"></i> AUD-7.4 - Additional MCQs
             </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_7_6_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> AUD-7.6
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/AUD_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
            </a>
              <span class="blinking">New</span>
          </td>
        </tr>
        <tr>
          <td width="40%">Business (BEC)
          </td>
          <td width="60%">
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
              </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_1_2_Business_Processes_(Operating%20cycle).pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-1.2 MCQ's
              </a>
              <span class="blinking">New</span>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_1_2_Appendix_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-1.2
              </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_9_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.9
              </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_9_Data_management_and_relationships.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.9 MCQ's
              </a>
              <span class="blinking">New</span>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_10_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.10
              </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_10_Outsourced_IT_Functions.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.10 MCQ's
              </a>
              <span class="blinking">New</span>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_11_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.11
              </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_11_Change_Management.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.11 MCQ's
              </a>
              <span class="blinking">New</span>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/BEC_7_12_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.12
              </a>

              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MCQ_7_12_Resilience.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> BEC-7.12 MCQ's
              </a>
              <span class="blinking">New</span>
          </td>
        </tr>
        <tr>
          <td width="40%">Financial (FAR)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/CPA_Content_Updates_FAR_Q1_201999.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q1 2019 ( Leases )
             </a>

            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/CPA_Content_Updates_FAR_Q1_2019_Other.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn" style="color: #ffff00;">
                <i class="fas fa-download"></i> Q1 2019 ( Others )
             </a>

            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/CPA_Content Updates_FAR_Q3 2019.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2019
             </a>

            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/CPA_FAR_Content%20Updates_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q1 2020
             </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_CARES_Act_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> CARES Act_Q4 2020
             </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_SEC_Filers_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> SEC_Filers_Q4 2020
             </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_Errata_Q4_2020.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i>Q4 2020
             </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_Long_term_Construction_Contracts.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> LONG-TERM CONSTRUCTION CONTRACTS
             </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Revenue_Recognition_Additional_Examples.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i>Revenue Recognition
             </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
             </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR-4_5_July_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> FAR-4.5
             </a>
              <span class="blinking">New</span>

              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/FAR_4_5_Additional_MCQs.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> FAR 4.5 - Additional MCQs
             </a>
              <span class="blinking">New</span>
          </td>
        </tr>

        <tr>
          <td width="40%">Regulation (REG)</td>
          <td width="60%">
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Content_Updates_REG_2019_Q1_v2.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q1 2019 ( TCJA )
            </a>

            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Miles CPA_Updates 2019_REG.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2019
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/MilesCPA_Updates2020_REG.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q1 2020
            </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/Miles_CPA_Updates_Q3_2020_REG.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> Q3 2020
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q4_2020_CARES_Act.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> CARES Act Q4 2020
            </a>
              <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_July_2021_Updates.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
                <i class="fas fa-download"></i> July 2021 – Key Updates
              </a>
            <a href="https://cdn.mileseducation.com/content-updates/cpa-lms-content-updates/REG_Q3_2021.pdf"
              target="_blank" class="m-0 font_size px-2 my-2 mx-2 btn-deep-purple btn-sm btn custom-topic-btn">
              <i class="fas fa-download"></i> Q3 2021 Updates
            </a>
            <span class="blinking">New</span>

          </td>
        </tr>
      </tbody>

    </table> -->

</div>

<!-- AUD Video Modal -->
<div mdbModal #audModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="audModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">AUD
          Q3 2022 Updates</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/JfH6946n928" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<!-- FAR Video Modal -->
<div mdbModal #farModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myfarModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="farModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel" style="font-weight: normal; font-size: 1.3rem !important;">FAR
          Q3 Updates</h4>
      </div>
      <div class="modal-body">
        <iframe width="100%" height="415" src="https://www.youtube.com/embed/rhTXohACZBc" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>